import React,{useState, useEffect} from 'react';
import "../locations/location.css";
import { Link,  useLocation, useNavigate} from "react-router-dom";
import useFetch from '../../fetch/useFetch';
import locations from '../locations/locations.json'; 
import FixedFooter from '../../components/FixedFooter';
import TopHeader from '../../components/TopHeader';

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import { Helmet } from "react-helmet-async";

import PageNotFound from '../PageNotFound/PageNotFound';

const BASEURL = process.env.REACT_APP_SQUAREONE_BASE_URL;
const SALONAPI = process.env.REACT_APP_SQUAREONE_API_EXTERNAL;
export default function LocationOverview() {


  const navigate = useNavigate() 
  const location = useLocation();
  // const path = location.pathname.split('/').pop();


  const pathSegments = location.pathname.split('/').filter(Boolean);
  const path = pathSegments[pathSegments.length - 1]; // Get the last non-empty segment
  
 
  let locationDetailsDayton = locations && locations.filter(location => location.urlformat === path);

  const [data, setData] = useState(null); 
 
  const { data: gallery , loading : masterGalleryLoading  } = useFetch(`${SALONAPI}/wsgallery/415/${locationDetailsDayton[0]?.masterGalleryModule}`);
 
 
  const {data : salonInfo }= useFetch(`${SALONAPI}/salon_info/${locationDetailsDayton[0]?.salonId}`);

  const salonId = locationDetailsDayton[0]?.masterSalonId || '';

  const galleryModuleId = locationDetailsDayton[0]?.masterGalleryModule ||  '';

 useEffect(() => {
    const handlefancyBox = async () => {
      if (salonId && galleryModuleId) {
        try {
          const response = await fetch(`${SALONAPI}/wsgalleryfancy/415/${galleryModuleId}`);
          
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          
          const result = await response.json();
          setData(result);
        } catch (error) {
          console.error('Failed to fetch data:', error);
        }
      }
    };

    handlefancyBox();
 
  }, [salonId, galleryModuleId]);
  
 
  const openFancybox = () => {
    const fancyboxData = data.map(({ gallery_image, gallery_title }) => ({
        src: gallery_image,
        opts: {
            caption: gallery_title,
            type: "modern",
            
        }
        
    }));

    new Fancybox(fancyboxData);
};




  if(masterGalleryLoading){
    return <div className='shimmer-main'>
      
      <div className='shimmer blog-sortbywrapper bg-transparent' style={{minHeight:'48px',width:'100%',height:'48px'}}></div>
    
    <div className="container">
      <div  className="our-story">
      <div className="row">
        <div className="col-md-6 col-12 pe-md-0">
          <div className='shimmer bg-transparent' style={{minHeight:'473px',height:'473px',display:'block',borderRadius:'10px'}}>

          </div>
        </div>
        <div className="col-md-6 col-12 px3">
        <div className="row">
            <div className="col-md-6 col-6 px2">
                <div className="our-storyImg shimmer"  style={{minHeight:'205',height:'208',display:'block',borderRadius:'10px'}}><div className="shimmer bg-transparent "></div></div>
            </div>
            <div className="col-md-6 col-6 px2">
            <div className="our-storyImg shimmer"  style={{minHeight:'205',height:'208',display:'block',borderRadius:'10px'}}><div className="shimmer bg-transparent "></div></div>
            </div>
            <div className="col-md-6 col-6 px2">
            <div className="our-storyImg shimmer"  style={{minHeight:'205',height:'208',display:'block',borderRadius:'10px'}}><div className="shimmer bg-transparent "></div></div>
            </div>
            <div className="col-md-6 col-6 px2">
            <div className="our-storyImg shimmer"  style={{minHeight:'205',height:'208',display:'block',borderRadius:'10px'}}><div className="shimmer bg-transparent "></div></div>
            </div>
        </div>
    </div>
      </div>
      </div>
      </div>
      
      </div>
  }


  if (!locationDetailsDayton || locationDetailsDayton.length === 0) {
    return <PageNotFound/>;
  }

  return (
    <>
   
     <Helmet>
    
          <link rel="canonical" href={locationDetailsDayton[0]?.curl_location_overview} />
           <title> {locationDetailsDayton[0]?.metaTitle_location || 'Aveda Hair & Spa Salon Ohio | Beauty & Wellness - Square One'} </title>
           <meta name="description" content={locationDetailsDayton[0]?.metaDes_location || 'Experience Best Aveda hair & spa services at Square One Salon in Ohio. Offering expert beauty & wellness treatments in Dayton & Columbus Ohio. Book now!'} />
          </Helmet>

    <TopHeader  locationDetailsDayton = {locationDetailsDayton[0]}  BASEURL = {BASEURL}/>

      <div className="our-story"> 
        <div className="row">
          {
            gallery && gallery?.slice(0 , 1).map(({gallery_id, gallery_title, gallery_image}) => (
              <div className="col-md-6 col-12 pe-md-0" key={Math.random(2 * gallery_id)}>
                <div className="our-storyImg"  onClick={openFancybox}>
                  
                  {
                    gallery_image ? (<img
                      src={gallery_image}
                      width="574"
                      height="423"
                      alt={gallery_title}
                    />) : (<img
                      src={`${BASEURL}/assets/images/placeholder.png`}
                      width="574"
                      height="423"
                      alt={gallery_title}
                    />)
                  }
                  
                  <div className='show_all_photos'>
                    <Link ><span><svg xmlns="http://www.w3.org/2000/svg" width="11.211" height="11.211" viewBox="0 0 11.211 11.211">
                    <path id="image_FILL0_wght400_GRAD0_opsz24_1_" data-name="image_FILL0_wght400_GRAD0_opsz24 (1)" d="M129.965-828.789a1.2,1.2,0,0,0,.88-.366,1.2,1.2,0,0,0,.366-.88v-8.72a1.2,1.2,0,0,0-.366-.88,1.2,1.2,0,0,0-.88-.366h-8.72a1.2,1.2,0,0,0-.88.366,1.2,1.2,0,0,0-.366.88v8.72a1.2,1.2,0,0,0,.366.88,1.2,1.2,0,0,0,.88.366Zm0-1.246h-8.72v-8.72h8.72Zm-.623-1.246h-7.474l2.336-3.114,1.868,2.491,1.4-1.869Zm.623,1.246v0Z" transform="translate(-120 840)" fill="#1d1d1d"/>
                  </svg></span>Show All Photos</Link>
                  </div>
                </div>
          </div>
            ))
          }
             

            
          
          

          <div className="col-md-6 col-12 px3">
            <div className="row">
               {
                gallery && gallery?.slice(1 , 5).map(({gallery_id, gallery_title, gallery_image}) => (
                  <div className="col-md-6 col-6 px2" key={gallery_id}>
                  <div className="our-storyImg"  onClick={openFancybox}>
                  {
                    gallery_image ? (<img
                      src={gallery_image}
                      width="574"
                      height="423"
                      alt={gallery_title}
                    />) : (<img
                      src={`${BASEURL}/assets/images/placeholder.png`}
                      width="574"
                      height="423"
                      alt={gallery_title}
                    />)
                  }
                  </div>
                </div>
                ))
               }
            </div>
          </div>
        </div>
      </div>



      <div className='come_visit_wrapper'>
        <div className='come_visit_gird'>
          <h2>Come visit us</h2>
          <h3>Welcome to Square One - {locationDetailsDayton[0] && locationDetailsDayton[0]?.locationName}</h3>
          <div className='come_visit_icon'>
          <img  src={`${BASEURL}/assets/images/storefront.svg`}
                    width="30.05"
                    height="26.92"
                    alt="store icon"
                  /> 
          </div>
          <p>Learn more about what our location can offer to you. We want to create a personalized experience for you at every visit.</p>
          {/* <Link to  = {locationDetailsDayton[0]?.onlineBooking} target='_blank' className='btn btn-outline-primary'>Book Now</Link> */}

          <Link to  ={`${BASEURL}/online-booking/${locationDetailsDayton[0] && locationDetailsDayton[0]?.urlformat}`}  className='btn btn-outline-primary'>Book Now</Link>

        </div>
      </div>

      <div className='container location_team_wrapper-page'>
        <div className='what_we_offer_wrapper location_team_wrapper'>
            <h3>What we Offer</h3>
            <p>Learn more about our team and promotions here at {locationDetailsDayton[0] && locationDetailsDayton[0]?.locationName}.</p>
        <div className="row">
              <div className="col-md-3 col-sm-6 col-12">
                <div className="location_gird">
                  <div className="team-bth-padd">
                    <div className="location_img" onClick={() => navigate(`${BASEURL}/team/${locationDetailsDayton[0] && locationDetailsDayton[0]?.urlformat}`)}>
                      <img
                        src={`${BASEURL}/assets/images/what_we_offer1.jpg`}
                        width="240"
                        height="240"
                        alt="what_we_offer1 img"
                      />
                    </div>
                    <div className="location_content">
                      <h4 onClick={() => navigate(`${BASEURL}/team/${locationDetailsDayton[0] && locationDetailsDayton[0]?.urlformat}`)}>
                       Our Team
                      </h4>
                      <p>Get to know more about each of<br></br> our experts at this salon.</p>
                    </div>
                  </div>
                  <div className="promotion-download">
                    <Link to = {`${BASEURL}/team/${locationDetailsDayton[0] && locationDetailsDayton[0]?.urlformat}`} className="btn btn-link">Discover</Link>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 col-12">
                <div className="location_gird">
                  <div className="team-bth-padd">
                    <div className="location_img" onClick={() => navigate(`${BASEURL}/promotions/${locationDetailsDayton[0] && locationDetailsDayton[0]?.urlformat}`)}>
                      <img
                        src={`${BASEURL}/assets/images/what_we_offer2.jpg`}
                        width="240"
                        height="240"
                        alt="what_we_offer2-img"
                      />
                    </div>
                    <div className="location_content">
                      <h4 onClick={() => navigate(`${BASEURL}/promotions/${locationDetailsDayton[0] && locationDetailsDayton[0]?.urlformat}`)}>Promotions</h4>
                      <p>Browse our seasonal and on going offers available here.</p>
                    </div>
                  </div>
                  <div className="promotion-download">
                    <Link to = {`${BASEURL}/promotions/${locationDetailsDayton[0] && locationDetailsDayton[0]?.urlformat}`}  className="btn btn-link">Discover</Link>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 col-12">
                <div className="location_gird">
                  <div className="team-bth-padd">
                    <div className="location_img" onClick={() => navigate(`${BASEURL}/reviews/${locationDetailsDayton[0] && locationDetailsDayton[0]?.urlformat}`)}>
                      <img
                        src={`${BASEURL}/assets/images/what_we_offer3.jpg`}
                        width="240"
                        height="240"
                        alt="what_we_offer3-img"
                      />
                    </div>
                    <div className="location_content">
                      <h4 onClick={() => navigate(`${BASEURL}/reviews/${locationDetailsDayton[0] && locationDetailsDayton[0]?.urlformat}`)}>Reviews</h4>
                      <p>Read reviews from customers and write your own.</p>
                    </div>
                  </div>
                  <div className="promotion-download">
                    <Link to={`${BASEURL}/reviews/${locationDetailsDayton[0] && locationDetailsDayton[0]?.urlformat}`} className="btn btn-link">Discover</Link>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 col-12">
                <div className="location_gird">
                  <div className="team-bth-padd">
                    <div className="location_img" onClick={() => navigate(`${BASEURL}/contact/${locationDetailsDayton[0] && locationDetailsDayton[0]?.urlformat}`)}>
                      <img
                        src={`${BASEURL}/assets/images/what_we_offer4.jpg`}
                        width="240"
                        height="240"
                        alt="what_we_offer4-img"
                      />
                    </div>
                    <div className="location_content">
                      <h4 onClick={() => navigate(`${BASEURL}/contact/${locationDetailsDayton[0] && locationDetailsDayton[0]?.urlformat}`)}>Get in Touch</h4>
                      <p>Have questions or concerns?<br></br> Contact us anytime.</p>
                    </div>
                  </div>
                  <div className="promotion-download">
                    <Link to={`${BASEURL}/contact/${locationDetailsDayton[0] && locationDetailsDayton[0]?.urlformat}`} className="btn btn-link">Discover</Link>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
     
      <FixedFooter  locationDetailsDayton = {locationDetailsDayton[0] && locationDetailsDayton[0]} salonInfo = {salonInfo}/>
    
    </>
  )

  
}

