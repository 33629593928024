import React, {useContext} from 'react'
import SiteContext from '../../helpers/CreateContext';
import parse from 'html-react-parser';
import { Helmet } from "react-helmet-async";

const BookNow = () => {

  const { salonData, salonInfoPending } = useContext(SiteContext); 
  const webInfo = salonData && salonData?.website_settings;
  return (
    <React.Fragment>
        
        <Helmet>
        <link rel="canonical" href="https://www.squareonesalon.com/online-booking" />
        <title>Hair Salon Spa Online Booking Ohio: Square One Salon & Spa</title>
        <meta name="description" content="Book your appointment online at Square One Salon Spa nearest salon in & around Ohio. Experience the best salon & wellness services provided by our team. Hurry up!" />
       </Helmet>

       <div className="inner-banner-main">

       {
          !salonInfoPending ? (<>{salonData && parse(salonData && salonData.website_settings.book_an_appointment_banner && salonData.website_settings.book_an_appointment_banner) }</>) : (<><div className="videoWrapperli">
            <div className="shimmer" style={{ minHeight: '324px', display: 'block' }}></div>
          </div></>)
        }

        </div>

       

       <section className='iframe-theme'>
         <div className='container'>
         <div className="iframe-max-theme">

           <div className="home-offer-content">
                <div className='max-800'>
                    {webInfo && parse(webInfo?.services_special_offer || '')}
              </div>
            </div>
          

            <div className="iframe-container">
                <iframe className="responsive-iframe" title='Book an Appointment' src="https://book.salonbiz.com/widget/#/welcome?comToken=c244t201402131049p6t52Uxb7k6z75S6eN3a6" width="100%"></iframe>
            </div>

              <div className='note-offer'>
                                 {webInfo && parse(webInfo?.services_offer_note || '')}
                                </div>
                                
        </div>

         </div>
       </section>

    </React.Fragment>
  )
}

export default BookNow;
