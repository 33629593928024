import React from 'react';
import '../PageNotFound/pageNotFound.css';
import { Link  } from "react-router-dom";
import { useLocation } from 'react-router-dom';
// import MetaTags from '../../MetaTags';
// import metaData from '../../seoMetaTags.json';

const BASEURL = process.env.REACT_APP_SQUAREONE_BASE_URL;




export default function PageNotFound() {

  const location = useLocation();
  const pathname = location?.pathname?.split('/')?.pop();
  

  return (
    <>
    {/* <MetaTags metaData={metaData} /> */}
        <section className='pagenotfound-main'>
            <div className={pathname === "holiday" ? 'pagenotfound-grid max-585':'pagenotfound-grid'}>
            {pathname === "holiday" ? '' : <h2>404 Error</h2> }
                
                <div className="pagenot-icon py-5">
                    <img src={`${BASEURL}/assets/images/storefront.svg`} className='img-fluid mx-auto' width="30" height="26" alt="home icon" />
                </div>
                <h3 className='heading_24'>{pathname === "holiday" ? 'Holiday Section Temporarily Unavailable' : 'Whoops, we can’t find this page.'}</h3>
                {pathname === "holiday" ? <p>The holiday section is currently being removed and will return next year. <span className='d-block'>Thank you for choosing Square One Salon. If you have any questions or need assistance, please don’t hesitate to reach out.</span></p> : <p>The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.</p>}
               
                <Link to="/" className='btn btn-primary mt-4'>Return Home</Link>
            </div>
        </section>
      
    </>
  )
}
