import React, { useState, useEffect, useContext, useRef } from "react";
import "../promotion/promotion.css";
import { useLocation, Link } from "react-router-dom";
import useFetch from "../../fetch/useFetch";
import SiteContext from "../../helpers/CreateContext";
import parse from "html-react-parser";
import { cleanHtml } from "../../utils/cleanHtml";
import { convertAndDownloadDynamic } from "../../utils/convertAndDownload";
import { Helmet } from "react-helmet-async";

const BASEURL = process.env.REACT_APP_SQUAREONE_BASE_URL;
const SALONAPI = process.env.REACT_APP_SQUAREONE_API_EXTERNAL;
const alternativeImg = process.env.REACT_APP_ALTERNATIVE_IMG;

export default function Promotion() {
  const { salonData, salonInfoPending } = useContext(SiteContext);
  const { data: promotionStaff, loading: promotionPending } = useFetch(
    `${SALONAPI}/promotionsDetails`
  );
  console.log(promotionStaff, "promotionStaff");

  const handleLinkClick = (event) => {
    event.preventDefault(); // Prevent React Router's navigation
    window.location.href = event.currentTarget.href; // Trigger full reload
  };

  const [showModal, setShowModal] = useState(false);
  const [modalPromotion, setModalPromotion] = useState(null);

  const location = useLocation();
  const path = location.pathname.split("/").pop();

  const popupRef = useRef(null);

  useEffect(() => {
    if (showModal) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [showModal]);

  const openModal = (promotion) => {
    setModalPromotion(promotion);
    setShowModal(true);
    setShowModal((prev) => ({ ...prev, fadeIn: true }));
  };

  const closeModal = () => {
    setShowModal(false);
    setModalPromotion(null);
  };

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setShowModal(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (salonInfoPending || promotionPending) {
    return (
      <>
        <div className="homebanner-section" style={{ height: "324px" }}>
          <div className="videoWrapperli">
            <div
              className=" shimmer "
              style={{ minHeight: "324px", display: "block" }}
            ></div>
          </div>
        </div>

        <div className="promotion-bg">
          <div className="container">
            <div className="promotion-wrapper">
              <div className="row g-4">
                <div
                  className="col-lg-4 col-md-6 col-sm-6 col-12 shimmer"
                  style={{
                    minHeight: "350px",
                    display: "block",
                    borderRadius: "5px",
                    border: "1px solid #97AD6E",
                  }}
                ></div>
                <div
                  className="col-lg-4 col-md-6 col-sm-6 col-12 shimmer"
                  style={{
                    minHeight: "350px",
                    display: "block",
                    borderRadius: "5px",
                    border: "1px solid #97AD6E",
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href="https://www.squareonesalon.com/promotions"
        />
        <title>
          Special Offers Hair & Spa Services | Square One Aveda Salon
        </title>
        <meta
          name="description"
          content="Explore special deals on haircuts, color, extensions, and spa services. Visit Square One Aveda Salon for location-specific deals and updates across Ohio!"
        />
      </Helmet>

      <div className="inner-banner-main">
        {salonData &&
          parse(
            salonData?.website_settings?.new_promotions_banner &&
              salonData?.website_settings?.new_promotions_banner
          )}
      </div>

      <div className="promotion-bg">
        <div className="container">
          <div className="promotion-wrapper">
            <div className="row">
              {promotionStaff &&
                promotionStaff
                  .filter(
                    (item) =>
                      item.specials_title !== "Home_page_Special" &&
                      item.specials_title !== "Home_page_Refer"
                  )
                  .map((promotionlist) => (
                    <div
                      className="col-lg-4 col-md-6 col-sm-6 col-12"
                      key={promotionlist.specials_id}
                    >
                      <div className="promotion-gird">
                        <div
                          className="promotion-card"
                          onClick={() => openModal(promotionlist)}
                        >
                          <div className="promotion-img">
                            <img
                              src={
                                promotionlist?.specials_image_new ||
                                alternativeImg
                              }
                              width="162"
                              height="162"
                              alt={promotionlist.specials_title}
                              className="img-fluid"
                            />
                          </div>
                          <div className="promotionCont">
                            <h3>
                              {promotionlist?.specials_title}
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() => openModal(promotionlist)}
                              >
                                <img
                                  src={`${BASEURL}/assets/images/info.svg`}
                                  width="14"
                                  height="14"
                                  alt="info"
                                />
                              </span>
                            </h3>
                            <div className="promotion-min">
                              {parse(
                                cleanHtml(
                                  promotionlist?.specials_description
                                    ?.replace(/<[^>]*>/g, "")
                                    .replace(/\s+/g, " ")
                                    .trim()
                                )
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="promotion-download">
              {promotionlist.specials_title === "Spa Packages" ? (
                <Link
                  to={`${BASEURL}/gift-card`}
                  onClick={() => {
                    sessionStorage.setItem("scrollToSpaGrid", "true");
                  }}
                  className="btn btn-link"
                >
                  Purchase
                </Link>
              ) : (
                <button
                  onClick={() =>
                    convertAndDownloadDynamic(
                      promotionlist?.specials_image_new,
                      promotionlist?.specials_title,
                      `${path}-${promotionlist.specials_title}-Promotions`
                    )
                  }
                  className="btn btn-link"
                >
                  Download
                </button>
              )}
            </div>
                      </div>
                    </div>
                  ))}
            </div>
          </div>
        </div>
      </div>

      {showModal && modalPromotion && (
        <div
          id="StaffModal"
          className={`modal-staff ${showModal.fadeIn ? "show" : ""}`}
        >
          <div className="modal-staff-dialog" ref={popupRef}>
            <div className="modal-staff-content">
              <button type="button" className="close" onClick={closeModal}>
                <span>
                  <img
                    src={`${BASEURL}/assets/images/close.svg`}
                    width="16.35"
                    height="16.35"
                    alt="close icon"
                  />
                </span>
              </button>
              <div className="modal-staff-body">
                <div className="promotion-modal-gird">
                  <div className="promotion-card">
                    <div className="promotion-img">
                      <img
                        src={modalPromotion?.specials_image_new}
                        width="162"
                        height="162"
                        alt="promotionimg1"
                      />
                    </div>
                    <h3>
                      {modalPromotion?.specials_title || "Square One Salon"}
                    </h3>
                    <div>
                      {parse(
                        modalPromotion?.specials_description ||
                          "Square One Salon"
                      )}
                    </div>
                  </div>
                  <div className="promotion-download">
              {modalPromotion?.specials_title === "Spa Packages" ? (
                <Link
                  to={`${BASEURL}/gift-card`}
                  onClick={handleLinkClick}
                  className="btn btn-link"
                >
                  Purchase
                </Link>
              ) : (
                modalPromotion?.specials_image_new && (
                  <button
                    onClick={() =>
                      convertAndDownloadDynamic(
                        modalPromotion?.specials_image_new,
                        modalPromotion?.specials_title,
                        `${path}-${modalPromotion?.specials_title}-Promotions`
                      )
                    }
                    className="btn btn-link"
                    download="download"
                  >
                    Download
                  </button>
                )
              )}
            </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
