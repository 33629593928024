/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";

import { Link } from "react-router-dom";
import {  Autoplay, Pagination } from 'swiper/modules'; 
import { Swiper, SwiperSlide } from 'swiper/react'; 
import locations from '../locations/locations.json'; // Import the locations object
import useFetch from "../../fetch/useFetch";
import { formatPhoneNumber } from "../../utils/formatPhoneNumber";
import "../locations/location.css";
import 'swiper/css';
import 'swiper/css/pagination';
import { Helmet } from "react-helmet-async";


import { Navigation } from 'swiper/modules';



const BASEURL = process.env.REACT_APP_SQUAREONE_BASE_URL;
const SALONAPI = process.env.REACT_APP_SQUAREONE_API_EXTERNAL;
const alternativeImg = process.env.REACT_APP_ALTERNATIVE_IMG;

export default function Location() {

// const navigate = useNavigate()
  const [images, setImages] = useState();
  const [loading, setLoading] = useState(true);

  const galleryUrls = [
    `${SALONAPI}/galleryList1`,
    `${SALONAPI}/galleryList2`,
    `${SALONAPI}/galleryList3`, 
    `${SALONAPI}/galleryList4`,
    `${SALONAPI}/galleryList5`,
    `${SALONAPI}/galleryList6`
  ];


  useEffect(() => {
    const fetchData = async () => {
      try {
        const galleryPromises = galleryUrls.map(url =>
          fetch(url).then(response => response.json())
        );

        const galleryData = await Promise.all(galleryPromises);

        const firstImages = galleryData.map(gallery =>
          gallery?.slice(0, 3).map(item => item.gallery_image)
        ).filter(gallery_image => !!gallery_image);

        setImages(firstImages);
        setLoading(false); // Set loading to false after data is fetched
      } catch (error) {
        console.error('Error fetching gallery data:', error);
      }
    };
      fetchData();
    }, []);


    const { data: salonDayTonInfo } = useFetch(`${SALONAPI}/DaytonInfo`);

    let dayTonSalonName = salonDayTonInfo?.salon_name?.split('-').pop() || "";
    let daytonAddress = salonDayTonInfo?.salon_address?.toLowerCase() || "";
    let daytonCity = salonDayTonInfo?.salon_city?.toLowerCase() || "";
    let daytonPhone = salonDayTonInfo?.salon_contact_no || "";
    let daytonState = salonDayTonInfo?.salon_state || "";
    let daytonSZip = salonDayTonInfo?.salon_zip || "";

    const { data: salonBrownInfo } = useFetch(`${SALONAPI}/BrownInfo`);

    let brownSalonName = salonBrownInfo?.salon_name?.split('-').pop() || "";
    let brownSalonAddress = salonBrownInfo?.salon_address?.toLowerCase() || "";
    let brownSalonCity = salonBrownInfo?.salon_city?.toLowerCase() || "";
    let brownSalonState = salonBrownInfo?.salon_state || "";
    let brownSalonZip = salonBrownInfo?.salon_zip || "";
    let brownSalonPhone = salonBrownInfo?.salon_contact_no || "";

    const { data: salonCentervilleInfo } = useFetch(`${SALONAPI}/CentervilleInfo`);

    let centervilleName = salonCentervilleInfo?.salon_name?.split('-').pop() || "";
    let centervilleAddress = salonCentervilleInfo?.salon_address?.toLowerCase() || "";
    let centervilleState = salonCentervilleInfo?.salon_state || "";
    let centervilleCity = salonCentervilleInfo?.salon_city?.toLowerCase() || "";
    let centervilleZip = salonCentervilleInfo?.salon_zip || "";
    let centervillePhone = salonCentervilleInfo?.salon_contact_no || "";

    const { data: salonOAKInfo } = useFetch(`${SALONAPI}/salonOAKInfoList`);

    let salonOAKName = salonOAKInfo?.salon_name?.split('-').pop() || "";
    let salonOAKAddress = salonOAKInfo?.salon_address?.toLowerCase() || "";
    let salonOAKState = salonOAKInfo?.salon_state || "";
    let salonOAKCity = salonOAKInfo?.salon_city?.toLowerCase() || "";
    let salonOAKZip = salonOAKInfo?.salon_zip || "";
    let salonOAKPhone = salonOAKInfo?.salon_contact_no || "";

    const { data: salonColumbusInfo } = useFetch(`${SALONAPI}/salonColumbusInfoList`);

    let salonColumbusInfoName = salonColumbusInfo?.salon_name?.split('-').pop() || "";
    let salonColumbusInfoAddress = salonColumbusInfo?.salon_address?.toLowerCase() || "";
    let salonColumbusInfoState = salonColumbusInfo?.salon_state || "";
    let salonColumbusInfoCity = salonColumbusInfo?.salon_city?.toLowerCase() || "";
    let salonColumbusInfoZip = salonColumbusInfo?.salon_zip || "";
    let salonColumbusInfoPhone = salonColumbusInfo?.salon_contact_no || "";

    const { data: salonAlbanyInfo } = useFetch(`${SALONAPI}/salonAlbanyInfoList`);

    let salonAlbanyName = salonAlbanyInfo?.salon_name?.split('-').pop() || "";
    let salonAlbanyAddress = salonAlbanyInfo?.salon_address?.toLowerCase() || "";
    let salonAlbanyState = salonAlbanyInfo?.salon_state || "";
    let salonAlbanyCity = salonAlbanyInfo?.salon_city?.toLowerCase() || "";
    let salonAlbanyZip = salonAlbanyInfo?.salon_zip || "";
    let salonAlbanyPhone = salonAlbanyInfo?.salon_contact_no || "";


   




    if (loading) {
      return <>

      <div className="shimmer-title location_wrapper" style={{paddingBottom:'0'}}>
        <div className="shimmer" style={{display:'block',height:'50px',width:'280px',marginBottom:'20px',borderRadius:'10px'}}></div>
        <div className="shimmer" style={{display:'block',height:'25px',width:'480px',marginBottom:'20px',borderRadius:'10px'}}></div>
          <br/><br/>
        <div className="shimmer" style={{display:'block',height:'30px',width:'480px',marginBottom:'20px',borderRadius:'10px'}}></div>

      </div>
      
      <div className="shimmer-main location_wrapper" style={{display:'flex',flexWrap:'wrap', gap:'16px',paddingTop:'10px'}}><div className="shimmer location_gird bg-transparent " style={{minHeight:'360px',height:'360px'}}></div> <div className="shimmer location_gird bg-transparent " style={{minHeight:'360px',height:'360px'}}></div><div className="shimmer location_gird bg-transparent " style={{minHeight:'360px',height:'360px'}}></div><div className="shimmer location_gird bg-transparent " style={{minHeight:'360px',height:'360px'}}></div> <div className="shimmer location_gird bg-transparent " style={{minHeight:'360px',height:'360px'}}></div>  <div className="shimmer location_gird bg-transparent " style={{minHeight:'360px',height:'360px'}}></div> </div>

      </>; 
    }


 
  let locationDetails = locations && locations.map((location) => {
      return location;
  });

  // const handleLinkClick = (event) => {
  //   event.preventDefault(); // Prevent React Router's navigation
  //   window.location.href = event.currentTarget.href; // Trigger full reload
  // };
  return (
    <>
        <Helmet>
        <title>Top Hair & Spa Services Near Dayton Ohio| Square One Salon</title>
        <meta name="description" content="Find the best hair & spa services in Dayton, Brown St, Centerville, Oak Creek, Columbus, & New Albany, OH. Visit Square One Salon for premium beauty treatments!" />
       </Helmet>

    <div className="location_bg">
      <div className="container">
        <div className="location_wrapper">
          <div className="location-heaing">
            <h2> Find a Location</h2>
            <p>To get started, please select a location from below</p>
          </div>
          <div className="location-column-sec">
            <h3>
              Locations near or around <span>Dayton</span>
            </h3>
            <div className="row">

            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
              {
                  <div className="location_gird">
               {images && <Swiper 
                  modules={[Pagination, Autoplay, Navigation]}
                  spaceBetween={50}
                  slidesPerView={1}
                  navigation={true}
                  loop={true}
                 
                  pagination={{ clickable: true }}
                  scrollbar={{ draggable: false }}>
                  {
                    images && images[0].map((gallery, index)=> (
                      
                      <SwiperSlide key={Math.random(2) * index}>
                      <div
                  className="location_img"
                  onClick={() => {
                    const url = `${BASEURL}/location/${locationDetails[0]?.locationName
                      ?.toLowerCase()
                      ?.split(' ')
                      ?.join('-')}`;
                    
                    // Trigger full page reload
                    window.location.href = url;
                  }}
                >

                          <img
                              src={gallery || alternativeImg}
                              width="240"
                              height="240"
                              alt="Dayton Gallery Images"
                              className="img-fluid"
                            />
                          </div>
                    </SwiperSlide>
                    ))
                  } 
                 
                  
                </Swiper>    
                } 
                <div className="location_content">
                  
                <h4><Link to = {`${BASEURL}/location/${locationDetails[0]?.locationName && locationDetails[0]?.locationName.toLowerCase().split(' ').join('-')}`}>{salonDayTonInfo && dayTonSalonName} 
                    <span>
                      <img
                        src={`${BASEURL}/assets/images/info.svg`}
                        width="14"
                        height="14"
                        alt="info"
                      />
                    </span></Link>
                  </h4>
                  <p className="textcapitalization">
                    
                    <Link to = {locationDetails[0]?.locationLink && locationDetails[0]?.locationLink} target="_blank">

                    {
                      salonDayTonInfo &&
                      (<>{salonDayTonInfo && daytonAddress},<br/>{salonDayTonInfo && daytonCity}, {salonDayTonInfo && daytonState} {salonDayTonInfo && daytonSZip}</>)
                    }
                    
                     
                    
                    </Link>
                    
                    </p>
                  <Link to={`tel:${daytonPhone}`}>{salonDayTonInfo && formatPhoneNumber(daytonPhone)}</Link>

                </div>

              </div>
              }
            
            </div>

            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
              <div className="location_gird" >
                <Swiper
                  modules={[Pagination, Autoplay, Navigation]}
                  spaceBetween={50}
                  slidesPerView={1}
                  navigation={true}
                  loop={true}
                 
                  pagination={{ clickable: true }}
                  scrollbar={{ draggable: false }}
                  
                >

  
                  {
                    images && images[1].map((gallery, index)=> ( 
                      <SwiperSlide key={Math.random(3) * index}>
                       <div
                        className="location_img"
                        onClick={() => {
                          const url = `${BASEURL}/location/${locationDetails[1].locationName
                            .toLowerCase()
                            .split(' ')
                            .join('-')}`;
                          window.location.href = url; // Triggers full reload
                        }}
                      >
                         
                         
                          <img
                              src={gallery || alternativeImg}
                              width="240"
                              height="240"
                              alt="Brown St Gallery Images"
                              className="img-fluid"
                            />
                          </div>
                    </SwiperSlide>
                    ))
                  } 
                 
                  
                </Swiper>    

                <div className="location_content">
                   
                   
                <h4><Link to = {`${BASEURL}/location/${locationDetails[1]?.locationName && locationDetails[1]?.locationName.toLowerCase().split(' ').join('-')}`}>{salonBrownInfo && brownSalonName}
                      <span>
                        <img
                          src={`${BASEURL}/assets/images/info.svg`}
                          width="14"
                          height="14"
                          alt="info"
                        />
                      </span></Link> 
                    </h4>
                    <p className="textcapitalization"> <Link to = {locationDetails[1]?.locationLink && locationDetails[1]?.locationLink} target="_blank">

                    {
                      salonBrownInfo && (<>{salonBrownInfo && brownSalonAddress},<br/>{salonBrownInfo && brownSalonCity}, {salonBrownInfo && brownSalonState} {salonBrownInfo && brownSalonZip}</>)
                    }
                    

                      </Link></p>
                    <Link to={`tel:${brownSalonPhone}`}>{salonBrownInfo && formatPhoneNumber(brownSalonPhone)}</Link>
                  </div>

              </div>
            </div>

            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
              <div className="location_gird">
                <Swiper
                  modules={[Pagination, Autoplay, Navigation]}
                  spaceBetween={50}
                  slidesPerView={1}
                  navigation={true}
                  loop={true}
                 
                  pagination={{ clickable: true }}
                  scrollbar={{ draggable: false }}
                  
                >

  
                  {
                    images && images[2].map((gallery, index)=> ( 
                      <SwiperSlide key={Math.random(4) * index}>
                        
                        <div
                        className="location_img"
                        onClick={() => {
                          const url = `${BASEURL}/location/${locationDetails[2].locationName
                            .toLowerCase()
                            .split(' ')
                            .join('-')}`;
                          window.location.href = url; // Triggers full reload
                        }}
                      >

                          <img
                              src={gallery || alternativeImg}
                              width="240"
                              height="240"
                              alt="Centerville Gallery Images"
                              className="img-fluid"
                            />
                          </div>
                    </SwiperSlide>
                    ))
                  } 
                 
                  
                </Swiper>    

                <div className="location_content">

                

                <h4><Link to = {`${BASEURL}/location/${locationDetails[2]?.locationName && locationDetails[2]?.locationName.toLowerCase().split(' ').join('-')}`}>{salonCentervilleInfo && centervilleName}
                      <span>
                        <img
                          src={`${BASEURL}/assets/images/info.svg`}
                          width="14"
                          height="14"
                          alt="info"
                        />
                      </span></Link> 
                    </h4>
                    <p className="textcapitalization"><Link to = {locationDetails[2]?.locationLink && locationDetails[2]?.locationLink} target="_blank">
                   

                   {salonCentervilleInfo && (<>{salonCentervilleInfo && centervilleAddress},<br/>{salonCentervilleInfo && centervilleCity}, {salonCentervilleInfo && centervilleState} {salonCentervilleInfo && centervilleZip}</>)}

                    

                     

                      </Link>
                    </p>
                    <Link to={`tel:${centervillePhone}`}>{salonCentervilleInfo && formatPhoneNumber(centervillePhone)}</Link>
                  </div>
                  
              </div>
            </div>

            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
              <div className="location_gird">
                <Swiper
                  modules={[Pagination, Autoplay, Navigation]}
                  spaceBetween={50}
                  slidesPerView={1}
                  navigation={true}
                  loop={true}
                 
                  pagination={{ clickable: true }}
                  scrollbar={{ draggable: false }}
                  
                >

  
                  {
                    images && images[3].map((gallery, index)=> ( 
                      <SwiperSlide key={Math.random(5) * index}>
                        
                        <div
                        className="location_img"
                        onClick={() => {
                          const url = `${BASEURL}/location/${locationDetails[3].locationName
                            .toLowerCase()
                            .split(' ')
                            .join('-')}`;
                          window.location.href = url; // Triggers full reload
                        }}
                      >

                          <img
                              src={gallery || alternativeImg}
                              width="240"
                              height="240"
                              alt="Oak Creek Gallery Images"
                              className="img-fluid"
                            />
                          </div>
                    </SwiperSlide>
                    ))
                  } 
                 
                  
                </Swiper> 

                 

                <div className="location_content">
                <h4><Link to = {`${BASEURL}/location/${locationDetails[3]?.locationName && locationDetails[3]?.locationName.toLowerCase().split(' ').join('-')}`}>{salonOAKInfo && salonOAKName}
                      <span>
                        <img
                          src={`${BASEURL}/assets/images/info.svg`}
                          width="14"
                          height="14"
                          alt="info"
                        />
                      </span></Link></h4>
                    <p className="textcapitalization"> <Link to = {locationDetails[3]?.locationLink && locationDetails[3]?.locationLink} target="_blank">
                    
                    {
                      salonOAKInfo && (<> {salonOAKInfo && salonOAKAddress},<br/>{salonOAKInfo && salonOAKCity}, {salonOAKInfo && salonOAKState} {salonOAKInfo && salonOAKZip}</>)
                    }
                   



                      </Link>
                    </p>
                    <Link to={`tel:${salonOAKPhone}`}>{salonOAKInfo && formatPhoneNumber(salonOAKPhone)}</Link>
                  </div>
                  
              </div>
            </div>


            </div>
          </div>

          <div className="location-column-sec">
            <h3>
              Locations near or around <span>Columbus</span>
            </h3>
            <div className="row"> 
                 
            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
              <div className="location_gird">
                <Swiper
                  modules={[Pagination, Autoplay, Navigation]}
                  spaceBetween={50}
                  slidesPerView={1}
                  navigation={true}
                  loop={true}
                 
                  pagination={{ clickable: true }}
                  scrollbar={{ draggable: false }}
                  
                >

                  {
                    images && images[4].map((gallery, index)=> ( 
                      <SwiperSlide key={Math.random(6) * index}>
                       
                        <div
                        className="location_img"
                        onClick={() => {
                          const url = `${BASEURL}/location/${locationDetails[4].locationName
                            .toLowerCase()
                            .split(' ')
                            .join('-')}`;
                          window.location.href = url; // Triggers full reload
                        }}
                      >

                          <img
                             src={gallery || alternativeImg}
                              width="240"
                              height="240"
                              alt="Columbus Gallery Images"
                              className="img-fluid"
                            />
                          </div>
                    </SwiperSlide>
                    ))
                  } 
                 
                  
                </Swiper>    

                <div className="location_content">

               
               
                <h4><Link to = {`${BASEURL}/location/${locationDetails[4]?.locationName && locationDetails[4]?.locationName.toLowerCase().split(' ').join('-')}`}>{salonColumbusInfo && salonColumbusInfoName} 

                  <span>
                    <img
                      src={`${BASEURL}/assets/images/info.svg`}
                      width="14"
                      height="14"
                      alt="info"
                    />
                  </span></Link>
                </h4>
                <p><Link to = {locationDetails[4]?.locationLink && locationDetails[4]?.locationLink} target="_blank">

                 
                  {salonColumbusInfo && (<>{salonColumbusInfo && salonColumbusInfoAddress},<br/>{salonColumbusInfo && salonColumbusInfoCity}, {salonColumbusInfo && salonColumbusInfoState} {salonColumbusInfo && salonColumbusInfoZip}</>)}
                  



                  </Link>
                </p>
                <Link to={`tel:${salonColumbusInfoPhone}`}>{salonColumbusInfo && formatPhoneNumber(salonColumbusInfoPhone)}</Link>
              </div>
                  
              </div>
            </div>

            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
              <div className="location_gird">
                <Swiper
                  modules={[Pagination, Autoplay, Navigation]}
                  spaceBetween={50}
                  slidesPerView={1}
                  navigation={true}
                  loop={true}
                 
                  pagination={{ clickable: true }}
                  scrollbar={{ draggable: false }}
                  
                >

  
                  {
                    images && images[5].map((gallery, index)=> ( 
                      <SwiperSlide key={Math.random(7) * index}>
                       
                        <div
                        className="location_img"
                        onClick={() => {
                          const url = `${BASEURL}/location/${locationDetails[5].locationName
                            .toLowerCase()
                            .split(' ')
                            .join('-')}`;
                          window.location.href = url; // Triggers full reload
                        }}
                      >

                         
                          <img
                              src={gallery || alternativeImg}
                              width="240"
                              height="240"
                              alt='New Albany Gallery Images'
                              className="img-fluid"
                            />
                          </div>
                    </SwiperSlide>
                    ))
                  } 
                 
                  
                </Swiper>    

                <div className="location_content">

               

                <h4><Link to = {`${BASEURL}/location/${locationDetails[5]?.locationName && locationDetails[5]?.locationName.toLowerCase().split(' ').join('-')}`}>{salonAlbanyInfo && salonAlbanyName}
                    <span>
                      <img
                        src={`${BASEURL}/assets/images/info.svg`}
                        width="14"
                        height="14"
                        alt="info"
                      />
                    </span></Link> 
                  </h4>
                  <p> <Link to = {locationDetails[5]?.locationLink && locationDetails[5]?.locationLink} target="_blank">
                   
                   {salonAlbanyInfo && (<>{salonAlbanyInfo && salonAlbanyAddress},<br/>{salonAlbanyInfo && salonAlbanyCity}, {salonAlbanyInfo && salonAlbanyState} {salonAlbanyInfo && salonAlbanyZip}</>)}

                   

                   </Link>
                  </p>
                  <Link to={`tel:${salonAlbanyPhone}`}>{salonAlbanyInfo && formatPhoneNumber(salonAlbanyPhone)}</Link>
                </div>
                  
              </div>
            </div>

            </div>
          </div>

        </div>
      </div>
      </div>
    </>
  );
}
