
const SALONAPI = process.env.REACT_APP_SQUAREONE_API_EXTERNAL; 

export const convertAndDownload = async (url, fileName) => {
    try {
      const response = await fetch(`${SALONAPI}/fetch-image?url=${encodeURIComponent(url)}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const blob = await response.blob();
  
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64 = reader.result;
        const link = document.createElement('a');
        link.href = base64;
        link.download = fileName;
  
        link.click();
      };
      reader.readAsDataURL(blob);
    } catch (error) {
      console.error('Error converting and downloading image:', error);
    }
  };
  


 export  const convertAndDownloadDynamic = async (url, title, fileName) => {
   
    try {
      const response = await fetch(`${SALONAPI}/fetch-image?url=${encodeURIComponent(url)}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
     
      const blob = await response.blob();
      
      const reader = new FileReader();
      
      reader.onloadend = () => {
        const base64 = reader.result;
        const link = document.createElement('a');
        link.href = base64;
        link.download = fileName;
        link.click();
      };
      reader.readAsDataURL(blob);
     
    } catch (error) {
      console.error('Error converting and downloading image:', error);
    }
  };