import React, { useContext, useState , Suspense} from "react";
import "../about/about.css";
import { Link } from "react-router-dom";
import SiteContext from "../../helpers/CreateContext";
import parse from 'html-react-parser';
import { Helmet } from "react-helmet-async";
import useFetch from "../../fetch/useFetch";

const BASEURL = process.env.REACT_APP_SQUAREONE_BASE_URL;
const SALONAPI = process.env.REACT_APP_SQUAREONE_API_EXTERNAL;

const FrequentlyFaq = React.lazy(() => import('./FrequentlyFaq'));


export default function About() { 

  const {data:gallery} =  useFetch(`${SALONAPI}/aboutGalleryData`);
 
  const {salonData, salonInfoPending} = useContext(SiteContext); 

  const configList = salonData && salonData?.salon_info && salonData?.salon_info?.config && salonData?.salon_info?.config?.config_aboutus && salonData?.salon_info?.config?.config_aboutus.split('~')
 
  const [activeTab, setActiveTab] = useState('tab1');

  

  const handleTabClick = (tab) => {
      setActiveTab(tab);
      const element = document.getElementById(tab);
      if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
      }
  };

  
  if(salonInfoPending){
    return <div className="inner-banner-main"><div className="shimmer" style={{display:'block',minHeight:'804px'}}></div>
    
    
    
    <div className="container">
      <div  className="our-story">
      <div className="row">
        <div className="col-md-6 col-12 pe-md-0">
          <div className='shimmer bg-transparent' style={{minHeight:'473px',height:'473px',display:'block',borderRadius:'10px'}}>

          </div>
        </div>
        <div className="col-md-6 col-12 px3">
        <div className="row">
            <div className="col-md-6 col-6 px2">
                <div className="our-storyImg shimmer"  style={{minHeight:'205',height:'208',display:'block',borderRadius:'10px'}}><div className="shimmer bg-transparent "></div></div>
            </div>
            <div className="col-md-6 col-6 px2">
            <div className="our-storyImg shimmer"  style={{minHeight:'205',height:'208',display:'block',borderRadius:'10px'}}><div className="shimmer bg-transparent "></div></div>
            </div>
            <div className="col-md-6 col-6 px2">
            <div className="our-storyImg shimmer"  style={{minHeight:'205',height:'208',display:'block',borderRadius:'10px'}}><div className="shimmer bg-transparent "></div></div>
            </div>
            <div className="col-md-6 col-6 px2">
            <div className="our-storyImg shimmer"  style={{minHeight:'205',height:'208',display:'block',borderRadius:'10px'}}><div className="shimmer bg-transparent "></div></div>
            </div>
        </div>
    </div>
      </div>
      </div>
      </div>

      <div className="homebanner-section">
            <div className="video-inner shimmer" style={{height:'354px', display:'block'}}> </div>
        </div>
        
    </div>
  }

  return (
    <>
       <Helmet>
        <link rel="canonical" href="https://www.squareonesalon.com/aboutus" />
        <title>About Square One Salon & Spa: #1 Aveda Hair Salon In Ohio</title>
        <meta name="description" content="Read about Square One Salon Spa, a full-service Aveda salon & spa and retail store with 6 locations in Ohio, offering salon & wellness services for men-women." />
       </Helmet>

    
      <div className="inner-banner-main">
        {salonData && parse(salonData?.website_settings?.new_about_main_banner && salonData?.website_settings?.new_about_main_banner)}
      </div>

      <div className="our-story">
        {salonData && parse(salonData?.website_settings?.New_our_story_info && salonData?.website_settings?.New_our_story_info)}

        <div className="row">
          <>
            <div className="col-md-6 col-12 pe-md-0">
              <div className="our-storyImg">
                <img 
                  alt="our story1" 
                  height="423" 
                  src={gallery && gallery[0].gallery_image && gallery[0].gallery_image} 
                  width="574" 
                />
              </div>
            </div>

            <div className="col-md-6 col-12 px3">
              <div className="row">
                <div className="col-md-6 col-6 px2">
                  <div className="our-storyImg">
                    <img 
                      alt="our story2" 
                      height="205" 
                      src={gallery && gallery[1].gallery_image && gallery[1].gallery_image} 
                      width="272" 
                    />
                  </div>
                </div>

                <div className="col-md-6 col-6 px2">
                  <div className="our-storyImg">
                    <img 
                      alt="our story3" 
                      src={gallery && gallery[2].gallery_image && gallery[2].gallery_image} 
                    />
                  </div>
                </div>

                <div className="col-md-6 col-6 px2">
                  <div className="our-storyImg">
                    <img 
                      alt="our story4" 
                      height="205" 
                      src={gallery && gallery[3].gallery_image && gallery[3].gallery_image} 
                      width="272" 
                    />
                  </div>
                </div>

                <div className="col-md-6 col-6 px2">
                  <div className="our-storyImg">
                    <img 
                      alt="our story5" 
                      height="205" 
                      src={gallery && gallery[4].gallery_image && gallery[4].gallery_image} 
                      width="272" 
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      </div>



  

      <div className="our_four_corner_bg">
        <h3>Our Four Corner Focus</h3>
        <div className="our_four_gird">
        
          <ul className="nav nav-tabs">
              <li className="nav-item">
                  <Link id="tab1" className={`nav-link ${activeTab === 'tab1' ? 'active' : ''}`} onClick={(e) => { e.preventDefault(); handleTabClick('tab1')}}>
                      Customers
                  </Link>
              </li>
              <li className="nav-item">
                  <Link id="tab2" className={`nav-link ${activeTab === 'tab2' ? 'active' : ''}`} onClick={(e) => {e.preventDefault(); handleTabClick('tab2')}}>
                      Strength
                  </Link>
              </li>
              <li className="nav-item">
                  <Link id="tab3" className={`nav-link ${activeTab === 'tab3' ? 'active' : ''}`} onClick={(e) => {e.preventDefault(); handleTabClick('tab3')}}>
                      Charity
                  </Link>
              </li>
              <li className="nav-item">
                  <Link id="tab4" className={`nav-link ${activeTab === 'tab4' ? 'active' : ''}`} onClick={(e) =>{e.preventDefault(); handleTabClick('tab4')}} >
                      Team
                  </Link>
              </li>
          </ul>
          <div className="tab-content">
          <div className={`tab-pane fade ${ activeTab === "tab1" ? "show active" : "" }`} id="tab1">
            <div className="our_four_cornerContent">
              <div className="our_fourIcon">
                <img src={`${BASEURL}/assets/images/Customers-icon.svg`} width="34" height="26.92" alt="Customers icon" />
              </div>
             <>
              { salonData && parse(configList[0])}
              </>
            </div>
          </div>
          <div className={`tab-pane fade ${ activeTab === "tab2" ? "show active" : "" }`} id="tab2" >
            <div className="our_four_cornerContent">
              <div className="our_fourIcon">
                <img src={`${BASEURL}/assets/images/strength-handshake.svg`} width="34" height="26.92" alt="strength icon" />
              </div>
              <>
              { salonData && parse(configList[1])}
              </>
            </div>
          </div>
          <div className={`tab-pane fade ${   activeTab === "tab3" ? "show active" : "" }`} id="tab3" >
            <div className="our_four_cornerContent">
              <div className="our_fourIcon">
                <img src={`${BASEURL}/assets/images/charity-volunteer.svg`} width="34" height="26.92" alt="charity icon" />
              </div>
              <>
              { salonData && parse(configList[2])}
              </>
            </div>
          </div>
          <div className={`tab-pane fade ${   activeTab === "tab4" ? "show active" : "" }`} id="tab4" >
            <div className="our_four_cornerContent">
              <div className="our_fourIcon">
                <img src={`${BASEURL}/assets/images/user-abt.svg`} width="34" height="26.92" alt="team icon" />
              </div>
              <>
              { salonData && parse(configList[3])}
              </>
            </div>
          </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="about-community-wrapper">
          <div className="row">
            {salonData && parse(salonData?.website_settings?.new_about_community_engagement && salonData?.website_settings?.new_about_community_engagement)}
            
          </div>
          <div className="row">
            {salonData && parse(salonData?.website_settings?.new_about_giving_back && salonData?.website_settings?.new_about_giving_back)}
           
          </div>
        </div>
      </div>

      
      <Suspense fallback={<div style={{visibility:'hidden'}}>Loading...</div>}>
        <FrequentlyFaq BASEURL= {BASEURL} salonData = {salonData} SALONAPI= {SALONAPI}/>
      </Suspense>

     


    </>
  );
}
