import React, { useEffect, useState, useContext} from "react";
import "../donation/donation.css";
import { Link } from "react-router-dom";
import SiteContext from '../../helpers/CreateContext';
import DonationForm from "../../components/DonationForm";
import parse from 'html-react-parser';
import { Helmet } from "react-helmet-async";

const BASEURL = process.env.REACT_APP_SQUAREONE_BASE_URL;

export default function Donation() {
  const { salonData, salonInfoPending } = useContext(SiteContext); 



  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (showModal) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [showModal]);

  // const openModal = () => {
  //   setShowModal(true);
  //   setShowModal((prev) => ({ ...prev, fadeIn: true }));
  // };

  const closeModal = () => {
    setShowModal(false);
  };


  /* ---- Form ----*/


  // if(salonInfoPending){
  //   return <HomePageShimmer/>
  // }
  

  return (
    <>

       <Helmet>
       <link rel="canonical" href="https://www.squareonesalon.com/donations" />
        <title>Donations | Square One Salon & Spa Aveda Salon</title>
        <meta name="description" content="Square One Salon and Spa believes in contributing to and being involved in our community. If you are interested in us contributing to your cause please fill out this form." />
       </Helmet>

   <div className="inner-banner-main">
        {
           !salonInfoPending ? (<>  {salonData && parse(salonData.website_settings.new_donation_main_banner && salonData.website_settings.new_donation_main_banner) }</>) : (<><div className="videoWrapperli">
            <div className="shimmer" style={{ minHeight: '324px', display: 'block' }}></div>
          </div></>)
        }
        
      </div>
    
    
     
      <div className="container">
        <div className="contactWrapper">
          <h3>We would love to help</h3>
          <p>
            Kindly complete the form below so that we can evaluate your request.
            For timely consideration, please submit the form 6-8 weeks prior to
            your event.
          </p>
           <DonationForm/>


        </div>
      </div>

      {showModal && (
        <div
          id="StaffModal"
          className={`modal-staff contact_modal ${
            showModal.fadeIn ? "show" : ""
          }`}
        >
          <div className="modal-staff-dialog">
            <div className="modal-staff-content">
              <button type="button" className="close" onClick={closeModal}>
                <span>
                  <img
                    src={`${BASEURL}/assets/images/close.svg`}
                    width="16.35"
                    height="16.35"
                    alt="close icon"
                  />
                </span>
              </button>
              <div className="modal-staff-body">
                <div className="check-your-inbox-gird">
                  <div className="check-your-icon">
                    <img
                      src={`${BASEURL}/assets/images/mail.svg`}
                      width="34.06"
                      height="27.25"
                      alt="mail icon"
                    />
                  </div>
                  <h4>Your submission has been received!</h4>
                  <p>
                    Thank you for your interest our donation program.<br></br>{" "}
                    Keep yours eye open for an email within the next<br></br> 30
                    days from our team on next steps.
                  </p>
                  <Link to="" className="btn btn-primary">
                    Okay
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
