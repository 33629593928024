import React, { useState, useEffect, useRef, useCallback } from "react";
import "../locations/location.css";
import { Link , useLocation } from "react-router-dom";
import locations from '../locations/locations.json'; 
import useFetch from "../../fetch/useFetch";
import TopHeader from "../../components/TopHeader";
import FixedFooter from "../../components/FixedFooter";
import { formatDate } from "../../utils/formatDate";
import { Helmet } from "react-helmet-async";
import PageNotFound from "../PageNotFound/PageNotFound";

const BASEURL = process.env.REACT_APP_SQUAREONE_BASE_URL;
const SALONAPI = process.env.REACT_APP_SQUAREONE_API_EXTERNAL;

export default function LocationReview() {
const location = useLocation();
const pathSegments = location.pathname.split('/').filter(Boolean);
const path = pathSegments[pathSegments.length - 1]; // Get the last non-empty segment

const locationDetailsDayton = locations.filter(location => location.urlformat === path);
  
  const [reviews, setReviews] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [salonId] = useState(locationDetailsDayton[0].salonId);
  const [loading, setLoading] = useState(false);
  const [length, setlength] = useState(null)
  const limit = 3;

  const observer = useRef();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${SALONAPI}/directory_reviews`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            salon_id: salonId,
            offset: (currentPage - 1) * limit,
            limit: limit
          })
        });
        
  
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
  
        const data = await response.json();
        if (!data.reviews || !data.total_reviews) {
          throw new Error('Invalid response data');
        }

      
  
        setReviews(prevReviews => [...prevReviews, ...data.reviews]);
        setTotalPages(Math.ceil(data.total_reviews / limit));
        setlength(Math.floor(data.total_reviews))
  
      } catch (error) {
        console.error('Error fetching or parsing data:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData()
  }, [currentPage, salonId]);

 

  const {data : salonInfo }= useFetch(`${SALONAPI}/salon_info/${locationDetailsDayton[0]?.salonId}`);

  const lastReviewElementRef = useCallback(node => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && currentPage < totalPages) {
        setCurrentPage(prevPage => prevPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, [loading, currentPage, totalPages]);

    const filterReviews = reviews && reviews.filter(rev =>  rev?.rating === '5' &&  (rev?.review_source === 'google' || rev?.review_source  === "salonclouds" || rev?.review_source  === "yelp") ); // 

    
    if (!locationDetailsDayton || locationDetailsDayton.length === 0) {
      return <PageNotFound/>;
    }


  return (
    <>
      <Helmet>
      <link rel="canonical" href={locationDetailsDayton[0]?.curl_rev} />
        <title> {locationDetailsDayton[0]?.metaTitle_reviews || 'Aveda Hair & Spa Salon Ohio | Beauty & Wellness - Square One'} </title>
        <meta name="description" content={locationDetailsDayton[0]?.metaDes_reviews || 'Experience Best Aveda hair & spa services at Square One Salon in Ohio. Offering expert beauty & wellness treatments in Dayton & Columbus Ohio. Book now!'} />
       </Helmet>
     <TopHeader locationDetailsDayton={locationDetailsDayton[0]} BASEURL={BASEURL} />

      <div className="review_bg">
      <div className="container">
        <div className="location_wrapper location_team_wrapper">
          <div className="location-heaing">
            <h2>Reviews</h2>
            <p>
              Read reviews from our guests below or leave a review on Google
            </p>
          </div>
          <div className="review_wrapper">
          <h3>{length} Reviews</h3> 
            <div className="row">


            <div className="col-md-8 col-12">
                  <div className="review_gird">
                    {reviews && filterReviews.map((rev, index) => {
                      const isLastElement = index === filterReviews.length - 1;
                      return (
                        <div
                          className="review_list"
                          key={rev.review_id}
                          ref={isLastElement ? lastReviewElementRef : null}
                        >
                          <h4>{rev.reviewer_name}</h4>
                          <div className="review_star_sec">
                            <div className="review_star_left">
                              {Array.from({ length: rev?.rating }).map((_, index) => (
                                <span key={index}>
                                  <img
                                    src={`${BASEURL}/assets/images/star.svg`}
                                    width="20"
                                    height="20"
                                    alt="star icon"
                                  />
                                </span>
                              ))}
                            </div>
                            <small>{formatDate(rev?.review_datetime)}</small>
                          </div>
                          <p>{rev?.review_description}</p>
                          <h5 style={{display:'flex', alignItems:'center'}}>
                          Verified by 
                            <span style={{paddingLeft:'10px'}}>
                            {
                              rev?.review_source === 'google' ? (
                                <img
                                  src={`${BASEURL}/assets/images/Google.svg`}
                                  width="48"
                                  height="28"
                                  alt="google icon"
                                />
                              ) : rev?.review_source === 'salonclouds' ? (
                                <img
                                  src={`${BASEURL}/assets/images/rev-plus-logo.svg`}
                                  width="48"
                                  height="28"
                                  alt="Salon Cloud Plus icon"
                                />
                              ) : rev?.review_source === 'yelp' ? (
                                <img
                                  src={`${BASEURL}/assets/images/yelp.svg`}
                                  width="48"
                                  height="28"
                                  alt="Yelp icon"
                                />
                              ) : null
                            }

                              

                            </span>
                          </h5>
                        </div>
                      );
                    })}
                  </div>
                  {<div className="loader-max py-4"><img src={`${BASEURL}/assets/images/gif.gif`} alt='loader' /> </div>}

                </div>
              <div className="col-md-4 col-12">
                <div className="career_job locationReview_know">
                  <div className="career_job_content">
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="29.209"
                        height="29.209"
                        viewBox="0 0 29.209 29.209"
                      >
                        <path
                          id="reviews_FILL0_wght400_GRAD0_opsz24"
                          d="M90.333-862.109,94.6-864.7l4.272,2.592-1.132-4.856,3.8-3.286-5-.4-1.935-4.6-1.935,4.6-5,.4,3.8,3.286ZM80-850.791v-26.288a2.813,2.813,0,0,1,.858-2.063A2.813,2.813,0,0,1,82.921-880h23.367a2.813,2.813,0,0,1,2.063.858,2.813,2.813,0,0,1,.858,2.063v17.525a2.812,2.812,0,0,1-.858,2.063,2.813,2.813,0,0,1-2.063.858H85.842Zm4.6-8.763h21.688v-17.525H82.921v19.168Zm-1.68,0v0Z"
                          transform="translate(-80 880)"
                          fill="#97ad6e"
                        />
                      </svg>
                    </span>
                    <h3>Let us know…</h3>
                    <p>
                      How was your recent experience with us? Share a thought on
                      Google.
                    </p>

                    

                    <Link to={locationDetailsDayton[0]?.writeGoogleRev} target="
                    _blank" className="btn btn-primary">
                      Leave a Review
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>

      {salonInfo &&  <FixedFooter  locationDetailsDayton = {locationDetailsDayton[0]} salonInfo = {salonInfo}/>}
     

    </>
  );
}
