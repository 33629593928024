import React, { useState} from 'react';
import '../blog/blog.css';
import { Link } from 'react-router-dom';
import useFetch from '../../fetch/useFetch';
import { useParams } from 'react-router-dom';
import ArchivedPost from '../../components/ArchivedPost';
import { useLocation } from 'react-router-dom';

const BlogDetailsList = React.lazy(() => import('../../components/BlogDetailsList'));

const BASEURL = process.env.REACT_APP_SQUAREONE_BASE_URL;
const SALONAPI = process.env.REACT_APP_SQUAREONE_API_EXTERNAL;
export default function BlogDetails() {
  const { name } = useParams();
  const location = useLocation();
  const { data: blog, loading : BlogLoading , serverError } = useFetch(`${SALONAPI}/blogDetails`);
  const [visibleCount] = useState(3);
  const [tooltip, settooltip] = useState('')


  const allBlogs = blog && blog.slice(0, visibleCount);
 
  const copyPath = () => {
    const fullPath = window.location.origin + location.pathname;
    navigator.clipboard.writeText(fullPath);
    settooltip(true);
    setTimeout(() => {
      settooltip(false)
    },2000)
  };


 
  const fullPath = window.location.origin + location.pathname;


  if (BlogLoading) {
    return (
      <div>
        <div 
          className="blog-sortbywrapper blogdetail-sort shimmer" 
          style={{ minHeight: '54px', width: '100%' }} 
        ></div> 
      
       <div className='shimmer-px'>
       <div 
          className="blog-main blog-details-main" 
          style={{ maxWidth: '1144px', width: '100%', margin: '0 auto' }}
        >
          <div style={{display:'flex', gap:'16px'}} className='shimmer-res'>
          <div 
            className="blog-main-container shimmer blog-item-in" 
            style={{ minHeight: '600px', width: '752px', flex:'0 0 70%', borderRadius: '5px' }}
          ></div>
          
          <div className="blog-main-container shimmer blog-item-in" 
            style={{ height:'147px', width: '360', borderRadius: '5px',marginBottom:'16px' }}
          >

            
          </div>
          
          </div>
        </div>
       </div>
      </div>
    );
  }
  
 
  if (serverError) {
    return <div className='error-fetch'>Error: {serverError}</div>;
  }

  const selectedBlog = blog && blog.find(blogPost => blogPost.blog_url === name);

  if (!selectedBlog) {
    return <div className='text-center py-4 text-center' style={{textAlign:'center'}}>
       <div className='shimmer archived-posts-card' style={{minHeight:'600px',display:'block',width:'100%', borderRadius:'5px'}}></div>
    </div>;
  }

  return (
    <>
      <div className="blog-sortbywrapper blogdetail-sort">
        <div className="blog-sortby-gird">
          <div className="blog-sortbyleft">
            <p>Share this post:</p>
          </div>
          <div className="blog-sortbyright socialicons">
            <ul>
            <li>
            <Link to ={`mailto:squareonejohn@gmail.com?subject=Copy%20Blog%20Link&body=${fullPath}`}>
              <img src={`${BASEURL}/assets/images/email.svg`} width='28' height='28' alt='email'/>
            </Link>
          </li>
              <li>
                <Link onClick={copyPath}><img src={`${BASEURL}/assets/images/link.svg`} width='28' height='28' alt='link'/></Link>
                {
                  tooltip && (<div className='tooltip'><p>Link copied</p></div>)
                }
              </li>
            </ul>
          </div>
        </div>
      </div>


      <div className="blog-main blog-details-main" >
        {
          !BlogLoading ? (<div className="blog-main-container">
          <div className="row">
            <div className="col-12 col-sm-12 col-lg-8 col-md-12 blog-item" >
            
                <React.Suspense fallback={<div className='shimmer archived-posts-card' style={{minHeight:'600px',display:'block',width:'100%', borderRadius:'5px'}}></div>}>
                  <BlogDetailsList BASEURL={BASEURL} selectedBlog={selectedBlog} />
                </React.Suspense>
              
            </div>

            <div className="col-12 col-sm-6 col-lg-4 col-md-6 get-latest-info d-sm-block">
              <div className="archived-posts-gird">
                <h3>Archived Posts</h3>
                {
                  !BlogLoading ? (<React.Suspense fallback={<div>Loading...</div>}>
                  <ArchivedPost blog={allBlogs} />
                  </React.Suspense>) : (<div className='shimmer archived-posts-card' style={{minHeight:'107px',display:'block',width:'358px', borderRadius:'5px'}}></div>)
                }
                
               
              </div>
            </div>
          </div>
          <div className="text-start mt-3">
            <Link to={`${BASEURL}/blog`} className="btn btn-outline-primary" onClick={() => window.scrollTo({top:0, behavior:'smooth'})}>Back</Link>
          </div>
        </div>) : (<div className='shimmer archived-posts-card' style={{height:'800px',display:'block',width:'100%', borderRadius:'5px'}}></div>)
        }
        
      </div>
    </>
  );
}
