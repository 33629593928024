import React, {useEffect, useState, useRef} from "react";
import "../locations/location.css";
import { useLocation } from "react-router-dom";
import useFetch from "../../fetch/useFetch";
import locations from '../locations/locations.json'; 
import TopHeader from "../../components/TopHeader";
import FixedFooter from "../../components/FixedFooter";
import parse from 'html-react-parser';
import { cleanHtml } from "../../utils/cleanHtml";
import { convertAndDownloadDynamic } from "../../utils/convertAndDownload";
import { Helmet } from "react-helmet-async";

import PageNotFound from "../PageNotFound/PageNotFound";

const BASEURL = process.env.REACT_APP_SQUAREONE_BASE_URL;
const SALONAPI = process.env.REACT_APP_SQUAREONE_API_EXTERNAL;
const alternativeImg = process.env.REACT_APP_ALTERNATIVE_IMG;


export default function LocationPromotion() {
  
  const [showModal, setShowModal] = useState(false); 
  const [modalPromotion, setModalPromotion] = useState(null);
  const popupRef = useRef(null);

  const location = useLocation();
  // const path = location.pathname.split('/').pop();

  const pathSegments = location.pathname.split('/').filter(Boolean);
  const path = pathSegments[pathSegments.length - 1]; // Get the last non-empty segment
  
  
  let locationDetailsDayton = locations && locations.filter(location => location.urlformat === path);
  
  const { data: promotionStaff ,loading : promotionsPending  } = useFetch(`${SALONAPI}/wsspecials/${locationDetailsDayton[0].salonId}/${locationDetailsDayton[0].SpecialsID}`);
  const {data : salonInfo }= useFetch(`${SALONAPI}/salon_info/${locationDetailsDayton[0].salonId}`);
 

  useEffect(() => {
    if (showModal) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [showModal]);

  const openModal = (promotion) => {
   
    setModalPromotion(promotion);
    setShowModal(true);
    setShowModal((prev) => ({ ...prev, fadeIn: true }));
  };

  const closeModal = () => {
    setShowModal(false);
    setModalPromotion(null);
  };

  const handleClickOutside = (event) => {

    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setShowModal(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  
  
  
 

 

  
  if (promotionsPending) {
    return <><div className='shimmer blog-sortbywrapper bg-transparent' style={{minHeight:'48px',width:'100%',height:'48px'}}></div><div className="shimmer-main location_wrapper" style={{display:'flex',flexWrap:'wrap', gap:'16px'}}><div className="shimmer location_gird bg-transparent " style={{minHeight:'360px',height:'360px'}}></div> <div className="shimmer location_gird bg-transparent " style={{minHeight:'360px',height:'360px'}}></div><div className="shimmer location_gird bg-transparent " style={{minHeight:'360px',height:'360px'}}></div><div className="shimmer location_gird bg-transparent " style={{minHeight:'360px',height:'360px'}}></div> <div className="shimmer location_gird bg-transparent " style={{minHeight:'360px',height:'360px'}}></div>  <div className="shimmer location_gird bg-transparent " style={{minHeight:'360px',height:'360px'}}></div> <div className="shimmer location_gird bg-transparent " style={{minHeight:'360px',height:'360px'}}></div><div className="shimmer location_gird bg-transparent " style={{minHeight:'360px',height:'360px'}}></div></div></>; 
  }

  if (!locationDetailsDayton || locationDetailsDayton.length === 0) {
    return <PageNotFound/>;
  }


  return (
    <>
  <Helmet>
       <link rel="canonical" href={locationDetailsDayton[0]?.curl_promotions} />
        <title> {locationDetailsDayton[0]?.metaTitle_promotions || 'Aveda Hair & Spa Salon Ohio | Beauty & Wellness - Square One'} </title>
        <meta name="description" content={locationDetailsDayton[0]?.metaDes_promotions || 'Experience Best Aveda hair & spa services at Square One Salon in Ohio. Offering expert beauty & wellness treatments in Dayton & Columbus Ohio. Book now!'} />
       </Helmet>
     <TopHeader locationDetailsDayton={locationDetailsDayton[0]} BASEURL={BASEURL} />

      <div className="locationpromotion_bg">
      <div className="container">
        <div className="location_wrapper location_team_wrapper">
          <div className="location-heaing">
            <h2>Promotions</h2>
            <p>Explore our current promotions available at our {locationDetailsDayton[0]?.locationName} location</p>
          </div>
          <div className="location-column-sec"> 
          <div className="row mt-2">
          {
            promotionStaff && promotionStaff.filter((item => item.specials_title !== "Home_page_Special" && item.specials_title !== "Home_page_Refer")).map((promotionlist, index)=>(
              
              <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={index}>
              <div className="promotion-gird">
                <div className="promotion-card" onClick={() => openModal(promotionlist)}>
                  <div className="promotion-img">
                    <img
                      src={promotionStaff && promotionlist?.specials_image_new && promotionlist?.specials_image_new}
                      width="162"
                      height="162"
                      alt="promotionimg1"
                    />
                  </div>
                  <div className="promotionCont">
                  <h3>
                    {promotionStaff && promotionlist?.specials_title && promotionlist?.specials_title}
                    <span style={{cursor:'pointer'}} onClick={() => openModal(promotionlist)}>
                      <img
                        src={`${BASEURL}/assets/images/info.svg`}
                        width="14"
                        height="14"
                        alt="info"
                      />
                    </span>
                  </h3> 
                  <div className="promotion-min">{parse(cleanHtml(promotionStaff && promotionlist?.specials_description && promotionlist?.specials_description.replace(/<[^>]*>/g, '').replace(/\s+/g, ' ').trim()))} </div>
                  </div>
                </div>
                <div className="promotion-download">
                  <button  onClick={() => convertAndDownloadDynamic(promotionStaff && promotionlist?.specials_image_new && promotionlist?.specials_image_new, promotionlist?.specials_title, `${path}-${promotionlist.specials_title}-Promotions`)} className="btn btn-link">
                    Download
                  </button>
                </div>
              </div>
            </div> 
            ))
          } 
          </div>
          </div> 
        </div>
      </div>
      </div>


      {salonInfo &&  <FixedFooter  locationDetailsDayton = {locationDetailsDayton[0]} salonInfo = {salonInfo}/>}
     



      {showModal && modalPromotion && (
        <div
          id="StaffModal"
          className={`modal-staff ${showModal.fadeIn ? "show" : ""}`} 
        >
          <div className="modal-staff-dialog" ref={popupRef}>
            <div className="modal-staff-content">
              <button type="button" className="close" onClick={closeModal}>
                <span>
                  <img
                    src={`${BASEURL}/assets/images/close.svg`}
                    width="16.35"
                    height="16.35"
                    alt="close icon"
                  />
                </span>
              </button>
              <div className="modal-staff-body">
                <div className="promotion-modal-gird">
                  <div className="promotion-card">
                    <div className="promotion-img">
                      <img
                        src={modalPromotion?.specials_image_new || alternativeImg}
                        width="162"
                        height="162"
                        alt="promotionimg1"
                      />
                    </div>
                    <h3>{modalPromotion?.specials_title || 'Square One Salon'}</h3>
                    <div>{parse(modalPromotion?.specials_description || 'Square One Salon')}</div>
                  </div>
                  <div className="promotion-download">
                    {
                      modalPromotion?.specials_image_new && (<button onClick={() => convertAndDownloadDynamic(modalPromotion?.specials_image_new, modalPromotion?.specials_title, `${path}-${modalPromotion?.specials_title}-Promotions`)}  className="btn btn-link" download="download">
                      Download
                    </button>)
                    }
                    
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

    </>
  );
}
