import React, {useContext} from "react";
import "../lavelsystem/lavelsystem.css";
import SiteContext from '../../helpers/CreateContext';
import parse from 'html-react-parser';
import { Helmet } from "react-helmet-async";


export default function NewGuest() {
  const { salonData , salonInfoPending} = useContext(SiteContext); 

 

  return (
    <>

         <Helmet>
              <link rel="canonical" href="https://www.squareonesalon.com/new-guest" />
                <title> Aveda Hair & Spa Salon Ohio | Beauty & Wellness - Square One </title>
                <meta name="description" content="Experience Best Aveda hair & spa services at Square One Salon in Ohio. Offering expert beauty & wellness treatments in Dayton & Columbus Ohio. Book now!" />
               </Helmet>
      <div className="inner-banner-main">
        {
           !salonInfoPending ? (<>{salonData && parse(salonData && salonData.website_settings.new_guest_banner && salonData.website_settings.new_guest_banner) }</>) : (<><div className="videoWrapperli">
            <div className="shimmer" style={{ minHeight: '324px', display: 'block' }}></div>
          </div></>)
        }
        
      </div>

  

      <div className="container">
        {
          !salonInfoPending ? (<> {salonData && parse(salonData && salonData.website_settings.new_guest_content && salonData.website_settings.new_guest_content) }</>) : (<><div className="videoWrapperli">
           <>
           {[...Array(3)].map((_, index) => (
              <div className="levels_wrapper" style={{marginBottom:'20px'}}>
              <div className="shimmer"  style={{ minHeight: '25px', width:"100%", display: 'block',marginBottom:'16px' }}></div>
              <div className="shimmer"  style={{ minHeight: '25px', width:"95%", display: 'block',marginBottom:'16px' }}></div>
              <div className="shimmer"  style={{ minHeight: '25px', width:"90%", display: 'block', marginBottom:'16px' }}></div>
              </div>   
            ))}<br/><br/>
          </>
          </div></>)
        }

      </div>

    </>
  );
}
