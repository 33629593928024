import React, { useState, useContext, useEffect, useRef } from 'react';
import { Link, NavLink } from 'react-router-dom';
import SiteContext from '../../helpers/CreateContext';
import parse from 'html-react-parser';
const BASEURL = process.env.REACT_APP_SQUAREONE_BASE_URL;

 function Header() {
  const { salonServices } = useContext(SiteContext);
  const  {salonData} = useContext(SiteContext);
  const popupRef = useRef(null);

  const [navbarActive, setNavbarActive] = useState(false);
  const [sidebarOverlayActive, setSidebarOverlayActive] = useState(false);
  const [modal, setModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setNavbarActive(!navbarActive);
    setSidebarOverlayActive(!sidebarOverlayActive);
  };

 
  const toggleSubMenu = () => {
    setIsOpen(!isOpen);
  };

 

  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setModal(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const salonlist = salonServices?.filter((service) => service.blog_category_name === "Salon");
  const wellnesslist = salonServices?.filter((service) => service.blog_category_name === "Wellness");

  const handleLinkClick = (event) => {
    event.preventDefault(); // Prevent React Router's navigation
    window.location.href = event.currentTarget.href; // Trigger full reload
  };

  const webInfo = salonData && salonData?.website_settings;
 

  return (
    <>


      <div className="header" id="header">
        <div className="container-fluid">
          <div className="nav-brand-parent">
            <div className="head-left">
              <div className="navlist">
                <div className="logo">
                  <h1>
                    <Link to='/'>
                      <img src={`${BASEURL}/assets/images/logo.png`} width="350" height="124" alt="logo" style={{ width: '360px' }} />
                    </Link>
                  </h1>
                </div>
                <div className="navbar-header">
                  <button type="button" className={`navbar-toggle ${navbarActive ? 'active' : ''}`} onClick={toggleNavbar} aria-label="Menu">
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="sr-only"></span>
                  </button>
                </div>
              </div>
              <nav className="navbar navbar-default navbar-expand-lg clearfix" role="navigation">
                <div className={`navbar-collapse ${navbarActive ? 'active' : ''}`}>
                  <ul className="nav navbar-nav" id="mainmenu">
                    <li className={isOpen ? "has-children open" : "has-children"}>
                      <Link to={`${BASEURL}/services`}>Services</Link>
                      <span onClick={toggleSubMenu} style={{ width: '24px', height: '24px' }}></span>
                      <div className="droplist-parent">
                        <ul className="droplist clearfix">
                          <li className="nav-items-list">
                            <ul className="nav-salon">
                              <li><strong>Salon</strong></li>
                              {salonlist?.map((service, index) => (
                                <li key={index}>
                                  <NavLink onClick={handleLinkClick}
                                    to={`${BASEURL}/services/${service.blog_category_name.toLowerCase()}/${service.post_title.toLowerCase().replace(/[^a-z0-9]+/g, '-')}`}
                                   
                                  >{service.post_title}
                                  </NavLink>
                                </li>
                              ))}
                            </ul>
                            <ul className="nav-wellness">
                              <li><strong>Wellness</strong></li>
                              {wellnesslist?.map((service, index) => (
                                <li key={index}>
                                  <NavLink onClick={handleLinkClick}
                                    to={`${BASEURL}/services/${service.blog_category_name.toLowerCase()}/${service.post_title.toLowerCase().replace(/[^a-z0-9]+/g, '-')}`}
                                  
                                  >
                                    {service.post_title}
                                  </NavLink>
                                </li>
                              ))}
                            </ul>
                          </li>
                          <li className="drop-hover-img" id="imageContainer">
                            <Link to = '/online-booking' className='home-offer-content'>
                            {webInfo && parse(webInfo?.services_special_offer || '')}
                               {/* <h3>Save 25% on Select Services!*</h3>
                               <p>Save 25% on particular services with select providers when you book our special First Friday or Saturday appointments every month!</p> */}
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li><Link to={`${BASEURL}/gift-card`} onClick={handleLinkClick}> Gift Cards </Link></li> 
                    <li><Link to={`${BASEURL}/promotions`} onClick={handleLinkClick}>Promotions</Link></li>
                    <li className="mobile-navlink">
                      <Link to={`${BASEURL}/location`} onClick={handleLinkClick}>
                        <small>
                          <svg xmlns="http://www.w3.org/2000/svg" width="9.981" height="12.477" viewBox="0 0 9.981 12.477">
                            <path id="location_on_FILL1_wght400_GRAD0_opsz24" d="M164.991-873.762a1.2,1.2,0,0,0,.881-.367,1.2,1.2,0,0,0,.367-.881,1.2,1.2,0,0,0-.367-.881,1.2,1.2,0,0,0-.881-.367,1.2,1.2,0,0,0-.881.367,1.2,1.2,0,0,0-.367.881,1.2,1.2,0,0,0,.367.881A1.2,1.2,0,0,0,164.991-873.762Zm0,6.238a20.738,20.738,0,0,1-3.751-3.969,6.172,6.172,0,0,1-1.24-3.392,4.825,4.825,0,0,1,1.5-3.727A4.985,4.985,0,0,1,164.991-880a4.985,4.985,0,0,1,3.486,1.388,4.825,4.825,0,0,1,1.5,3.727,6.172,6.172,0,0,1-1.24,3.392A20.739,20.739,0,0,1,164.991-867.523Z" transform="translate(-160 880)" fill="#4e714a"/>
                          </svg>
                        </small> 
                        Find a Location
                      </Link>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
            <div className="head-login">
              <ul>
                <li className="login-link">
                  <Link to={`${BASEURL}/location`} onClick={handleLinkClick}>
                    <small>
                      <svg xmlns="http://www.w3.org/2000/svg" width="9.981" height="12.477" viewBox="0 0 9.981 12.477">
                        <path id="location_on_FILL1_wght400_GRAD0_opsz24" d="M164.991-873.762a1.2,1.2,0,0,0,.881-.367,1.2,1.2,0,0,0,.367-.881,1.2,1.2,0,0,0-.367-.881,1.2,1.2,0,0,0-.881-.367,1.2,1.2,0,0,0-.881.367,1.2,1.2,0,0,0-.367.881,1.2,1.2,0,0,0,.367.881A1.2,1.2,0,0,0,164.991-873.762Zm0,6.238a20.738,20.738,0,0,1-3.751-3.969,6.172,6.172,0,0,1-1.24-3.392,4.825,4.825,0,0,1,1.5-3.727A4.985,4.985,0,0,1,164.991-880a4.985,4.985,0,0,1,3.486,1.388,4.825,4.825,0,0,1,1.5,3.727,6.172,6.172,0,0,1-1.24,3.392A20.739,20.739,0,0,1,164.991-867.523Z" transform="translate(-160 880)" fill="#4e714a"/>
                      </svg>
                    </small> 
                    Find a Location
                  </Link>
                </li>
                <li>
                  <button className="btn btn-primary book-btn" onClick={openModal}>Book Now</button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {modal && (
        <div id="StaffModal" className={`modal-staff contact_modal fade-in ${modal ? "show" : ""}`}>
          <div className="modal-staff-dialog" ref={popupRef}>
            <div className="modal-staff-content">
              <button type="button" className="close" onClick={closeModal}>
                <span>
                  <img src={`${BASEURL}/assets/images/close.svg`} width="16.35" height="16.35" alt="close icon" />
                </span>
              </button>
              <div className="modal-staff-body">
                <div className="check-your-inbox-gird">
                  <div className="check-your-icon">
                    <img src={`${BASEURL}/assets/images/calendar.svg`} width="34.06" height="27.25" alt="calendar icon" />
                  </div>
                  <h4>Book an Appointment</h4>
                  <p>
                    We can’t wait to see you! If you are looking for an appointment asap, explore our available <br/> 
                    <Link className="popup_link" to="https://saloncloudsplus.com/getData/last_min_appts_frame/25800/1917122324/" target="_blank">last-minute openings.</Link>
                  </p>
                  <Link className="btn btn-primary" to={`${BASEURL}/online-booking`} onClick={() => setModal(false)}>Book Now</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default React.memo(Header);