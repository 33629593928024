import React, {useEffect, useState, useRef} from 'react';
import "../locations/location.css";
import { Link, useLocation } from "react-router-dom";
import locations from '../locations/locations.json'; 
import FixedFooter from '../../components/FixedFooter';
import TopHeader from '../../components/TopHeader';
import useFetch from '../../fetch/useFetch';
import ContactFormMain from '../../components/ContactFormMain';
import { formatPhoneNumber } from '../../utils/formatPhoneNumber';
import { convertAndDownload } from '../../utils/convertAndDownload';
import { Helmet } from "react-helmet-async";

import PageNotFound from '../PageNotFound/PageNotFound';


const BASEURL = process.env.REACT_APP_SQUAREONE_BASE_URL;
const SALONAPI = process.env.REACT_APP_SQUAREONE_API_EXTERNAL;

export default function LocationContact() {

  const location = useLocation();
  // const path = location.pathname.split('/').pop();
  const pathSegments = location.pathname.split('/').filter(Boolean);
  const path = pathSegments[pathSegments.length - 1]; // Get the last non-empty segment
  
  let locationDetailsDayton = locations && locations.filter(location => location.urlformat === path);


  const popupRef = useRef(null);

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setMapShowModal(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  const {data : salonInfo , loading : contactLoading}= useFetch(`${SALONAPI}/salon_info/${locationDetailsDayton[0].salonId}`);
  const { data: gallery  } = useFetch(`${SALONAPI}/SingleGallery/${locationDetailsDayton[0].salonId}/${locationDetailsDayton[0].galleryModuleId}`);
 

   let phone = salonInfo && (salonInfo.salon_contact_no || '') ;
   let hoursList =salonInfo && (salonInfo.salon_hours.split('\n') || '')
  
   let hoursMain = hoursList && hoursList.map((hour , index) => <li key={index}>{hour}</li>)
  

  const [showModal, setShowModal] = useState(false);
  const [mapShowModal, setMapShowModal] = useState(false);



  useEffect(() => {
    if (showModal) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [showModal]);

 

  const closeModal = () => {
    setShowModal(false);
  };


  useEffect(() => {
    if (mapShowModal) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [mapShowModal]);

  const openMapModal = () => {
    setMapShowModal(true);
    setMapShowModal((prev) => ({ ...prev, fadeIn: true }));
  };

  const closeMapModal = () => {
    setMapShowModal(false);
  };





if (contactLoading) {
  return <><div className='shimmer blog-sortbywrapper bg-transparent' style={{minHeight:'48px',width:'100%',height:'48px', display:'block'}}></div><div className='container'>
    <div className='locationcontactmap-sec'>
      <div className='row'>
        <div className='col-lg-8 col-md-7 col-sm-7 col-12' >
          <div className='shimmer' style={{minHeight:'400px',height:'400px', width:'100%',display:'block'}}></div>
        </div>
        <div className='col-lg-4 col-sm-5 col-md-5 col-12'> <div className='shimmer' style={{minHeight:'400px',height:'400px', width:'100%',display:'block'}}></div></div>
      </div>
    </div>
  </div>
  </>; 
}

let addressLocation = salonInfo?.salon_address && (salonInfo?.salon_address.toLowerCase() || '')

let placeHolderImg =  `${BASEURL}/assets/images/contactmap-img.jpg`

if (!locationDetailsDayton || locationDetailsDayton.length === 0) {
  return <PageNotFound/>;
}


  return (
   <>

       <Helmet>
       <link rel="canonical" href={locationDetailsDayton[0]?.curl_contact} />
        <title> {locationDetailsDayton[0]?.metaTitle_contact || 'Aveda Hair & Spa Salon Ohio | Beauty & Wellness - Square One'} </title>
        <meta name="description" content={locationDetailsDayton[0]?.metaDes_contact || 'Experience Best Aveda hair & spa services at Square One Salon in Ohio. Offering expert beauty & wellness treatments in Dayton & Columbus Ohio. Book now!'} />
       </Helmet>

    <TopHeader  locationDetailsDayton = {locationDetailsDayton[0]}  BASEURL = {BASEURL}/>


      <div className='container'>
        <div className='locationcontactmap-sec'>
          <div className='row'>
           <div className='col-lg-8 col-md-7 col-sm-7 col-12'>
           <div className='locationcontactmap-img'>
           
            <iframe 
            src={locationDetailsDayton[0].locationIframe} 
            width="752" 
            height="489" 
            
            allowFullScreen 
            loading="lazy" 
            title = "map"
            referrerPolicy="no-referrer-when-downgrade" style={{border:'0',borderRadius:'10px'}}>
        </iframe>

            </div> 
           </div> 
           <div className='col-lg-4 col-sm-5 col-md-5 col-12'>
           <div className='locationcontactmap-right'>
           <div className='locationcontactmap-cont'>
            {
              gallery ? <img src={gallery[0]?.gallery_image || placeHolderImg} width="336" height="160" alt='img' /> : ''
            }
            
 

           <h3>{salonInfo && salonInfo?.salon_name?.split('-').pop()} <span onClick={openMapModal}>

             {
              salonInfo && salonInfo?.salon_id === "220" ? ( <img
                src={`${BASEURL}/assets/images/info.svg`}
                width="14"
                height="14"
                alt="info"
              />) : null
             }
             </span></h3>
           
           
           <p><Link to = {locationDetailsDayton[0]?.locationLink} target='_blank' className='text-cap'>

           {
              salonInfo && salonInfo.salon_id === "218" ? (

                `${salonInfo && addressLocation}, ${salonInfo.salon_city.toLowerCase()}, ${salonInfo && salonInfo.salon_state} ${salonInfo && salonInfo.salon_zip.toLowerCase()}`

              ) : salonInfo && salonInfo.salon_id === "219" ? (

                `${salonInfo && addressLocation}, ${salonInfo.salon_city.toLowerCase()}, ${salonInfo && salonInfo.salon_state} ${salonInfo && salonInfo.salon_zip.toLowerCase()}`

              ) : salonInfo && salonInfo.salon_id === "220" ? (
               
                 `${salonInfo && addressLocation}, ${salonInfo.salon_city.toLowerCase()}, ${salonInfo && salonInfo.salon_state} ${salonInfo && salonInfo.salon_zip.toLowerCase()}`

              ) : salonInfo && salonInfo.salon_id === "235" ? (

                 `${salonInfo && addressLocation}, ${salonInfo.salon_city.toLowerCase()}, ${salonInfo && salonInfo.salon_state} ${salonInfo && salonInfo.salon_zip.toLowerCase()}`

              ) : salonInfo && salonInfo.salon_id === "221" ? (

                 `${salonInfo && addressLocation}, ${salonInfo.salon_city.toLowerCase()}, ${salonInfo && salonInfo.salon_state} ${salonInfo && salonInfo.salon_zip.toLowerCase()}`


              ) :  salonInfo && salonInfo.salon_id === "222" ? ( 

                 `${salonInfo && addressLocation}, ${salonInfo.salon_city.toLowerCase()}, ${salonInfo && salonInfo.salon_state} ${salonInfo && salonInfo.salon_zip.toLowerCase()}`

              ) : ''

            }

           
           {/* {salonInfo && addressLocation[1]}<br/>{salonInfo && salonInfo.salon_city.toLowerCase()}, {salonInfo && salonInfo.salon_state}, {salonInfo && salonInfo.salon_zip.toLowerCase()} */}
           
           </Link></p>

           <h4><Link className='link-clr' to = {`tel:${phone}`}>{formatPhoneNumber && formatPhoneNumber(phone)}</Link></h4>
           <p><Link to = {`mailto:${salonInfo?.salon_email_id && (salonInfo?.salon_email_id || '')}`}>{salonInfo && salonInfo.salon_email_id}</Link></p>
           <ul>
             { hoursMain ? (hoursMain) : (<p>Salon Hours Will be Updated Soon</p>) }
           </ul>
           </div> 
           </div>
            </div> 
          </div>
        </div>
      </div>


      <div className="container">
        <div className="contactWrapper">
          <h3>If you need us, we are here for you</h3>
          <p>
          Please use the form below to submit any customer service inquiries for Square One - {locationDetailsDayton && locationDetailsDayton[0].locationName}.
          </p>
          <ContactFormMain locationDetailsDayton = {locationDetailsDayton[0]}/>
        </div>
      </div>

      {salonInfo &&  <FixedFooter  locationDetailsDayton = {locationDetailsDayton[0]} salonInfo = {salonInfo}/>}
     



      {showModal && (
        <div
          
          className={`modal-staff contact_modal ${showModal.fadeIn ? "show" : ""}`}
        >
          <div className="modal-staff-dialog">
            <div className="modal-staff-content">
              <button type="button" className="close" onClick={closeModal}>
                <span>
                  <img
                    src={`${BASEURL}/assets/images/close.svg`}
                    width="16.35"
                    height="16.35"
                    alt="close icon"
                  />
                </span>
              </button>
              <div className="modal-staff-body">
                <div className="check-your-inbox-gird">
                  <div className="check-your-icon">
                    <img
                      src={`${BASEURL}/assets/images/mail.svg`}
                      width="34.06"
                      height="27.25"
                      alt="mail icon"
                    />
                  </div>
                  <h4>Your submission has been received!</h4>
                  <p>
                  Thank you for your interest in Square One Salon<br></br> and Spa. We will get back to you as soon as<br></br> possible!
                  </p>
                  <Link to="" className="btn btn-primary">
                    Okay
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      
 {mapShowModal && (
        <div
          id="StaffModal" 
          className={`modal-staff ${mapShowModal.fadeIn ? "show" : ""}`}
        >
          <div className="modal-staff-dialog" ref={popupRef}>
            <div className="modal-staff-content" >
              <button type="button" className="close" onClick={closeMapModal}>
                <span>
                  <img
                    src={`${BASEURL}/assets/images/close.svg`}
                    width="16.35"
                    height="16.35"
                    alt="close icon"
                  />
                </span>
              </button>
              <div className="modal-staff-body">
                <div className="promotion-modal-gird">
                  <div className="promotion-card">
                    <div className="promotion-img obj-contain">
                      <img
                        src={`${BASEURL}/assets/images/Centerville_Parking.png`}
                        width="162"
                        height="162"
                        alt="Centerville_Parking"
                      />
                    </div>
                    <h3>Parking Instructions</h3>
                    <p className="mh-auto">
                    Parking for the Uptown Centerville Business District. We know parking may be difficult around the Centerville area and that’s why we are here to help. Take a brief look at the map above to get a glimpse of where you may be able to park when visiting this location. 
                    </p>
                  </div>
                  <div className="promotion-download">
                    {
                      <button onClick={() => convertAndDownload(`${SALONAPI}/assets/images/Centerville_Parking.png`, 'parking', `${path}-parking`)}  className="btn btn-link" download="download">
                      Download
                    </button>
                    }
                    
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
   </>
  )
}
