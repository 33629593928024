import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import locations from '../../pages/locations/locations.json'; 
const BASEURL = process.env.REACT_APP_SQUAREONE_BASE_URL;

export default function Footer() {

  const location = useLocation();
  let path = location.pathname.split('/').pop();
  let locationDetailsDayton = locations && locations.filter(location => location.urlformat === path);

  const handleLinkClick = (event) => {
    event.preventDefault(); // Prevent React Router's navigation
    window.location.href = event.currentTarget.href; // Trigger full reload
  };


  return (
    <>
    <footer >
        <div className="footer-top">
          <div className="container">
              <div className="row">
                  <div className="col-12 col-md-9">
                    <div className="row">
                      <div className="col-12 col-md-3">
                          <div className="footer-nav footer-loclist">
                            <h3 className='footer-heading'>Locations</h3>
                            <ul>
                              <li><Link to={`${BASEURL}/location/dayton`} onClick={handleLinkClick}>Dayton </Link></li>
                              <li><Link to={`${BASEURL}/location/brown-st`} onClick={handleLinkClick}>Brown St </Link></li>
                              <li><Link to={`${BASEURL}/location/centerville`} onClick={handleLinkClick}>Centerville </Link></li>
                              <li><Link to={`${BASEURL}/location/oak-creek`} onClick={handleLinkClick}>Oak Creek </Link></li>
                              <li><Link to={`${BASEURL}/location/columbus`} onClick={handleLinkClick}>Columbus </Link></li>
                              <li><Link to={`${BASEURL}/location/new-albany`} onClick={handleLinkClick}>New Albany </Link></li>
                            </ul>
                          </div>
                      </div>
                      <div className="col-12 col-md-2">
                          <div className="footer-nav">
                            <h3 className='footer-heading'>Customers</h3>
                            <ul>
                              <li><Link to={`${BASEURL}/services`} onClick={handleLinkClick}>Services </Link></li>
                               {
                                locationDetailsDayton && locationDetailsDayton[0] ? (<li><Link to={locationDetailsDayton && locationDetailsDayton[0].shopNowUrl} target='_blank'>Shop Aveda </Link></li>):(<li><Link to='https://aveda.me/squareone-dayton' target='_blank'>Shop Aveda </Link></li>)
                                }

  
                              
                              <li><Link to={`${BASEURL}/gift-card`} onClick={handleLinkClick}>Gift Cards </Link></li>
                              <li><Link to={`${BASEURL}/online-booking`} onClick={handleLinkClick}>Book Now</Link></li>

                              {/* {
                                locationDetailsDayton && locationDetailsDayton[0] ? (<li><Link to={locationDetailsDayton && locationDetailsDayton[0].onlineBooking} target='_blank'>Book Now </Link></li>):(<li><Link to='https://book.salonbiz.com/widget/#/welcome?comToken=c244t201402131049p6t52Uxb7k6z75S6eN3a6' target='_blank'>Book Now</Link></li>)
                                } */}

                            </ul>
                          </div>
                      </div>
                      <div className="col-12 col-md-2">
                          <div className="footer-nav">
                            <h3 className='footer-heading'>Company</h3>
                            <ul>
                              <li><Link to={`${BASEURL}/aboutus`} onClick={handleLinkClick}>About </Link></li>
                              <li><Link to={`${BASEURL}/donations`} onClick={handleLinkClick}>Donations </Link></li>
                              <li><Link to={`${BASEURL}/career`} onClick={handleLinkClick}>Careers </Link></li>
                              <li><Link to={`${BASEURL}/blog`} onClick={handleLinkClick}>Blog</Link></li>
                            </ul>
                          </div>
                      </div>
                      <div className="col-12 col-md-2">
                          <div className="footer-nav">
                            <h3 className='footer-heading'>Resources</h3>
                            <ul>
                              <li><Link to={`${BASEURL}/contactus`} onClick={handleLinkClick}>Contact </Link></li>
                              <li><Link to={`${BASEURL}/salon-policies`} onClick={handleLinkClick}>Policies </Link></li>
                              <li><Link to={`${BASEURL}/privacy-policy`} onClick={handleLinkClick}>Privacy </Link></li>
                              <li><Link to={`${BASEURL}/terms-and-condition`} onClick={handleLinkClick}>Terms</Link></li>
                            </ul>
                          </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-3 d-flex">
                      <div className="footer-social ms-0 ms-md-auto">
                          <h3 className='footer-heading'>Follow Us</h3>
                          <ul>
                            <li>
                              <Link to = 'https://www.instagram.com/squareonesalonandspa/' target='_blank'><img src={`${BASEURL}/assets/images/instagram.svg`} width='24' height='24' alt="instagram" /></Link>
                            </li>
                            <li>
                              <Link to  = 'https://www.facebook.com/sq1salonandspa/' target='_blank'><img src={`${BASEURL}/assets/images/facebook.svg`} width='24' height='24' alt="facebook" /></Link>
                            </li>
                          </ul>

                      </div>
                  </div>
              </div>
          </div>
        </div>
        <div className="footer-bottom">
            <div className="container">
                <p>© 2025 Square One Salon & Spa. All Rights Reserved. Developed by <Link onClick={handleLinkClick} to="https://saloncloudsplus.com/" target='_blank'> SalonCloudsPlus</Link></p>
            </div>
        </div>
    </footer>
       
    </>
  ) 
}
