import { useEffect, useState, useMemo, useCallback } from "react";
import { createBrowserRouter, Route, createRoutesFromElements, RouterProvider, useLocation } from "react-router-dom";
import RootLayout from './layouts/RootLayout.js';
import './css/bootstrap-grid.min.css';
import './css/style.css';

import About from './pages/about/About.js';
import Home from './pages/home/Home.js';
import Career from "./pages/career/Career.js";
import LavelSystem from "./pages/lavelsystem/LavelSystem.js";
import NewGuest from "./pages/lavelsystem/NewGuest.js";
import Blog from "./pages/blog/Blog.js";
import BlogDetails from "./pages/blog/BlogDetails.js";
import PrivacyPolicy from "./pages/lavelsystem/PrivacyPolicy.js";
import SalonPolicies from "./pages/lavelsystem/SalonPolicies.js";
import TermsAndConditions from "./pages/lavelsystem/TermsAndConditions.js";
import Promotion from "./pages/promotion/Promotion.js";
import Services from "./pages/services/Services.js";
import Contact from "./pages/contact/Contact.js";
import Donation from "./pages/donation/Donation.js";
import Location from "./pages/locations/Location.js";
import LocationTeam from "./pages/locations/LocationTeam.js";
import PageNotFound from "./pages/PageNotFound/PageNotFound.js";
import useFetch from "./fetch/useFetch.js";
import SiteContext from "./helpers/CreateContext.js";
import LocationPromotion from "./pages/locations/LocationPromotion.js";
import LocationReview from "./pages/locations/LocationReview.js";
import LocationOverview from "./pages/locations/LocationOverview.js";
import LocationContact from "./pages/locations/LocationContact.js";
import MainServices from "./pages/services/MainServices.jsx";
// import Holiday from "./pages/holiday/Holiday.jsx";
import './gtm.js';

import PageNotFoundServices from './pages/services/PageNotFoundServices.jsx';
import BookNow from "./pages/iframe/BookNow.js";
import GiftCard from "./pages/iframe/GiftCard.js";
import LocationOnlineBooking from "./pages/locations/LocationOnlineBooking.js";
import RedirectHandler from "./RedirectHandler.js";
import GiftPackage from "./pages/iframe/GiftPackage.js";
import Package from "./pages/iframe/Package.js";
import PrePostCareFunction from "./pages/lavelsystem/PrePostCareFunction.js";


const BASEURL = process.env.REACT_APP_SQUAREONE_BASE_URL;
const SALONAPI = process.env.REACT_APP_SQUAREONE_API_EXTERNAL;

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path={`${BASEURL}/`} element={<><RootLayout />  <RedirectHandler /> </>}>
      <Route path={`${BASEURL}/`} element={<Home />} />
      <Route path={`${BASEURL}/aboutus`} element={<About />} />
      <Route path={`${BASEURL}/career`} element={<Career />} />
      <Route path={`${BASEURL}/online-booking`} element={<BookNow />} />
      <Route path={`${BASEURL}/gift-card`} element={<GiftCard />} />
      <Route path={`${BASEURL}/gift-package`} element={<GiftPackage />} />
      <Route path={`${BASEURL}/packages/:linkId`} element={<Package />} />
      <Route path={`${BASEURL}/pre-post-care-information`} element={<PrePostCareFunction />} />
      <Route path={`${BASEURL}/online-booking/:name`} element={<LocationOnlineBooking />} />
      <Route path={`${BASEURL}/new-guest`} element={<NewGuest />} />
      <Route path={`${BASEURL}/blog`} element={<Blog />} />
      <Route path={`${BASEURL}/blogdetails/:name`} element={<BlogDetails />} />
      <Route path={`${BASEURL}/privacy-policy`} element={<PrivacyPolicy />} />
      <Route path={`${BASEURL}/salon-policies`} element={<SalonPolicies />} />
      <Route path={`${BASEURL}/terms-and-condition`} element={<TermsAndConditions />} />
      <Route path={`${BASEURL}/promotions`} element={<Promotion />} />
      <Route path={`${BASEURL}/services`} element={<Services />} />
      <Route path={`${BASEURL}/services/:category/:title`} element={<MainServices />} />
      <Route path={`${BASEURL}/service-salon`} element={<RedirectWithHash />} />
      <Route path={`${BASEURL}/contactus`} element={<Contact />} />
      <Route path={`${BASEURL}/location`} element={<Location />} />
      <Route path={`${BASEURL}/team/:name`} element={<LocationTeam />} />
      <Route path={`${BASEURL}/donations`} element={<Donation />} />
      <Route path={`${BASEURL}/promotions/:name`} element={<LocationPromotion />} />
      <Route path={`${BASEURL}/reviews/:name`} element={<LocationReview />} />
      <Route path={`${BASEURL}/location/:name`} element={<LocationOverview />} />
      <Route path={`${BASEURL}/contact/:name`} element={<LocationContact />} />
      <Route path={`${BASEURL}/level-system`} element={<LavelSystem />} />
      {/* <Route path={`${BASEURL}/holiday`} element={<Holiday />} /> */}
      <Route path={`${BASEURL}/*`} element={<PageNotFound />} />
      <Route path={`${BASEURL}/not-found`} element={<PageNotFoundServices />} />
    </Route>
  )
);

function RedirectWithHash() {
  const location = useLocation();

  useEffect(() => {
    if (location.hash === '#facial') {
      window.location.replace(`${BASEURL}/services/wellness/facials`);
    } else if (location.hash === '#nails') {
      window.location.replace(`${BASEURL}/services/wellness/nails`);
    } else if (location.hash === '#waxing') {
      window.location.replace(`${BASEURL}/services/wellness/waxing`);
    } else if (location.hash === '#makeup') {
      window.location.replace(`${BASEURL}/services/wellness/makeup`);
    } else if (location.hash === '#lash-and-brow') {
      window.location.replace(`${BASEURL}/services/wellness/lash-brow-enhancements`);
    } else if (location.hash === '#massage') {
      window.location.replace(`${BASEURL}/services/wellness/massage`);
    } else if (location.hash === '#salon') {
      window.location.replace(`${BASEURL}/services/salon/hair-color`);
    } else if (location.hash === '#series-of-services' || location.hash === '#spa') {
      window.location.replace(`${BASEURL}/services`);
    } else {
      window.location.replace(`${BASEURL}/services`);
    }
  }, [location]);

  return null;
}

function App() {
  const [salonData, setSalonData] = useState();
  const [salonServices, setSalonServices] = useState();
  const [wellnessServices, setWellnessServices] = useState();
  const [facialServices, setFacialServices] = useState();

  const { data: salonInfo, loading: salonInfoPending, serverError: salonDataError } = useFetch(`${SALONAPI}/homewebsettings`);
  const { data: salonServicesData, loading: servicePending } = useFetch(`${SALONAPI}/salonServicesData`);
  const { data: wellnessServicesData, loading: wellnessPending } = useFetch(`${SALONAPI}/wellnessServicesData`);
  const { data: facialServicesData, loading: facialPending } = useFetch(`${SALONAPI}/serviceFacialData`);

  const memoizedSalonData = useMemo(() => salonInfo, [salonInfo]);
  const memoizedSalonServices = useMemo(() => salonServicesData, [salonServicesData]);
  const memoizedWellnessServices = useMemo(() => wellnessServicesData, [wellnessServicesData]);
  const memoizedFacialServices = useMemo(() => facialServicesData, [facialServicesData]);


  useEffect(() => {
    setSalonData(memoizedSalonData);
    setSalonServices(memoizedSalonServices);
    setWellnessServices(memoizedWellnessServices);
    setFacialServices(memoizedFacialServices);
  }, [memoizedSalonData, memoizedSalonServices, memoizedWellnessServices, memoizedFacialServices]);

  return (
    <SiteContext.Provider value={{ salonData, salonDataError, salonInfoPending, servicePending, salonServices, wellnessPending, wellnessServices, facialServices, facialPending }}>
      <RouterProvider router={router} />
    </SiteContext.Provider>
  );
}

export default App;

