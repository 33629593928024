import React, { useState, useRef, useEffect } from "react";
import "../locations/location.css";
import locations from '../locations/locations.json';
import { Link, useLocation } from "react-router-dom";
import useFetch from "../../fetch/useFetch";
import TopHeader from "../../components/TopHeader";
import FixedFooter from "../../components/FixedFooter";
import parse from 'html-react-parser';
import { truncateDescription } from "../../utils/truncateDescription";
import PageNotFound from "../PageNotFound/PageNotFound";
import { Helmet } from "react-helmet-async";
const BASEURL = process.env.REACT_APP_SQUAREONE_BASE_URL;
const SALONAPI = process.env.REACT_APP_SQUAREONE_API_EXTERNAL;
const alternativeImg = process.env.REACT_APP_ALTERNATIVE_IMG;

export default function LocationTeam() {

  const popupRef = useRef(null);

  const location = useLocation();
  const pathSegments = location.pathname.split('/').filter(Boolean);
  const path = pathSegments[pathSegments.length - 1]; // Get the last non-empty segment
  
  let locationDetailsDayton = locations && locations.filter(location => location.urlformat === path);


 
  const {data : salonInfo }= useFetch(`${SALONAPI}/salon_info/${locationDetailsDayton[0]?.salonId}`);
  const { data: team , loading : teamLoading } = useFetch(`${SALONAPI}/teaminfo/${locationDetailsDayton[0]?.salonId}`);

 
  const [showModal, setShowModal] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState(null);
  

  let allStaff = team && team?.staff && team?.staff.filter((manager) => {
    let title = manager?.owner_employee;
    return !(title === "owner");
  });
 


  let ManagerStaffFilter = team && team?.staff && team?.staff.filter((manager) => {
    let title = manager?.owner_employee;
    return (title === "owner");
  });
  const handleLinkClick = (event) => {
    event.preventDefault(); // Prevent React Router's navigation
    window.location.href = event.currentTarget.href; // Trigger full reload
  };


  const openModal = async (staffId) => {
    try {
      const response = await fetch(`${SALONAPI}/teamDetails/${staffId}`);
      if (!response.ok) {
        throw new Error('Failed to fetch staff details');
      }
      const staffDetail = await response.json();
      setShowModal(true);
      setSelectedStaff(staffDetail);
    } catch (error) {
      console.error("Error fetching staff details:", error.message);
    }
  };

  



  const closeModal = () => {
    setShowModal(false);
    setSelectedStaff(null);
  };

  const handleClickOutside = (event) => {
  
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setShowModal(false);
    }
  };

  useEffect(() => {
     document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  





  if (teamLoading) {
    return <><div className='shimmer blog-sortbywrapper bg-transparent' style={{minHeight:'48px',width:'100%',height:'48px'}}></div><div className="shimmer-main location_wrapper" style={{display:'flex',flexWrap:'wrap', gap:'16px'}}><div className="shimmer location_gird bg-transparent " style={{minHeight:'360px',height:'360px'}}></div> <div className="shimmer location_gird bg-transparent " style={{minHeight:'360px',height:'360px'}}></div><div className="shimmer location_gird bg-transparent " style={{minHeight:'360px',height:'360px'}}></div><div className="shimmer location_gird bg-transparent " style={{minHeight:'360px',height:'360px'}}></div> <div className="shimmer location_gird bg-transparent " style={{minHeight:'360px',height:'360px'}}></div>  <div className="shimmer location_gird bg-transparent " style={{minHeight:'360px',height:'360px'}}></div> <div className="shimmer location_gird bg-transparent " style={{minHeight:'360px',height:'360px'}}></div><div className="shimmer location_gird bg-transparent " style={{minHeight:'360px',height:'360px'}}></div></div></>; 
  }

  if (!locationDetailsDayton || locationDetailsDayton.length === 0) {
    return <PageNotFound/>;
  }

  return (
    <>
     <Helmet>
        <link rel="canonical" href={locationDetailsDayton[0]?.curl_team} />
        <title> {locationDetailsDayton[0]?.metaTitle_team || 'Aveda Hair & Spa Salon Ohio | Beauty & Wellness - Square One'} </title>
        <meta name="description" content={locationDetailsDayton[0]?.metaDes_team || 'Experience Best Aveda hair & spa services at Square One Salon in Ohio. Offering expert beauty & wellness treatments in Dayton & Columbus Ohio. Book now!'} />
       </Helmet>
      <TopHeader locationDetailsDayton={locationDetailsDayton[0]} BASEURL={BASEURL} />

      <div className="locationpromotion_bg">
        <div className="container">
          <div className="location_wrapper location_team_wrapper">
            <div className="location-heaing">
              <h2>Browse our Members</h2>
              <p>Meet our members and get to know them a bit more!</p>
            </div>
            <div className="location-column-sec team-info-title">
              <h3>Team Members</h3>
              <div className="row">
                {
                  team && allStaff.map(({ staff_id, name, designation, image, description, square_one_staff_ob_url }) => (
                    <div className="col-lg-3 col-md-4 col-sm-6 col-12" key={staff_id}>
                      <div className="location_gird" >
                        <div className="team-bth-padd"  onClick={() => openModal(staff_id)}>
                          <div className="location_img">
                            {image && (
                              <img
                                src={image || alternativeImg}
                                width="240"
                                height="240"
                                alt={name}
                              />
                            ) }
                          </div>
                          <div className="location_content">
                            <h4>{truncateDescription(name, 18)}
                              <span>
                                <img
                                  src={`${BASEURL}/assets/images/info.svg`}
                                  width="14"
                                  height="14"
                                  alt="info"
                                />
                              </span>
                            </h4>
                            <p>{designation}</p>
                          </div>
                        </div>
                        <div className="promotion-download">
                        <Link onClick={handleLinkClick} to = { square_one_staff_ob_url ? square_one_staff_ob_url : 'https://book.salonbiz.com/widget/#/welcome?comToken=c244t201402131049p6t52Uxb7k6z75S6eN3a6'} target="_blank" className="btn btn-link">Book Now</Link>
                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
              
            {
              ManagerStaffFilter && ManagerStaffFilter.length > 0 && ( 
              
              <div className="location-column-sec team-info-title">
                <h3>Leadership/Management</h3>
               
                <div className="row">
                  {
                    team && ManagerStaffFilter.map(({ staff_id, name, designation, image, square_one_staff_ob_url }) => (
                      <div className="col-lg-3 col-md-4 col-sm-6 col-12" key={staff_id}>
                        <div className="location_gird" >
                          <div className="team-bth-padd"  onClick={() => openModal(staff_id)}>
                            <div className="location_img">
                              {image && (
                                <img
                                  src={image || alternativeImg}
                                  width="240"
                                  height="240"
                                  alt={name}
                                />
                              )
                              }
                            </div>
                            <div className="location_content">
                              <h4>{truncateDescription(name, 18)}
                                <span>
                                  <img
                                    src={`${BASEURL}/assets/images/info.svg`}
                                    width="14"
                                    height="14"
                                    alt="info"
                                  />
                                </span>
                              </h4>
                              <p>{designation}</p>
                            </div>
                          </div>
                          <div className="promotion-download">
                            <Link to = { square_one_staff_ob_url ? square_one_staff_ob_url : 'https://book.salonbiz.com/widget/#/welcome?comToken=c244t201402131049p6t52Uxb7k6z75S6eN3a6'} target="_blank" className="btn btn-link">Book Now</Link>
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>)
            }
           

          </div>
        </div>
      </div>

      {salonInfo &&  <FixedFooter  locationDetailsDayton = {locationDetailsDayton[0]} salonInfo = {salonInfo} />}
     

      {selectedStaff && showModal && (
        <div
          id="StaffModal"
          className={`modal-staff ${showModal ? "show" : ""}`} 
        >
          <div className="modal-staff-dialog" ref= {popupRef}>
            <div className="modal-staff-content">
              <button type="button" className="close" onClick={closeModal}>
                <span>
                  <img
                    src={`${BASEURL}/assets/images/close.svg`}
                    width="16.35"
                    height="16.35"
                    alt="close icon"
                  />
                </span>
              </button>
              <div className="modal-staff-body">
                <div className="locationteam_modal">
                  <h3>{selectedStaff && selectedStaff?.staff?.name}
                    
                    <span>
               
                    <Link to = {selectedStaff && selectedStaff?.staff?.portfolio_url ? selectedStaff?.staff?.portfolio_url : selectedStaff?.social?.instagram} target="_blank"> 

                    <img src={`${BASEURL}/assets/images/instagram.svg`} width='14' height='14' alt="instagram icon" /></Link>
                    
                    </span>

                    </h3>
                  <div className="locationteam_paragh">
                    <p>{parse(selectedStaff && selectedStaff?.staff?.description)}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
