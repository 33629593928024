import React, { useState, useEffect, useContext, useRef } from 'react';
import "../services/services.css";
import SiteContext from '../../helpers/CreateContext';
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { Link as ScrollLink, Element } from 'react-scroll';
import parse from 'html-react-parser'

import { replaceAnchorTags } from '../../utils/replaceAnchorTags';
import ServiceDescription from './ServiceDescription';
import { Helmet } from 'react-helmet';


// import MetaTags from '../../MetaTags';
// import metaData from '../../seoMetaTags.json';

const BASEURL = process.env.REACT_APP_SQUAREONE_BASE_URL;
const SALONAPI = process.env.REACT_APP_SQUAREONE_API_EXTERNAL;

export default function MainServices() {


  const [loading, setLoading] = useState(true);
  const { salonData } = useContext(SiteContext); 
  const listRef = useRef(null);
  const { category, title } = useParams();
  const [combinedData, setCombinedData] = useState([]);
  const [data, setData] = useState([]);
  const [menudata, setMenudata] = useState([]);
  const [selectedService, setSelectedService] = useState([]);

  const navigate = useNavigate();

  const scrollLeft = () => {
    if (listRef.current) {
      listRef.current.scrollBy({ left: -100, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (listRef.current) {
      listRef.current.scrollBy({ left: 100, behavior: 'smooth' });
    }
  };

  const handleMouseDown = (e) => {
    if (listRef.current) {
      listRef.current.isDragging = true;
      listRef.current.startX = e.pageX - listRef.current.offsetLeft;
    }
  };

  const handleMouseMove = (e) => {
    if (listRef.current && listRef.current.isDragging) {
      const x = e.pageX - listRef.current.offsetLeft;
      const walk = (x - listRef.current.startX) * 2; //scroll-fast
      listRef.current.scrollLeft -= walk;
    }
  };

  const handleMouseUp = () => {
    if (listRef.current) {
      listRef.current.isDragging = false;
    }
  };

  console.log('ouside useeffect')

  useEffect(() => {
    fetch(`${SALONAPI}/salonServicesData`)
      .then(response => response.json())
      .then(data => {
        setData(data);
        const filteredData = data.filter(service => 
          category.toLowerCase().replace(/[^a-z0-9]+/g, '-') === service.blog_category_name.toLowerCase().replace(/[^a-z0-9]+/g, '-') && 
          title.toLowerCase().replace(/[^a-z0-9]+/g, '-') === service.post_title.toLowerCase().replace(/[^a-z0-9]+/g, '-')
        );
        if (filteredData.length === 0) {
           navigate(`${BASEURL}/not-found`)
        } else {
          setSelectedService(filteredData);
        }
      })
      .catch(error => console.error('Error fetching data:', error));

      console.log('ouside useeffect', data)


  }, [category, title, navigate]); 

  useEffect(() => {
    if (selectedService.length > 0) {
      setLoading(true); // Set loading to true before fetch starts servicesSubMenuData
      fetch(`${SALONAPI}/servicesSubMenuData/${selectedService[0].author}`)
        .then(response => response.json())
        .then(data => {
          setMenudata(data);
          setLoading(false); // Set loading to false after data is fetched
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          setLoading(false); // Set loading to false in case of error
        });
    }
  }, [selectedService]);

  useEffect(() => {
    if (menudata?.length > 0 && selectedService?.length > 0) {
      const fetchSubmenus = async () => {
        setLoading(true);
  
        try {
          const response = await fetch(
            `${SALONAPI}/fetch-submenus?menudata=${encodeURIComponent(JSON.stringify(menudata))}&selectedService=${encodeURIComponent(JSON.stringify(selectedService))}`
          );
  
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
  
          const allData = await response.json();
          setCombinedData(allData);
        } catch (error) {
          console.error('Error fetching submenus:', error);
        } finally {
          setLoading(false);
        }
      };
  
      fetchSubmenus();
    }
  }, [menudata , selectedService]);

  const isLengthThree = menudata?.length < 3;

  let metaServices = [

 
    {
      "id": 1,
      "url": "/services/salon/hair-color",
      "title": "Aveda Hair Color Services Ohio | Square One Salon & Spa-OH",
      "description": "Aveda's vegan full-spectrum hair color at Square One Hair Salon Ohio. Offering Permanent Color, Highlights, Balayage & more. Vibrant to natural shades. Book Now!",
      "curl" : "https://www.squareonesalon.com/services/salon/hair-color",
      
    },
    {
      "id": 2,
      "url": "/services/salon/texture-service",
      "title": "Hair Texture Services Dayton & Ohio: Keratin, Blow Out, Perms",
      "description": "Get hair texture services at Square One Salon Dayton & Ohio. We offer blowouts, keratin treatments, perms, relaxers & styling for wavy, curly & coily hair types.",
      "curl" : "https://www.squareonesalon.com/services/salon/texture-service",
    },
    {
      "id": 3,
      "url": "/services/salon/hair-extensions",
      "title": "VoMor® Hair Extensions - Application & Maintenance Dayton, Oh",
      "description": "Square One offers VoMor® hair extensions in Dayton Ohio, matched to Aveda color. Enjoy hand-tied or tape-in applications with full maintenance & removal options.",
      "curl" : "https://www.squareonesalon.com/services/salon/hair-extensions",
    },
    {
      "id": 4,
      "url": "/services/salon/cut-style",
      "title": "Best Haircuts & Styles Dayton & across Ohio| Square One Salon",
      "description": "Square One Salon & Spa offers expert haircuts for every hairstyle in Dayton & all across Ohio. Book blowouts, event styles, curly texture cuts, and more. ",
      "curl" : "https://www.squareonesalon.com/services/salon/cut-style",
    },
    {
      "id": 5,
      "url": "/services/salon/hair-treatments",
      "title": "Aveda Hair Treatments Ohio | Repair & Hydration | Square One",
      "description": "Add Aveda’s Botanical Repair, Nutriplenish Ultimate Hydration, or Scalp Solutions Hair Treatments to your service at Square One, available in Dayton & Ohio-wide.",
      "curl" : "https://www.squareonesalon.com/services/salon/hair-treatments",
    },
    {
      "id": 6,
      "url": "/services/wellness/makeup",
      "title": "Best Makeup Services - Dayton & Ohio | Square One Salon",
      "description": "Get your perfect look with our makeup artists, offering makeup application, airbrush makeup & Xtreme Lash services using Smashbox makeup at Square One, Dayton Ohio.",
      "curl" : "https://www.squareonesalon.com/services/wellness/makeup",
    },
    {
      "id": 7,
      "url": "/services/wellness/body-treatments",
      "title": "Thai-Inspired Body Treatments Dayton Ohio | Square One Salon",
      "description": "Eliminate stress with our Thai Cranial Herbal Poultice Head & Neck Recovery body treatment in Dayton & Ohio. Available at Square One Salon, Dayton & Ohio-wide.",
      "curl" : "https://www.squareonesalon.com/services/wellness/body-treatments",
    },
    {
      "id": 8,
      "url": "/services/wellness/waxing",
      "title": "Full-Body Waxing Services Dayton, Ohio | Square One Salon Spa",
      "description": "From lip-brow to Brazilian, our estheticians offer customized waxing services in Dayton & across Ohio for smooth results. Visit Square One Salon & Spa in Ohio.",
      "curl" : "https://www.squareonesalon.com/services/wellness/waxing",
    },
    {
      "id": 9,
      "url": "/services/wellness/lash-brow-enhancements",
      "title": "Lash & Brow Enhancements Dayton, Oh | Lash Lifts | Square One",
      "description": "Enhance your look with Lash & Brow Lifts, Tints & Laminations at Square One Salon & Spa. Book your lash & brow services in Dayton & Ohio-wide. Book today!",
      "curl" : "https://www.squareonesalon.com/services/wellness/lash-brow-enhancements",
    },
    {
      "id": 10,
      "url": "/services/wellness/massage",
      "title": "Massage & Wellness Services Dayton, Columbus, Ohio: Square One",
      "description": "Experience customized wellness massage in Dayton, Columbus, Centerville, Ohio. From light to deep tissue, our custom aromatherapy massage enhances relaxation.",
      "curl" : "https://www.squareonesalon.com/services/wellness/massage",
    },
    {
      "id": 11,
      "url": "/services/wellness/nails",
      "title": "Best Nail Salon Dayton Ohio | Mani-Pedi, Gel Nails, Nail Art",
      "description": "Custom nail services in Dayton Ohio. Enhance your nail experience with Aveda aromas enjoying acrylic nails, gel nails, mani-pedis, nail art & nail repair services.",
      "curl" : "https://www.squareonesalon.com/services/wellness/nails",
    },
    {
      "id": 12,
      "url": "/services/wellness/facials",
      "title": "Aveda Facial Treatments in Dayton, Ohio | Square One Salon",
      "description": "Achieve radiant skin with Aveda facials in Dayton, Oh, at Square One Salon. Enjoy customized treatments, including exfoliation facials, plant peels & dermaplaning.",
      "curl" : "https://www.squareonesalon.com/services/wellness/facials",
    }
  
    
  ]

  const locationServices = useLocation();
  
  const currentMeta = metaServices.find((meta) => meta.url === locationServices.pathname);


console.log(combinedData, 'combinedData')



  return (
    <>
          <Helmet>
          <link rel="canonical" href={currentMeta?.curl || 'https://www.squareonesalon.com/services'} />
                         <title>{currentMeta?.title || 'Top Haircuts, Color & Wellness Services in Ohio | Square One'}</title>
                         <meta name="description" content={currentMeta?.description || 'Square One Salon provides the best haircuts, color, facials, extensions, nails, and wellness services for men & women in Dayton & Columbus'} />
                        </Helmet>

      <div className="services-header">
        <div className="services-header-inner">
          <button type='button' className="slide-button left" onClick={scrollLeft}>
            <img src={`${BASEURL}/assets/images/arrow-left.svg`} width="4.65" height="8.157" alt="left arrow" />
          </button>
          <ul  className={isLengthThree ? 'length-class' : ''}
            ref={listRef} 
            onMouseDown={handleMouseDown}
            onMouseLeave={handleMouseUp}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}
          >

            { menudata.length > 0 && menudata?.map((service, index) => {
             return(
              <li key={index}>
              <ScrollLink to={`${service.menu_title.toLowerCase().replace(/[^a-z0-9]+/g, '-')}`} activeClass="active" spy={true} offset={-180} smooth={true} duration={500}>
                {service.menu_title}
              </ScrollLink>
            </li>
             )
           })}
          </ul>
          <button type='button' className="slide-button right" onClick={scrollRight}>
            <img src={`${BASEURL}/assets/images/arrow-left.svg`} width="4.65" height="8.157" alt="right arrow" style={{ transform: 'rotate(180deg)' }} />
          </button>
        </div>
      </div>
      <div className="services-inner-main facials-inner-main gray-bg">
        <div className="container">
          {
            loading ? (
              <div className='services-inner-main gray-bg pt-0 pb-0'>
                <div className='container'>
                  <div className='services-top-desc-cont'>
                    <div className='shimmer' style={{ minHeight: '40px', display: 'block', width: '400px', marginBottom: '16px' }}></div>
                    <div className='shimmer' style={{ minHeight: '20px', display: 'block', width: '100%', marginBottom: '10px' }}></div>
                    <div className='shimmer' style={{ minHeight: '20px', display: 'block', width: '95%', marginBottom: '10px' }}></div>
                    <div className='shimmer' style={{ minHeight: '20px', display: 'block', width: '90%', marginBottom: '10px' }}></div>
                    <div className='shimmer' style={{ minHeight: '20px', display: 'block', width: '50%', marginBottom: '10px' }}></div>
                    <br/><br/>
                    <div className='shimmer' style={{ minHeight: '20px', display: 'block', width: '100%', marginBottom: '10px' }}></div>
                    <div className='shimmer' style={{ minHeight: '20px', display: 'block', width: '95%', marginBottom: '10px' }}></div>
                    <div className='shimmer' style={{ minHeight: '20px', display: 'block', width: '90%', marginBottom: '10px' }}></div>
                    <div className='shimmer' style={{ minHeight: '20px', display: 'block', width: '50%', marginBottom: '10px' }}></div>
                  </div>
                </div>
              </div>
            ) : (
              <React.Fragment>
                {data && selectedService?.map((service) => (
                  <div className="services-top-desc-cont" key={service.post_id}>
                    <h2 className="heading_36">{service.post_title}</h2>
                    <div>{replaceAnchorTags(service.post_description)}</div>       
                  </div>
                ))}
              </React.Fragment>
            )
          }
          <div className="services-list-grid-cont">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-8">
                {loading ? (
                  <div className='services-list-grid-cont salon-wellenss-card'>
                    <div className='container'>
                      <div className='row'>
                        <div className='col-12 '>
                          <div className='shimmer' style={{ minHeight: '20px', display: 'block', width: '100%', marginBottom: '10px' }}></div>
                          <div className='shimmer' style={{ minHeight: '20px', display: 'block', width: '95%', marginBottom: '10px' }}></div>
                          <div className='shimmer' style={{ minHeight: '20px', display: 'block', width: '90%', marginBottom: '10px' }}></div>
                          <div className='shimmer' style={{ minHeight: '20px', display: 'block', width: '50%', marginBottom: '10px' }}></div>
                          <br/>
                          <br/>
                          <div className='shimmer' style={{ minHeight: '20px', display: 'block', width: '100%', marginBottom: '10px' }}></div>
                          <div className='shimmer' style={{ minHeight: '20px', display: 'block', width: '95%', marginBottom: '10px' }}></div>
                          <div className='shimmer' style={{ minHeight: '20px', display: 'block', width: '90%', marginBottom: '10px' }}></div>
                          <div className='shimmer' style={{ minHeight: '20px', display: 'block', width: '50%', marginBottom: '10px' }}></div>
                          <br/>
                          <br/>
                          <div className='shimmer' style={{ minHeight: '20px', display: 'block', width: '100%', marginBottom: '10px' }}></div>
                          <div className='shimmer' style={{ minHeight: '20px', display: 'block', width: '95%', marginBottom: '10px' }}></div>
                          <div className='shimmer' style={{ minHeight: '20px', display: 'block', width: '90%', marginBottom: '10px' }}></div>
                          <div className='shimmer' style={{ minHeight: '20px', display: 'block', width: '50%', marginBottom: '10px' }}></div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
              
                      
                    {combinedData && combinedData.map((service, index) => {
                     
                    
                     return(
                        <div key={index}>
                             
                        <Element className="salon-wellenss-card" id={`${service.menu_title.toLowerCase().replace(/[^a-z0-9]+/g, '-')}`}>

                       
                          <div className="salon-wellness-top">
                         <h3>{service.menu_title}</h3>
                         
                         
                         <ServiceDescription 
  description={
    service && service.menu_description
      ? service.menu_description.replace(
          /(pre and post care instructions)/gi,
          `<a href="${BASEURL}/pre-post-care-information">pre and post care instructions</a>`
        )
      : ""
  } 
  salonData={salonData} 
  BASEURL={BASEURL} 
/>


{/* const ServiceDescription = ({ description, salonData, BASEURL }) => {
  return (
    <div dangerouslySetInnerHTML={{ __html: description }} />
  );
}; */}



                            
                          </div>
                          {service && service.submenus.map((submenu) => {

                              let subMenuDescriptionSplitdata = submenu && (submenu.sub_menu_description.split('~') || '')
                             
                              // const prePostCareLink = "Pre and post-care instructions";

                              // const updatedText = subMenuDescriptionSplitdata[0].replace(
                              //   /Pre and post-care instructions/g,
                              //   `<a href="${prePostCareLink}" target="_blank" rel="noopener noreferrer">Pre and post-care instructions</a>`
                              // );

                              // console.log(updatedText, 'updatedText')

                             
                            
      
                            


                           return(
                              <div className="salon-prices-card" key={submenu.sub_menu_id}>
                                <div className="salon-price-flex">
                                  <div className="salon-service">
                                    {service && submenu.sub_menu_title}
                                  </div>
                                  <div className="salon-price">
                                    {service && submenu.sub_menu_price}
                                  </div>
                                </div>
                                {/* safaf
                               // <div dangerouslySetInnerHTML={{ __html: replaceWithLink(subMenuDescription) }} /> */}

                                <p>{subMenuDescriptionSplitdata[0]} <em>{subMenuDescriptionSplitdata[1]}</em>
                                
                              {submenu.sub_menu_externallink ? (
                                <Link className="btn btn-link"  to={`${BASEURL}/packages/${submenu.website_sub_menu_price}`}>
                                  Purchase
                                </Link>
                                  ) : null}
                                  </p>
                            </div>
                            )
                          }
                            
                          )}
                        </Element>
                      </div>
                      )
                    })}

                    {combinedData && combinedData.length === 0 ? <p className='text-danger'>Menu coming soon! Stay tuned.</p> : ''}
                  </>
                )}
              </div>
              <div className="col-12 col-sm-12 col-md-4">
                <div className="salon-before-appt-cont">
                  {salonData && parse(salonData.website_settings.new_services_before_appt && salonData.website_settings.new_services_before_appt)}
                  <Link to={`${BASEURL}/salon-policies`} className='btn btn-primary'>View Policies</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
