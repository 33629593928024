import React from 'react';
import '../PageNotFound/pageNotFound.css';
import { Link, NavLink } from "react-router-dom";
import useFetch from '../../fetch/useFetch';

const BASEURL = process.env.REACT_APP_SQUAREONE_BASE_URL;

export default function PageNotFoundServices() {


  const { data } = useFetch(`https://saloncloudsplus.com/wssalonbloginfo/blog_posts/415/25804`);

  const salonlist = data && data?.filter((item) => item.blog_category_name === "Salon");
  const wellnesslist = data && data?.filter((item) => item.blog_category_name === "Wellness");

  return (
    <>
      <section className='pagenotfound-main'>
        <div className="pagenotfound-grid">
          <h2>404 Error</h2>
          <div className="pagenot-icon py-4">
            <img
              src={`${BASEURL}/assets/images/storefront.svg`}
              className='img-fluid mx-auto'
              width="30"
              height="26"
              alt="home icon"
            />
          </div>
          <h3 className='heading_24'>Whoops, we can’t find this page.</h3>

          <div className="list-nav">
           
            <p className="footer-heading"  style={{fontSize:'20px',fontWeight:'600',padding:'20px 0 30px',color:'#97ad6e',fontFamily:'"Kalnia", serif'}}>Services We Provide</p>

            {/* <h3 className="footer-heading" style={{fontSize:'20px',fontWeight:'600',padding:'20px 0 0px'}}>Our Services List</h3> */}

            <div className='row'>
              <div className='col-lg-6 col-md-6 col-12' style={{textAlign:'start'}}>
                <ul>
                  <li className='page-heading'>Salon</li>
                  {data && salonlist.map((service, index) => (
                    <li key={index}>
                      <NavLink
                       
                        to={`${BASEURL}/services/${service.blog_category_name.toLowerCase()}/${service.post_title.toLowerCase().replace(/[^a-z0-9]+/g, '-')}`}
                       
                      >
                        {service.post_title}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </div>
              <div className='col-lg-6 col-md-6 col-12' style={{textAlign:'start'}}>
                <ul>
                  <li className='page-heading'>Wellness</li>
                  {data && wellnesslist.map((service, index) => (
                    <li key={index}>
                      <NavLink
                       
                        to={`${BASEURL}/services/${service.blog_category_name.toLowerCase()}/${service.post_title.toLowerCase().replace(/[^a-z0-9]+/g, '-')}`}
                       
                      >
                        {service.post_title}
                      </NavLink>
                    </li>
                  ))}
                </ul>

               
              </div>
              <div className='text-center' style={{padding:'20px 0'}}>
                  <Link to="/" className='btn btn-primary mt-4'>Return Home</Link>
                </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
