export function cleanHtml(htmlString) {
    htmlString = truncateDescription(htmlString , 200)
   return htmlString;
}


export const truncateDescription = (description, limit) => {
    if (description.length > limit) {
      return description.substring(0, limit) + '...';
    }
    return description;
  };
  


  