import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const urlRedirects = {
  "/dayton": "/location/dayton",
  "/dayton/": "/location/dayton",

  "/brown-st/": "/location/brown-st",

  "/centerville/": "/location/centerville",
  "/oak-creek/": "/location/oak-creek",

  "/columbus/": "/location/columbus",
  "/new-albany/": "/location/new-albany",
  

  "/booknow": "/online-booking",
  "/columbus": "/location/columbus",
  "/services.php": "/services",
  "/booknow.php": "/online-booking",
  "/team-detail/dayton/evelyn": "/team/dayton",
  "/team-detail/centerville/adam-freshour": "/team/centerville",
  "/team": "/aboutus",
  "/oak-creek": "/location/oak-creek",
  "/wedding": "/services",
  "/team-detail/centerville/betsi-rowland": "/team/centerville",
  "/blog-details/explore-our-hair-texture-services-at-square-one-salon": "/blog",
  "/team-detail/new-albany/lori-davis": "/team/new-albany",
  "/team-detail/brown-st/angie-mehaffie": "/team/brown-st",
  "/blog-details/top-5-convincing-arguments-for-waxing-instead-of-shaving": "/blog",
  "/team-detail/dayton/dennis-hale": "/team/dayton",
  "/team-detail/dayton/mia-wallace": "/team/dayton",
  "/team-detail/columbus/jada-briggs": "/team/columbus",
  "/team-detail/dayton/mary-kate-haas": "/team/dayton",
  "/team-detail/brown-st/grace-boman": "/team/brown-st",
  "/team-detail/brown-st/tori-acevedo": "/team/brown-st",
  "/oak-creek.php": "/oak-creek",


  
  "/blog-details/the-5-most-gorgeous-wedding-manicures-this-year": "/blogdetails/the-5-most-gorgeous-wedding-manicures-this-year",
  "/columbus.php%20target=": "/location/columbus",
  "/blog-details/healthy-nails-and-nourished-skin-with-professional-mani-pedis": "/blogdetails/healthy-nails-and-nourished-skin-with-professional-mani-pedis",
  "/blog-details/which-of-these-8-stylish-2022-makeup-trends-is-right-for-you": "/blogdetails/which-of-these-8-stylish-2022-makeup-trends-is-right-for-you",
  "/team-detail/oak-creek/paula-barrows": "/team/oak-creek",
  "/team-detail/centerville/tracy-freeman": "/team/centerville",
  "/specials": "/promotions",
  "/index.php": "/",
  "/blog-details/advantages-of-dermaplaning-for-smooth-healthy-skin": "/blogdetails/advantages-of-dermaplaning-for-smooth-healthy-skin",
  "/blog-details/fresh-start-to-spring-with-a-new-hair-color-or-highlight": "/blogdetails/fresh-start-to-spring-with-a-new-hair-color-or-highlight",
  "/team-detail/columbus/dani-culp": "/team/columbus",
  "/centerville": "/location/centerville",
  "/team-detail/centerville/tim-farquhar": "/team/centerville",
  "/team/specials": "/promotions",
  "/new-albany.php": "/location/new-albany",
  "/team-detail/dayton/karen-wendling": "/team/dayton",
  "/blog-details/5-reasons-your-man-will-love-our-square-one-men-s-spa-services": "/blogdetails/5-reasons-your-man-will-love-our-square-one-men-s-spa-services",
  "/team-detail/brown-st/chelsea-teat": "/team/brown-st",
  "/blog-details/perfect-eyes-with-lash-brow-enhancements-at-square-one-salon": "/blogdetails/perfect-eyes-with-lash-brow-enhancements-at-square-one-salon",
  "/team-detail/brown-st/yvonne-haley": "/team/brown-st",
  "/team-detail/centerville/lori-heffner": "/team/centerville",
  "/new-albany": "/location/new-albany",
  "/team-detail/dayton/lacey-terrell": "/team/dayton",
  "/service-salon.php": "/services",
  "/blog-details/college-mornings-made-easy-with-quick-hairstyles": "/blogdetails/college-mornings-made-easy-with-quick-hairstyles",
  "/index": "/",
  "/blog-details/the-7-most-fashionable-haircuts-for-men-this-summer": "/blogdetails/the-7-most-fashionable-haircuts-for-men-this-summer",
  "/team-detail/new-albany/madeline-smith": "/team/new-albany",
  "/blog-details/the-beauty-of-hair-gloss-enhance-your-color-and-shine": "/blogdetails/the-beauty-of-hair-gloss-enhance-your-color-and-shine",
  "/team/404": "/",
  "/blog-details/ringing-in-2024-with-trending-hair-colors-and-highlighting-techniques": "/blogdetails/ringing-in-2024-with-trending-hair-colors-and-highlighting-techniques",
  "/team/wedding": "/",
  "/blog-details/stylish-fall-spooky-halloween-nail-art": "/blogdetails/stylish-fall-spooky-halloween-nail-art",
  "/blog-details/highlights-balayages-roots-oh-my": "/blogdetails/highlights-balayages-roots-oh-my",
  "/team-detail/dayton/grace-fisher": "/team/dayton",
  "/team-detail/centerville/marie-maude-charles-valcourt": "/team/centerville",
  "/team/booknow": "/online-booking",
  "/team/team": "/",
  "/team-detail/columbus/ydalis-almonte": "/team/columbus",
  "/team-detail/centerville/abby-grubb": "/team/centerville",
  "/team-detail/brown-st/stephanie-minutolo": "/team/brown-st",
  "/blog-details/know-what-sebaceous-filaments-are-and-how-to-treat-them": "/blogdetails/know-what-sebaceous-filaments-are-and-how-to-treat-them",
  "/team-detail-1.php/4431": "/",
  "/team-detail.php/dayton": "/team/dayton",
  "/team-detail.php/broen-st": "/team/brown-st",
  "/blog-details/summer-hair-color-trends-2024-the-biggest-surprises-you-re-going-to-love": "/blogdetails/summer-hair-color-trends-2024-the-biggest-surprises-you-re-going-to-love",
  "/team-detail/columbus/colby-caudill": "/team/columbus",
  "/team-detail/dayton/tori-payne": "/team/dayton",
  "/team-detail/centerville/macy-richards": "/team/centerville",
  "/blog-details/5-benefits-of-sunscreen-even-in-the-chill": "/blogdetails/5-benefits-of-sunscreen-even-in-the-chill",
  "/team-detail/brown-st/kayla-washington": "/team/brown-st",
  "/team-detail/new-albany/alli-wolfe": "/team/new-albany",
  "/team-detail/centerville/rae-emerson": "/team/centerville",
  "/team-detail/brown-st/jordyn-howell": "/team/brown-st",
  "/team-detail/columbus/ron-keffer": "/team/columbus",
  "/blog-details/embrace-the-season-fall-2023-s-hottest-hair-colors-every-woman-must-try": "/blogdetails/embrace-the-season-fall-2023-s-hottest-hair-colors-every-woman-must-try",
  "/team-detail/new-albany/julie-miller": "/team/new-albany",
  "/team-detail/oak-creek/stephanie-thompson": "/team/oak-creek",
  "/team-detail/dayton/michelle-reyes": "/team/dayton",
  "/team-detail/centerville/alex-willhoite": "/team/centerville",
  "/salon-services/massage.php": "/services/wellness/massage",
  "/team-detail/dayton/cheryl-erbes": "/team/dayton",
  "/team-detail-1": "/",
  "/booknow.php/new-albany.php": "/team/new-albany",
  "/team-detail/dayton/brooklyn-pickett": "/team/dayton",
  "/locations.php": "/location",
  "/blog-details/who-is-the-best-candidate-for-this-procedure": "/blog",
  "/oak-creek.php/1000": "/location/oak-creek",
  "/team-detail.php/new-albany": "/team/new-albany",
  "/staff-44259": "/",
  "/team-detail.php/vew-albany": "/team/new-albany",
  "/news.htm": "/blog",
  "/team-detail/brown-st/jauntae-blakely": "/team/brown-st",
  "/team-detail.php/oak-creek": "/team/oak-creek",
  "/team-detail/centerville/rachel-stelzer": "/team/centerville",
  "/team-detail/centerville/shelby-leonard": "/team/centerville",
  "/hannah-mckinnon": "/",
  "/ydalis-almonte": "/",
  "/blog-details.php/get-gorgeous-hair-with-a-professional-blowout?blog_url=get-gorgeous-hair-with-a-professional-blowout": "/blogdetails/get-gorgeous-hair-with-a-professional-blowout",
  "/team-detail/new-albany/melissa-lewis": "/team/new-albany",
  "/blog-details/square-one-salon-for-all-your-men-s-hair-grooming-and-spa-needs": "/blogdetails/square-one-salon-for-all-your-men-s-hair-grooming-and-spa-needs",
  "/blog-details/blog-details?blog_url=perfect-eyes-with-lash-brow-enhancements-at-square-one-salon": "/blog",
  "/team-detail.php/brown-st": "/team/brown-st",
  "/blog-details/blog-details?blog_url=slow-premature-aging-with-regular-salon-facials": "/",
  "/team-detail/columbus/piper-richdale": "/team/columbus",
  "/team-detail/centerville/joe-davis": "/team/centerville",
  "/team-detail/centerville/kellie-pierce": "/team/centerville",
  "/team-detail/dayton/emily-tomasello": "/team/dayton",
  "/team-detail/new-albany/nicole-matthews": "/team/new-albany",
  "/blog-details/blog-details?blog_url=college-mornings-made-easy-with-quick-hairstyles": "/",
  "/piper-richdale": "/",
  "/team-detail/dayton/beth-williams": "/team/dayton",
  "/melissa-lewis": "/",
  "/team-detail/centerville/betsi-rowland": "/team/centerville",
  "/booknow.php": "/online-booking",
  "/dayton.php": "/location/dayton",
  "/team-detail/oak-creek/katy-menard": "/team/oak-creek",
  "/team-detail/dayton/kara-robbins": "/team/dayton",
  "/team-detail/columbus/love-crosby": "/team/columbus",
  "/team-detail/dayton/doug-henderson": "/team/dayton",
  "/team-detail/centerville/lauren-gardner": "/team/centerville",
  "/blog-details/your-skin-will-thank-you-for-year-round-waxing": "/blogdetails/your-skin-will-thank-you-for-year-round-waxing",
  "/team-detail/dayton/darrick-boston": "/team/dayton",
  "/team-detail/brown-st/angie-mehaffie": "/team/brown-st",
  "/team-detail/columbus/doug-henderson": "/team/columbus",
  "/team-detail/centerville/lisa-harelson": "/team/centerville",
  "/services/salon": "/services/salon/hair-color",
  "/services/salon/": "/services/salon/hair-color",
  "/services/wellness": "/services/wellness/makeup",
  "/blog-details/the-perfect-gift-for-mom-treat-her-to-a-relaxing-massage-this-mother-s-day": "/blogdetails/the-perfect-gift-for-mom-treat-her-to-a-relaxing-massage-this-mother-s-day",
  "/blog-details/it-s-time-to-spring-clean-your-hair-styling-tools-here-s-how-and-why": "/blogdetails/it-s-time-to-spring-clean-your-hair-styling-tools-here-s-how-and-why",
  "/team-detail/columbus/abby-gilmore": "/team/columbus",
  "/team-detail/centerville/reece-sutton": "/team/centerville",
  "/team-detail/centerville/shelby-wilson": "/team/centerville",
  "/blog-details/great-new-year-goals-for-beautiful-healthy-hair": "/blogdetails/great-new-year-goals-for-beautiful-healthy-hair",
  "/blog-details/give-mom-the-gift-of-a-square-one-spa-day": "/blogdetails/give-mom-the-gift-of-a-square-one-spa-day",
  "/blog-details/gender-neutral-pricing-is-coming-to-square-one-salon": "/blogdetails/gender-neutral-pricing-is-coming-to-square-one-salon",
  "/square-one-salon/centerville-salon.php": "/location/centerville",
  "/team-detail/dayton/alex-everett": "/team/dayton",
  "/blog-details/update-your-summer-look-at-square-one-salon": "/blogdetails/update-your-summer-look-at-square-one-salon",
  "/dayton-staff.php": "/team/dayton",
  "/staff-2511": "/",
  "/team-detail/dayton/ariel-brown": "/team/dayton",
  "/bridal-form.php": "/",
  "/team-detail/oak-creek/saren-kellis": "/team/oak-creek",
  "/oak-creek.php": "/location/oak-creek",
  "/team-detail.php/221": "/",
  "/team-detail/centerville/stephanie-thompson": "/team/centerville",
  "/brent-johnson": "/",
  "/team-detail.php": "/",
  "/blog-details/you-should-treat-your-inner-diva-this-summer-with-a-hot-new-perm": "/blogdetails/you-should-treat-your-inner-diva-this-summer-with-a-hot-new-perm",
  "/alex-everett": "/",
  "/team-detail/columbus/rachel-ruland": "/team/columbus",
  "/team-detail/new-albany/allison-kielmeyer": "/location/new-albany",
  "/team-detail.php/columbus": "/team/columbus",
  "/booknow.php/centerville.php": "/online-booking/centerville",
  "/team-detail/columbus/sean-burdine": "/team/columbus",
  "/team-detail/columbus/madison-varney": "/team/columbus",
  "/blog-details/holiday-hairstyles-from-glam-to-cozy-elegance-at-square-one-salon": "/blogdetails/holiday-hairstyles-from-glam-to-cozy-elegance-at-square-one-salon",
  "/lori-davis": "/",
  "/booknow.php/locations.php": "/online-booking",
  "/team-detail/centerville/erin-mcintosh": "/location/centerville",
  "/blog-details/rejuvenate-your-summer-with-a-blissful-massage-at-square-one-salon": "/blogdetails/rejuvenate-your-summer-with-a-blissful-massage-at-square-one-salon",
  "/blog-details/blooming-beauty-spring-makeup-trends-to-embrace-in-2024": "/blogdetails/blooming-beauty-spring-makeup-trends-to-embrace-in-2024",
  "/team-detail/new-albany/lillian-oleary": "/team/new-albany",
  "/team-detail/dayton/cassidy-coonrod": "/team/dayton",
  "/team-detail/columbus/aryn-mason": "/team/columbus",
  "/team-detail/centerville/kaiti-owens": "/team/centerville",
  "/team-detail/oak-creek/brooke-chasteen": "/team/oak-creek",
  "/blog-details/spice-up-your-autumn-style-with-these-trendy-hair-accessories": "/blogdetails/spice-up-your-autumn-style-with-these-trendy-hair-accessories",
  "/team-detail/dayton/leslie-simmons": "/team/dayton",
  "/blog-details/embrace-the-sunshine-a-guide-to-spring-waxing-at-square-one-salon": "/blogdetails/embrace-the-sunshine-a-guide-to-spring-waxing-at-square-one-salon",
  "/blog-details/rejuvenate-your-body-and-mind-with-a-hot-stone-massage": "/blogdetails/rejuvenate-your-body-and-mind-with-a-hot-stone-massage",
  "/team-detail/dayton/misty-wells": "/team/dayton",
  "/booknow.php/oakcreek.php": "/online-booking/oak-creek",
  "/blog-details/discover-the-benefits-of-dermaplaning-for-beautiful-radiant-skin": "/blogdetails/discover-the-benefits-of-dermaplaning-for-beautiful-radiant-skin",
  "/team-detail/columbus/rosanne-reese": "/team/columbus",
  "/blog-details/nail-trends-in-the-new-year-a-stylish-start-for-your-manicures": "/blogdetails/nail-trends-in-the-new-year-a-stylish-start-for-your-manicures",
  "/team-detail/dayton/jamee-parker": "/team/dayton",
  "/blog-details/dermaplaning-delight-revitalize-your-skin-for-spring-s-radiance": "/blogdetails/dermaplaning-delight-revitalize-your-skin-for-spring-s-radiance",
  "/team-detail/oak-creek/kelsey-klostermeier": "/team/oak-creek",
  "/team-detail/dayton/marie-battrell": "/team/dayton",
  "/series-of-services.php": "/services",
  "/team-detail/new-albany/annah-powell": "/team/new-albany",
  "/team-detail/oak-creek/caitlin-parigen": "/team/oak-creek",
  "/blog-details/6-lovely-pedicure-ideas-for-sandal-weather": "/blogdetails/6-lovely-pedicure-ideas-for-sandal-weather",
  "/team-detail/centerville/kellie-meadows": "/team/centerville",
  "/team-detail/new-albany/michelle-iddings": "/team/new-albany",
  "/blog-details/fall-winter-hair-colors-you-ll-love": "/blogdetails/fall-winter-hair-colors-you-ll-love",
  "/holidayform/": "/",
  "/team-detail/centerville/mary-suerdick": "/team/centerville",
  "/team-detail/oak-creek/sara-johnson": "/online-booking/oak-creek",
  "/service-spa.php": "/services",
  "/team-detail/brown-st/lindsey-zimmer": "/team/brown-st",
  "/love-crosby": "/",
  "/blog-details/embrace-the-vibrant-hues-top-trending-hair-colors-for-summer-2023": "/blogdetails/embrace-the-vibrant-hues-top-trending-hair-colors-for-summer-2023",
  "/team-detail/centerville/taylor-cortner": "/team/centerville",
  "/blog-details/a-case-of-the-boxed-dye-blues-why-hair-stylists-swear-off-boxed-hair-dye": "/blogdetails/a-case-of-the-boxed-dye-blues-why-hair-stylists-swear-off-boxed-hair-dye",
  "/team-detail/oak-creek/angie-hatfield": "/team/oak-creek",
  "/team-detail/centerville/malory-hine": "/team/centerville",
  "/team-detail/new-albany/caroline-moran": "/team/new-albany",
  "/blog-details/relax-and-rejuvenate-the-amazing-benefits-of-getting-a-massage-at-a-spa": "/blogdetails/relax-and-rejuvenate-the-amazing-benefits-of-getting-a-massage-at-a-spa",
  "/team-detail/new-albany/breanna-g-lothes": "/team/new-albany",
  "/team-detail/dayton/tim-farquhar": "/team/dayton",
  "/team-detail/new-albany/marissa-tucker": "/team/new-albany",
  "/team-detail/dayton/josh-johnson": "/team/dayton",
  "/index.php/team-detail/dayton/joshua-stucky": "/team/dayton",
  "/team-detail/centerville/addison-henry": "/team/centerville",
  "/blog-details/how-to": "/blog",
  "/team-detail/brown-st/addi-howard": "/team/brown-st",
  "/team-detail/new-albany/angie-showalter": "/team/new-albany",
  "/team-detail/oak-creek/natalie-stuckey": "/team/oak-creek",
  "/team-detail/oak-creek/xiomara-brooks": "/team/oak-creek",
  "/team-detail/dayton/loghan-alexandra": "/team/dayton",
  "/team-detail/new-albany/alex-romans-carrel": "/team/new-albany",
  "/team-detail/centerville/kaiti-cinque": "/team/centerville",
  "/team-detail/new-albany/lori-davis": "/team/new-albany",
  "/booknow.php/dayton.php": "/team/dayton",
  "/team-detail/oak-creek/jamie-bennett": "/team/oak-creek",
  "/team-detail/brown-st/katie-reed": "/team/brown-st",
  "/team-detail/dayton/debra-tyler": "/team/dayton",
  "/team-detail/oak-creek/ashley-mcclure": "/team/oak-creek",
  "/blog-details/benefits-to-regular-massage-treatments-in-the-summer": "/blogdetails/benefits-to-regular-massage-treatments-in-the-summer",
  "/team-detail/brown-st/tori-acevedo": "/team/brown-st",
  "/blog-details/why-eyelash-extensions-are-the-perfect-solution-to-your-summer-lash-woes": "/blogdetails/why-eyelash-extensions-are-the-perfect-solution-to-your-summer-lash-woes",
  "/blog-details.php/testing?blog_url=testing": "/blog",
  "/blog-details/embrace-summer-with-stunning-nails-the-importance-of-manicures-and-pedicures": "/blogdetails/embrace-summer-with-stunning-nails-the-importance-of-manicures-and-pedicures",
  "/blog-details/testing": "/blog",
  "/blog-details/summer-wedding-looks-to-impress": "/blogdetails/summer-wedding-looks-to-impress",
  "/team-detail/dayton/morgan-gossett": "/team/dayton",
  "/team-detail/new-albany/marisa-grossman": "/team/new-albany",
  "/team-detail/dayton/dennis-hale": "/team/dayton",
  "/team-detail/dayton/brent-johnson": "/team/dayton",
  "/dani-culp": "/",
  "/team-detail/brown-st/liz-fair": "/team/brown-st",
  "/blog-details/slow-premature-aging-with-regular-salon-facials": "/blogdetails/slow-premature-aging-with-regular-salon-facials",
  "/team-detail/centerville/natalie-nagy": "/team/centerville",
  "/team-detail/dayton/taylor-guehl": "/team/dayton",
  "/team-detail/brown-st/rachel-bartz": "/team/brown-st",
  "/blog-details/take-the-boredom-out-of-winter-with-eyelash-extensions": "/blogdetails/take-the-boredom-out-of-winter-with-eyelash-extensions",
  "/giftcards": "/gift-card",
  "/index.php/new-albany-staff.php": "/team/new-albany",
  "/gallery": "/aboutus",
  "/team-detail/new-albany/lillian-o-leary": "/team/new-albany",
  "/team-detail/centerville/sandra-kiefer": "/team/centerville",
  "/team-detail/dayton/joshua-stucky": "/team/oak-creek",
  "/team-detail/dayton/china-cassidy": "/team/oak-creek",
  "/blog-details/love-your-skin-again-with-dermaplaning": "/blogdetails/love-your-skin-again-with-dermaplaning",
  "/signup": "/online-booking",
  "/team-detail/new-albany/chris-carrico": "/team/new-albany",
  "/team-detail/new-albany/monica-jones": "/team/new-albany",
  "/blog-details/6-of-the-most-popular-hairstyles-for-women-in-2023": "/blogdetails/6-of-the-most-popular-hairstyles-for-women-in-2023",
  "/team-detail/centerville/maria-burkett": "/team/centerville",
  "/blog-details/top-4-gorgeous-eye-makeup-trends-we-found-for-2022": "/blogdetails/top-4-gorgeous-eye-makeup-trends-we-found-for-2022",
  "/bridal-form": "/online-booking",
  "/blog-details/gorgeous-spring-nail-art-trends-that-you-need-to-try": "/blogdetails/gorgeous-spring-nail-art-trends-that-you-need-to-try",
  "/university": "/",
  "/team-detail/dayton/alan-leonard": "/team/dayton",
  "/mia-wallace": "/",
  "/blog-details/unlocking-the-secret-to-stunning-hair-the-amazing-benefits-of-keratin-treatments": "/blogdetails/unlocking-the-secret-to-stunning-hair-the-amazing-benefits-of-keratin-treatments",
  "/blog-details/people-are-going-crazy-for-these-5-spring-hair-colors": "/blogdetails/people-are-going-crazy-for-these-5-spring-hair-colors",
  "/emily-bocock": "/",
  "/team-detail/new-albany/tessa-valandingham": "/team/new-albany",
  "/team-detail/dayton/marisa-turpin": "/team/dayton",
  "/team-detail/columbus/whit-brunner": "/team/columbus",
  "/team-detail/dayton/brooklynn-pickett": "/team/dayton",
  "/columbus.php": "/location/columbus",
  "/team-detail/dayton/summer-pace": "/team/dayton",
  "/team-detail/centerville/adam-freshour": "/team/centerville",
  "/team-detail/new-albany/anna-koesters": "/team/new-albany",
  "/team-detail/new-albany/kelsey-hawk": "/team/new-albany",
  "/booknow.php/index.php": "/online-booking",
  "/team-detail/columbus/jada-briggs": "/team/columbus",
  "/centerville.php": "/location/centerville",
  "/blog-details/unlocking-the-beauty-of-your-eyes-with-square-one-salon-s-lash-brow-enhancement-services": "/blogdetails/unlocking-the-beauty-of-your-eyes-with-square-one-salon-s-lash-brow-enhancement-services",
  "/team-detail/centerville/karessa-blackwell": "/team/centerville",
  "/team-detail/columbus/missy-fair": "/team/columbus",
  "/team-detail/oak-creek/anna-fletcher": "/team/oak-creek",
  "/team-detail/brown-st/olivia-nolan": "/team/brown-st",
  "/team-detail/new-albany/lisa-wardell": "/team/new-albany",
  "/blog-details/get-some-gorgeous-textured-curls-with-a-trendy-permed-look": "/blogdetails/get-some-gorgeous-textured-curls-with-a-trendy-permed-look",
  "/index.php/dayton-gallery.php": "/location/dayton",
  "/blog-details/how-regular-facials-help-combat-teenage-acne": "/blogdetails/how-regular-facials-help-combat-teenage-acne",
  "/team-detail/oak-creek/abby-mitchell": "/team/oak-creek",
  "/team-detail/centerville/jeffrey-hobson": "/team/centerville",
  "/blog-details/fall-2023-s-must-try-men-s-hairstyles-elevate-your-look-with-square-one-salon": "/blogdetails/fall-2023-s-must-try-men-s-hairstyles-elevate-your-look-with-square-one-salon",
  "/team-detail/columbus/alex-everett": "/team/columbus",
  "/columbus.php/1000": "/location/columbus",
  "/blog-details/7-college-hair-hacks-for-beautiful-healthy-hair-all-semester": "/blogdetails/7-college-hair-hacks-for-beautiful-healthy-hair-all-semester",
  "/team-detail.php/235": "/",
  "/team-detail/new-albany/mikala-johnston": "/team/new-albany",
  "/team-detail/dayton/emily-bocock": "/team/dayton",
  "/blog-details/try-one-of-these-8-gorgeous-hair-color-trends-hot-in-2023": "/blogdetails/try-one-of-these-8-gorgeous-hair-color-trends-hot-in-2023",
  "/team-detail/centerville/lisa-sutton": "/team/centerville",
  "/team-detail/dayton/brooke-morlock": "/team/dayton",
  "/blog-details/restart-your-waxing-journey-this-spring-for-these-great-benefits": "/blogdetails/restart-your-waxing-journey-this-spring-for-these-great-benefits",
  "/blog-details/use-these-great-tips-to-choose-the-best-stylist-to-work-with-your-hair": "/blogdetails/use-these-great-tips-to-choose-the-best-stylist-to-work-with-your-hair",
  "/team-detail/oak-creek/gabby-lacy": "/team/oak-creek",
  "/team-detail/centerville/christine-hearn": "/team/centerville",
  "/team-detail/oak-creek/brennan-paulin": "/team/oak-creek",
  "/team-detail/dayton/jake-sams": "/team/dayton",
  "/team-detail/new-albany/emma-demaree": "/team/new-albany",
  "/team-detail/centerville/lori-davis": "/team/centerville",
  "/blog-details/enjoy-smooth-manageable-hair-with-a-keratin-treatment": "/blogdetails/enjoy-smooth-manageable-hair-with-a-keratin-treatment",
  "/booknow.php/1000": "/online-booking",
  "/blog-details/show-your-holiday-cheer-with-a-festive-hairstyle": "/blogdetails/show-your-holiday-cheer-with-a-festive-hairstyle",
  "/blog-details/check-out-these-9-on-the-go-hairstyles-for-busy-women": "v/blogdetails/check-out-these-9-on-the-go-hairstyles-for-busy-women",
  "/team-detail/dayton/michael-philpot": "/team/dayton",
  "/dayton.php%20target=": "/location/dayton",
  "/team-detail/centerville/robin-harff": "/team/centerville",
  "/blog-details/the-best-ways-to-prevent-cold-chapped-hands-in-winter": "/blogdetails/the-best-ways-to-prevent-cold-chapped-hands-in-winter",
  "/blog-details/unveiling-unforgettable-2024-spring-and-summer-wedding-hair-and-makeup-trends": "/blogdetails/unveiling-unforgettable-2024-spring-and-summer-wedding-hair-and-makeup-trends",
  "/blog-details/these-are-just-some-of-the-most-attractive-manicures-trending-this-fall": "/blogdetails/these-are-just-some-of-the-most-attractive-manicures-trending-this-fall",
  "/blog-details/5-benefits-of-a-square-one-salon-waxing-routine": "/blogdetails/5-benefits-of-a-square-one-salon-waxing-routine",
  "/blog-details/get-to-know-these-wonderful-benefits-for-consistent-massages": "/blogdetails/get-to-know-these-wonderful-benefits-for-consistent-massages",
  "/cassidy-coonrod": "/",
  "/booknow.php/": "/online-booking",
  "/team-detail/brown-st/emily-mickle": "/team/brown-st",
  "/team-detail/brown-st/macy-stein": "/team/brown-st",
  "/index.php/1000": "/",
  "/holidayform": "/promotions",
  "/blog-details/home-care-test": "/blog",
  "/team-detail/dayton/taran-kaur": "/team/dayton",
  "/centerville.php%20target=": "/location/centerville",
  "/taran-kaur": "/",
  "/team-detail/columbus/maddy-king": "/team/columbus",
  "/team-detail/dayton/john-edinger": "/team/dayton",
  "/aryn-mason": "/",
  "/gallery.php": "/",
  "/team-detail/new-albany/hannah-mckinnon": "/team/new-albany",
  "/team-detail/dayton/eva-brueggemeyer": "/team/dayton",
  "/eva-brueggemeyer": "/",
  "/jordyn-howell": "/",
  "/new-albany.php%20target=": "/location/new-albany",
  "/team-detail/columbus/laverta-hallman": "/team/columbus",
  "/doug-henderson": "/",
  "/kelsey-klostermeier": "/",
  "/malory-hine": "/",
  "/blog.php": "/blog",
  "/caitlin-parigen": "/",
  "/natalie-nagy": "/",
  "/abby-mitchell": "/",
  "/lori-heffner": "/",
  "/new-albany.php/1000": "/location/new-albany",
  "/jeffrey-hobson": "/",
  "/chelsea-teat": "/",
  "/taylor-cortner": "/",
  "/colby-caudill": "/",
  "/university.php": "/",
  "/donations.php": "/donations",
  "/team.php": "/aboutus",
  "/laverta-hallman": "/",
  "/centerville.php/1000": "/location/centerville",
  "/alli-wolfe": "/",
  "/service-salon.php/1000": "/services",
  "/specials.php/1000": "/promotions",
  "/contactus.php": "/contactus",
  "/dayton.php/1000": "/location/dayton",
  "/maria-burkett": "/",
  "/contactus.php/1000": "/contactus",
  "/specials.php": "/promotions",
  "/lindsey-zimmer": "/",
  "/josh-johnson": "/",
  "/angie-showalter": "/",
  "/gift-card.php": "/",
  "/wedding.php": "/",
  "/michael-philpot": "/",
  "/alan-leonard": "/",
  "/angie-mehaffie": "/",
  "/team-detail": "/",
  "/abby-gilmore": "/",
  "/emily-mickle": "/",
  "/aboutus.php": "/",
  "/yvonne-haley": "/",
  "/team-detail/dayton/ti-asia-jackson": "/team/dayton",
  "/rachel-stelzer": "/",
  "/stephanie-minutolo": "/",
  "/tracy-freeman": "/",
  "/lacey-terrell": "/",
  "/team-detail/new-albany/jenna-johnson": "/team/new-albany",
  "/team-detail/centerville/kellie-furderer": "/team/centerville",
  "/team-detail/new-albany/allison-brock": "/team/new-albany",
  "/blog-details": "/blog",
  "/blog-details.php": "/blog",
  "/addison-henry": "/",
  "/brooke-morlock": "/",
  "/canaan-good": "/",
  "/christine-hearn": "/",
  "/debra-tyler": "/",
  "/jauntae-blakely": "/",
  "/kellie-pierce": "/",
  "/macy-richards": "/",
  "/nicole-matthews": "/",
  "/rosanne-reese": "/",
  "/tim-farquhar": "/",
  "/tori-payne": "/",
  "/betsi-rowland": "/",
  "/caroline-moran": "/",
  "/china-cassidy": "/",
  "/evelyn": "/",
  "/madeline-smith": "/",
  "/michelle-iddings": "/",
  "/monica-jones": "/",
  "/morgan-gossett": "/",
  "/summer-pace": "/",
  "/anna-fletcher": "/",
  "/brown-st": "/",
  "/emma-demaree": "/",
  "/erin-mcintosh": "/",
  "/jada-briggs": "/",
  "/julie-miller": "/",
  "/kaiti-cinque": "/",
  "/leslie-simmons": "/",
  "/marie-battrell": "/",
  "/misty-wells": "/",
  "/rae-emerson": "/",
  "/shelby-wilson": "/",
  "/anna-koesters": "/",
  "/jamee-parker": "/",
  "/annah-powell": "/",
  "/emily-tomasello": "/",
  "/john-edinger": "/",
  "/paula-barrows": "/",
  "/adam-freshour": "/",
  "/alex-romans-carrel": "/",
  "/beth-williams": "/",
  "/breanna-g-lothes": "/",
  "/dennis-hale": "/",
  "/kelsey-hawk": "/",
  "/rachel-bartz": "/",
  "/chris-carrico": "/",
  "/joe-davis": "/",
  "/katy-menard": "/",
  "/mary-suerdick": "/",
  "/ron-keffer": "/",
  "/taylor-guehl": "/",
  "/lillian-oleary": "/",
  "/grace-boman": "/",
  "/jamie-bennett": "/",
  "/joshua-stucky": "/",
  "/lauren-gardner": "/",
  "/marissa-tucker": "/",
  "/olivia-nolan": "/",
  "/tori-acevedo": "/",
  "/darrick-boston": "/",
  "/mary-kate-haas": "/",
  "/tessa-valandingham": "/",
  "/mikala-johnston": "/",
  "/sandra-kiefer": "/",
  "/abby-grubb": "/",
  "/karessa-blackwell": "/",
  "/team-detail-1.php/maddy-king": "/",
  "/cheryl-erbes": "/",
  "/grace-fisher": "/",
  "/jake-sams": "/",
  "/lillian-o-leary": "/",
  "/lisa-wardell": "/",
  "/robin-harff": "/",
  "/xiomara-brooks": "/",
  "/addi-howard": "/",
  "/stephanie-thompson": "/",
  "/karen-wendling": "/",
  "/gabby-lacy": "/",
  "/angie-hatfield": "/",
  "/natalie-stuckey": "/",
  "/lisa-sutton": "/",
  "/kellie-meadows": "/",
  "/saren-kellis": "/",
  "/brooke-chasteen": "/",
  "/katie-reed": "/",
  "/brooklynn-pickett": "/",
  "/loghan-alexandra": "/",
  "/whit-brunner": "/",
  "/team-detail-1.php/alli-wolfe": "/",
  "/liz-fair": "/",
  "/brennan-paulin": "/",
  "/ashley-mcclure": "/",
  "/marisa-grossman": "/",
  "/team-detail-1.php/joe-davis": "/",
  "/allison-brock": "/",
  "/madison-varney": "/",
  "https://www.squareonesalon.com/columbus.php%20target=" : "https://www.squareonesalon.com/location/columbus",
  "https://www.squareonesalon.com/dayton.php%20target=" : "https://www.squareonesalon.com/location/dayton",
  "https://www.squareonesalon.com/centerville.php%20target=" : "https://www.squareonesalon.com/location/centerville",
  "https://www.squareonesalon.com/new-albany.php%20target=" : "https://www.squareonesalon.com/location/new-albany",


  "/blog-details/3-benefits-of-a-winter-facial-nourishing-your-skin-through-the-cold-months": "/blogdetails/3-benefits-of-a-winter-facial-nourishing-your-skin-through-the-cold-months",
  "/blog-details.php/get-gorgeous-hair-with-a-professional-blowout?blog_url=get-gorgeous-hair-with-a-professional-blowout": "/blogdetails/get-gorgeous-hair-with-a-professional-blowout",
  "/blog-details.php/testing?blog_url=testing": "/blog",
  "/v/blogdetails/check-out-these-9-on-the-go-hairstyles-for-busy-women": "/blogdetails/check-out-these-9-on-the-go-hairstyles-for-busy-women",




  "/team-detail/brown-st/alli-whitesell": "/team/brown-st",
  "/team-detail-1.php/brandy-richardson": "/aboutus",
  "/team-detail-1.php/rachel-stelzer": "/aboutus",
  "/vendors/modernizr/test/caniuse_files/mathml.html": "/",
  "/vendors/modernizr/test/caniuse_files/caniuse.html": "/",
  "/vendors/jquery-ui/themes/flick/images": "/",
  "/request_spa_services": "/services",
  "/resier_services_data": "/services",
  "/vendors/modernizr/test/caniuse_files/pushstate.html": "/",
  "/vendors/modernizr/test/caniuse.html": "/",
  "/vendors/modernizr/test/caniuse_files/xhtml.html": "/",
  "/squareonesalon.com/404": "/",
  "/request_salon_services": "/services",
  "/image?give=1": "/",
  "/blog/testing": "/blog",
  "/square-one-salon/dayton-salon.php": "/location/dayton",
  "/team-detail/dayton/laura-belle": "/team/dayton",
  "/team/liz-fair": "/aboutus",
  "/team-detail/dayton/canaan-good": "/team/dayton",
  "/team-detail-1.php/45717": "/",
  "/team/loghan-alexandra": "/aboutus",
  "/team/grace-boman": "/aboutus",
  "/team/laverta-hallman": "/aboutus",
  "/team-detail-1.php/brent-johnson": "/aboutus",
  "/team-detail-1.php/love-crosby": "/aboutus",
  "/team/lacey-terrell": "/aboutus",
  "/team-detail-1.php/mikala-johnston": "/aboutus"
  
};

const RedirectHandler = () => {


  const navigate = useNavigate();
  const location = useLocation();


//   useEffect(() => {
//     const currentPath = location.pathname;
//     if (urlRedirects[currentPath]) {
//       // Redirect to new URL
//       navigate(urlRedirects[currentPath], { replace: true });
//     }
//   }, [location.pathname, navigate]);

//   return null;
// 

useEffect(() => {
  // Get both pathname and query string to check the full URL
  const currentPath = location.pathname + location.search;

  // Check if a redirect exists for the current path (including query parameters)
  if (urlRedirects[currentPath]) {
    // Redirect to the new URL with replace option
    navigate(urlRedirects[currentPath], { replace: true });
  }
}, [location.pathname, location.search, navigate]);
};

export default RedirectHandler;
