import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

const BASEURL = process.env.REACT_APP_SQUAREONE_BASE_URL;
const SALONAPI = process.env.REACT_APP_SQUAREONE_API_EXTERNAL;
const ContactFormMain = ({ locationDetailsDayton }) => {
  const [captchaToken, setCaptchaToken] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    emailAddress: "",
    phoneNumber: "",
    message: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const recaptchaRef = useRef(null);

  useEffect(() => {
    if (showModal) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [showModal]);

  useEffect(() => {
    if (captchaToken) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        captcha: "",
      }));
    }
  }, [captchaToken]);

  const handleChange = (e) => {
    const { name, value } = e.target;
  
    let formattedValue = value;
    if (name === 'phoneNumber') {
      formattedValue = formatPhoneNumber(value);
    }
  
    setFormData((prevFormData) => ({ ...prevFormData, [name]: formattedValue }));
    validateField(name, formattedValue);
  };

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const formatPhoneNumber = (value) => {
    if (!value) return value;
    
    const phoneNumber = value.replace(/[^\d]/g, ''); // Remove all non-digit characters
    
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber?.slice(0, 3)}) ${phoneNumber?.slice(3)}`;
    }
    return `(${phoneNumber?.slice(0, 3)}) ${phoneNumber?.slice(3, 6)}-${phoneNumber?.slice(6, 10)}`;
  };

  

  const validateField = (fieldName, value) => {
    let errorMessage = "";
    const nameRegex = /^[A-Za-z]+$/;
    const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
  
    switch (fieldName) {
      case "firstName":
        if (!value.trim()) {
          errorMessage = "First name is required";
        } else if (!nameRegex.test(value.trim())) {
          errorMessage = "First name can only contain letters";
        }
        break;
      case "lastName":
        if (!value.trim()) {
          errorMessage = "Last name is required";
        } else if (!nameRegex.test(value.trim())) {
          errorMessage = "Last name can only contain letters";
        }
        break;
      case "emailAddress":
        if (!value.trim()) {
          errorMessage = "Email address is required";
        } else if (!/\S+@\S+\.\S+/.test(value)) {
          errorMessage = "Invalid email address";
        }
        break;
      case "phoneNumber":
        if (!value.trim()) {
          errorMessage = "Phone number is required";
        } else if (!phoneRegex.test(value)) {
          errorMessage = "Invalid phone number format";
        }
        break;
      case "message":
        if (!value.trim()) {
          errorMessage = "Message is required";
        }
        break;
      default:
        break;
    }
  
    setFormErrors((prevFormErrors) => ({
      ...prevFormErrors,
      [fieldName]: errorMessage,
    }));
  };
  
  

 const validateForm = () => {
  const updatedFormErrors = {};

  // Validate each field
  for (const fieldName in formData) {
    if (formData.hasOwnProperty(fieldName)) {
      const value = formData[fieldName];
      validateField(fieldName, value);
      if (!value.trim() || formErrors[fieldName]) {
        updatedFormErrors[fieldName] = formErrors[fieldName] || "This field is required";
      }
    }
  }

  // Check if captcha is missing
  if (!captchaToken) {
    updatedFormErrors.captcha = "Please complete the reCAPTCHA";
  }

  // Update formErrors with the new errors
  setFormErrors(updatedFormErrors);

  // Check if there are any errors
  const isValid = Object.keys(updatedFormErrors).length === 0;

  return isValid;
};


const handleSubmit = (e) => {
  e.preventDefault();
  if (validateForm()) {
    const postData = {
      name: `${formData.firstName} ${formData.lastName}`,
      phone: formData.phoneNumber,
      email: formData.emailAddress,
      subject: "-",
      message: formData.message,
      token : captchaToken
    };
    console.log(postData, 'post data')

    const url = locationDetailsDayton
      ? `${SALONAPI}/wscontactus/add_message/${locationDetailsDayton.salonId}/${locationDetailsDayton.getInTouch}`
      : `${SALONAPI}/wscontactus/add_message/415/23751`;

    fetch(url, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(postData)
      
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((responseData) => {
        if (responseData.status === true) {
          openModal();
          // Clear form fields after successful submission
          setFormData({
            firstName: "",
            lastName: "",
            emailAddress: "",
            phoneNumber: "",
            message: "",
          });
          setCaptchaToken("");
          recaptchaRef.current.reset();
        }
      })
      .catch((error) => {
        console.error("Error during POST request:", error);
      });
  } else {
    console.log('Form not valid');
  }
};


 

  const openModal = () => {
  
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    recaptchaRef.current.reset();
  };

  return (
    <div className="contactForm">
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-6 col-12">
            <div className="form-floating">
              <input
                type="text"
                className={`form-control ${formErrors.firstName && "input-error"}`}
                id="firstname"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                placeholder="Jane" 
              />
              <label htmlFor="firstname">First Name</label>
              {formErrors.firstName && (
                <div className="text-danger pt-2 ps-1">
                  {formErrors.firstName}
                </div>
              )}
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div className="form-floating">
              <input
                type="text"
                className={`form-control ${formErrors.lastName && "input-error"}`}
                id="lastname"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                placeholder="Doe"
              />
              <label htmlFor="lastname">Last Name</label>
              {formErrors.lastName && (
                <div className="text-danger pt-2 ps-1">
                  {formErrors.lastName}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-12">
            <div className="form-floating">
              <input
                type="email"
                className={`form-control ${formErrors.emailAddress && "input-error"}`}
                id="emailAddress"
                name="emailAddress"
                value={formData.emailAddress}
                onChange={handleChange}
                placeholder="you@example.com"
              />
              <label htmlFor="emailAddress">Email Address</label>
              {formErrors.emailAddress && (
                <div className="text-danger pt-2 ps-1">
                  {formErrors.emailAddress}
                </div>
              )}
            </div>
          </div>
          <div className="col-md-12 col-12">
            <div className="form-floating">
              <input
                type="text"
                className={`form-control ${formErrors.phoneNumber && "input-error"}`}
                id="phoneNumber"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                placeholder="+1 (123) 456-7890"
              />
              <label htmlFor="phoneNumber">Phone Number</label>
              {formErrors.phoneNumber && (
                <div className="text-danger pt-2 ps-1">
                  {formErrors.phoneNumber}
                </div>
              )}
            </div>
          </div>
          <div className="col-md-12 col-12">
            <div className="form-floating">
              <textarea
                className={`form-control ${formErrors.message && "input-error"}`}
                placeholder="Contents of message go here…"
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
              ></textarea>
              <label htmlFor="message">Message</label>
              {formErrors.message && (
                <div className="text-danger pt-2 ps-1">
                  {formErrors.message}
                </div>
              )}
            </div>
          </div>
          <div className="col-md-12 col-12">
            <div className="robot">
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey="6LeffZ4UAAAAALDuVNImbIsDt6pfmlSIhHnVHXnK"
                onChange={handleCaptchaChange}
              />
              {formErrors.captcha && (
                <div className="text-danger pt-2 ps-1">
                  {formErrors.captcha}
                </div>
              )}
            </div>
          </div>
          <div className="col-md-12 col-12">
            <div className="text-start">
              <input
                type="submit"
                className="btn btn-primary border-0"
                value="Submit"
              />
            </div>
          </div>
        </div>

        {showModal && (
          <div id="StaffModal" className={`modal-staff contact_modal ${showModal ? "show" : ""}`}>
            <div className="modal-staff-dialog">
              <div className="modal-staff-content">
                <button type="button" className="close" onClick={closeModal}>
                  <span>
                    <img
                      src={`${BASEURL}/assets/images/close.svg`}
                      width="16.35"
                      height="16.35"
                      alt="close icon"
                    />
                  </span>
                </button>
                <div className="modal-staff-body">
                  <div className="check-your-inbox-gird">
                    <div className="check-your-icon">
                      <img
                        src={`${BASEURL}/assets/images/mail.svg`}
                        width="34.06"
                        height="27.25"
                        alt="mail icon"
                      />
                    </div>
                    <h4>Your submission has been received!</h4>
                    <p>
                      Thank you for your interest in Square One Salon<br /> and Spa.
                      We will get back to you as soon as<br /> possible!
                    </p>
                    <Link to="" className="btn btn-primary" onClick={closeModal}>
                      Okay
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default ContactFormMain;
