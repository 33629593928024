import React, { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import ReCAPTCHA from "react-google-recaptcha";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const BASEURL = process.env.REACT_APP_SQUAREONE_BASE_URL;
const SALONAPI = process.env.REACT_APP_SQUAREONE_API_EXTERNAL;

const validationSchema = yup.object({
  location: yup.string().required("Location is required"),
  firstName: yup
    .string()
    .matches(/^[A-Za-z]+$/, "First name must contain only letters")
    .required("First Name is required"),
  lastName: yup
    .string()
    .matches(/^[A-Za-z]+$/, "Last name must contain only letters")
    .required("Last Name is required"),
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  phoneNumber: yup
    .string()
    .matches(/^[0-9]+$/, "Phone number must contain only numbers")
    .required("Phone Number is required"),
  organizationName: yup
    .string()
    .matches(/^[A-Za-z\s]+$/, "Organization name must contain only letters")
    .required("Organization Name is required"),
  streetAddress: yup.string().required("Street Address is required"),
  city: yup
    .string()
    .matches(/^[A-Za-z\s]+$/, "City must contain only letters")
    .required("City is required"),
  state: yup
    .string()
    .matches(/^[A-Za-z]+$/, "State must contain only letters")
    .required("State is required"),
  postalCode: yup
    .string()
    .matches(/^\d{5}(-\d{4})?$/, "Invalid postal code")
    .required("Postal Code is required"),
  eventDate: yup
    .date()
    .min(new Date(), "Event Date must be in the future", { inclusive: true })
    .required("Event Date is required"),
  startTime: yup.string().required("Start Time is required"),
  message: yup.string().required("Message is required"),
  captcha: yup.string().required("Please complete the reCAPTCHA"),
});

const DonationForm = () => {
  const location = ["Dayton Region", "Columbus Region"];
  const endOfDay = new Date();
  endOfDay.setHours(23, 59, 59);
  const [captchaToken, setCaptchaToken] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [captchaKey] = useState(1);
  const [selectedTime, setSelectedTime] = useState(null);
  const captchaRef = useRef(null);

  useEffect(() => {
    if (showModal) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [showModal]);

  const openModal = () => {
    formik.validateForm().then((errors) => {
      const isValid = Object.keys(errors).length === 0;
      if (isValid) {
       // setShowModal(true);
      }else{
        setShowModal(false);
      }
    });
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const formik = useFormik({
    initialValues: {
      location: "",
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      organizationName: "",
      streetAddress: "",
      apartment: "",
      city: "",
      state: "",
      postalCode: "",
      isNonProfit: false,
      hasDonatedBefore: false,
      eventDate: "",
      startTime: "",
      message: "",
      captcha: "",
    },
    validationSchema,

    onSubmit: async (values, { resetForm }) => {
      try {
        const response = await fetch(`${SALONAPI}/donationData`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        });
    
        const result = await response.json();
        console.log(result, 'result');

       
        if (result.status === true) {
          setShowModal(true); 
          resetForm();
          if (captchaRef.current) {
            captchaRef.current.reset();
          }
         
        } else {
          console.error("Failed to insert data:", result.message);
          console.error("Error:", result.error);
       
          setShowModal(false);
        }
      } catch (error) {
        console.error("Error posting form data:", error);
        // Ensure the modal is not displayed on error
        setShowModal(false);
      }
    }
    

  });

 

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
    formik.setFieldValue("captcha", token);
    
  };
 
  console.log(captchaToken)

  const states = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  return (
    <form onSubmit={formik.handleSubmit} className="contactForm">
      <div className="row">
        <div className="col-md-12 col-12">
          <div className="form-floating">
            <select
              className={`form-control ${
                formik.errors.location ? "input-error" : ""
              }`}
              id="selectLocation"
              name="location"
              value={formik.values.location}
              onChange={formik.handleChange}
              aria-label="Floating label select example"
            >
              <option value="">Select Location</option>
              {location.map((loc, index) => (
                <option key={index} value={loc}>
                  {loc}
                </option>
              ))}
            </select>
            <label htmlFor="selectLocation">Select Location</label>
            {formik.errors.location ? (
              <div className="text-danger pt-2 ps-1">
                {formik.errors.location}
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 col-12 pe-md-2">
          <div className="form-floating">
            <input
              type="text"
              className={`form-control ${
                formik.errors.firstName ? "input-error" : ""
              }`}
              id="firstname"
              name="firstName"
              placeholder="Jane"
              value={formik.values.firstName}
              onChange={formik.handleChange}
            />
            <label htmlFor="firstname">First Name</label>
            {formik.errors.firstName ? (
              <div className="text-danger pt-2 ps-1">
                {formik.errors.firstName}
              </div>
            ) : null}
          </div>
        </div>
        <div className="col-md-6 col-12 ps-md-2">
          <div className="form-floating">
            <input
              type="text"
              className={`form-control ${
                formik.errors.lastName ? "input-error" : ""
              }`}
              id="lastname"
              name="lastName"
              placeholder="Doe"
              value={formik.values.lastName}
              onChange={formik.handleChange}
            />
            <label htmlFor="lastname">Last Name</label>
            {formik.errors.lastName ? (
              <div className="text-danger pt-2 ps-1">
                {formik.errors.lastName}
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 col-12">
          <div className="form-floating">
            <input
              type="email"
              className={`form-control ${
                formik.errors.email ? "input-error" : ""
              }`}
              id="emailAddress"
              name="email"
              placeholder="you@example.com"
              value={formik.values.email}
              onChange={formik.handleChange}
            />
            <label htmlFor="emailAddress">Email Address</label>
            {formik.errors.email ? (
              <div className="text-danger pt-2 ps-1">{formik.errors.email}</div>
            ) : null}
          </div>
          <div className="form-floating">
            <input
              type="text"
              className={`form-control ${
                formik.errors.phoneNumber ? "input-error" : ""
              }`}
              id="phoneNumber"
              name="phoneNumber"
              placeholder="+1 (123) 456-7890"
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
            />
            <label htmlFor="phoneNumber">Phone Number</label>
            {formik.errors.phoneNumber ? (
              <div className="text-danger pt-2 ps-1">
                {formik.errors.phoneNumber}
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 col-12">
          <h4>Event Details</h4>
          <div className="form-floating">
            <input
              type="text"
              className={`form-control ${
                formik.errors.organizationName ? "input-error" : ""
              }`}
              id="organization_name"
              name="organizationName"
              placeholder="Lorem Ipsum"
              value={formik.values.organizationName}
              onChange={formik.handleChange}
            />
            <label htmlFor="organization_name">Organization Name</label>
            {formik.errors.organizationName ? (
              <div className="text-danger pt-2 ps-1">
                {formik.errors.organizationName}
              </div>
            ) : null}
          </div>
          <div className="form-floating">
            <input
              type="text"
              className={`form-control ${
                formik.errors.streetAddress ? "input-error" : ""
              }`}
              id="street_address"
              name="streetAddress"
              placeholder="123 Brown Street"
              value={formik.values.streetAddress}
              onChange={formik.handleChange}
            />
            <label htmlFor="street_address">Street Address</label>
            {formik.errors.streetAddress ? (
              <div className="text-danger pt-2 ps-1">
                {formik.errors.streetAddress}
              </div>
            ) : null}
          </div>
          <div className="form-floating">
            <input
              type="text"
              className={`form-control`}
              id="apartment"
              name="apartment"
              placeholder="APT. C"
              value={formik.values.apartment}
              onChange={formik.handleChange}
            />
            <label htmlFor="apartment">
              Apartment, suite, floor, etc. (optional)
            </label>
          </div>
          <div className="form-floating">
            <input
              type="text"
              className={`form-control ${
                formik.errors.city ? "input-error" : ""
              }`}
              id="city"
              name="city"
              placeholder="Philadelphia"
              value={formik.values.city}
              onChange={formik.handleChange}
            />
            <label htmlFor="city">City</label>
            {formik.errors.city ? (
              <div className="text-danger pt-2 ps-1">{formik.errors.city}</div>
            ) : null}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 col-12 pe-md-2">
          <div className="form-floating">
            <select
              className={`form-control ${
                formik.errors.state ? "input-error" : ""
              }`}
              id="state"
              name="state"
              value={formik.values.state}
              onChange={formik.handleChange}
              aria-label="Floating label select example"
            >
              <option value="">--Select a state--</option>
              {states.map((state, index) => (
                <option key={index} value={state}>
                  {state}
                </option>
              ))}
            </select>
            <label htmlFor="state">State</label>
            {formik.errors.state ? (
              <div className="text-danger pt-2 ps-1">{formik.errors.state}</div>
            ) : null}
          </div>
        </div>
        <div className="col-md-6 col-12 ps-md-2">
          <div className="form-floating">
            <input
              type="text"
              className={`form-control ${
                formik.errors.postalCode ? "input-error" : ""
              }`}
              id="postal_code"
              name="postalCode"
              placeholder="12345"
              value={formik.values.postalCode}
              onChange={formik.handleChange}
            />
            <label htmlFor="postal_code">Postal Code</label>
            {formik.errors.postalCode ? (
              <div className="text-danger pt-2 ps-1">
                {formik.errors.postalCode}
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 col-12">
          <div className="form-check-box mb-4">
            <h5>Is the organization a non-profit?</h5>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="isNonProfit"
                id="flexRadioDefault1"
                value="true"
                checked={formik.values.isNonProfit === "true"}
                onChange={formik.handleChange}
              />
              <label className="form-check-label" htmlFor="flexRadioDefault1">
                Yes
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="isNonProfit"
                id="flexRadioDefault2"
                value="false"
                checked={formik.values.isNonProfit === "false"}
                onChange={formik.handleChange}
              />
              <label className="form-check-label" htmlFor="flexRadioDefault2">
                No
              </label>
            </div>
          </div>
        </div>
        <div className="col-md-12 col-12">
          <div className="form-check-box mb-5">
            <h5>Have we previously donated to this organization?</h5>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="hasDonatedBefore"
                id="flexRadioDefault3"
                value="true"
                checked={formik.values.hasDonatedBefore === "true"}
                onChange={formik.handleChange}
              />
              <label className="form-check-label" htmlFor="flexRadioDefault3">
                Yes
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="hasDonatedBefore"
                id="flexRadioDefault4"
                value="false"
                checked={formik.values.hasDonatedBefore === "false"}
                onChange={formik.handleChange}
              />
              <label className="form-check-label" htmlFor="flexRadioDefault4">
                No
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 col-12 pe-md-2">
          <div className="form-floating">
            <DatePicker
              selected={formik.values.eventDate}
              onChange={(date) => formik.setFieldValue("eventDate", date)}
              className={`form-control ${
                formik.errors.eventDate ? "input-error" : ""
              }`}
              minDate={new Date()}
              dateFormat="MM/dd/yyyy"
              placeholderText="MM/DD/YYYY"
              filterDate={(date) => {
                if (
                  formik.values.location === "Columbus" &&
                  date.getDay() === 1
                ) {
                  return false;
                }
                return date.getDay() !== 0;
              }}
            />
            {formik.errors.eventDate ? (
              <div className="text-danger pt-2 ps-1">
                {formik.errors.eventDate}
              </div>
            ) : null}
          </div>
        </div>
        <div className="col-md-6 col-12 ps-md-2">
          <div className="form-floating">
            <DatePicker
              id="startTime"
              name="startTime"
              selected={selectedTime}
              onChange={(date) => {
                setSelectedTime(date);
                formik.setFieldValue("startTime", date);
              }}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="h:mm aa"
              placeholderText="Start Time"
              minTime={(() => {
                if (formik.values.eventDate) {
                  const dayOfWeek = formik.values.eventDate.getDay();
                  if (dayOfWeek === 6) {
                    return new Date().setHours(9, 0, 0, 0);
                  }
                }
                return new Date().setHours(8, 0, 0, 0);
              })()}
              maxTime={(() => {
                if (formik.values.eventDate) {
                  const dayOfWeek = formik.values.eventDate.getDay();
                  if (dayOfWeek === 6) {
                    return new Date().setHours(16, 0, 0, 0);
                  }
                }
                return new Date().setHours(20, 0, 0, 0);
              })()}
              timeConstraints={{
                minutes: {
                  step: 30,
                },
              }}
              className={`form-control ${
                formik.errors.startTime ? "input-error" : ""
              }`}
              disabled={!formik.values.eventDate}
            />
            {formik.errors.startTime ? (
              <div className="text-danger pt-2 ps-1">
                {formik.errors.startTime}
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 col-12">
          <div className="form-floating">
            <textarea
              className={`form-control ${
                formik.errors.message ? "input-error" : ""
              }`}
              placeholder="Contents of message go here…"
              id="message"
              name="message"
              value={formik.values.message}
              onChange={formik.handleChange}
            ></textarea>
            <label htmlFor="message">Message</label>
            {formik.errors.message ? (
              <div className="text-danger pt-2 ps-1">
                {formik.errors.message}
              </div>
            ) : null}
          </div>
        </div>

        <div className="col-md-12 col-12">
          <div className="robot">
            <ReCAPTCHA
              ref={captchaRef}
              key={captchaKey}
              sitekey="6LeffZ4UAAAAALDuVNImbIsDt6pfmlSIhHnVHXnK"
              onChange={handleCaptchaChange}
            />
            {formik.errors.captcha ? (
              <div className="text-danger pt-2 ps-1">
                {formik.errors.captcha}
              </div>
            ) : null}
          </div>
        </div>
        <div className="col-md-12 col-12">
          <div className="text-start">
            <button
              type="submit"
              onClick={openModal}
              className="btn btn-primary border-0"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      {showModal && (
        <div
          id="StaffModal"
          className={`modal-staff contact_modal ${showModal ? "show" : ""}`}
        >
          <div className="modal-staff-dialog">
            <div className="modal-staff-content">
              <button type="button" className="close" onClick={closeModal}>
                <span>
                  <img
                    src={`${BASEURL}/assets/images/close.svg`}
                    width="16.35"
                    height="16.35"
                    alt="close icon"
                  />
                </span>
              </button>
              <div className="modal-staff-body">
                <div className="check-your-inbox-gird">
                  <div className="check-your-icon">
                    <img
                      src={`${BASEURL}/assets/images/mail.svg`}
                      width="34.06"
                      height="27.25"
                      alt="mail icon"
                    />
                  </div>
                  <h4>Your submission has been received!</h4>
                  <p>
                    Thank you for your interest in our donation program.
                    <br />
                    Keep your eyes open for an email within the next
                    <br />
                    30 days from our team on next steps.
                  </p>
                  <button onClick={closeModal} className="btn btn-primary">
                    Okay
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </form>
  );
};

export default DonationForm;
