import React, { useContext, useEffect } from "react";
import SiteContext from "../../helpers/CreateContext";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import useFetch from "../../fetch/useFetch";
import { Helmet } from "react-helmet-async";

const BASEURL = process.env.REACT_APP_SQUAREONE_BASE_URL;
const SALONAPI = process.env.REACT_APP_SQUAREONE_API_EXTERNAL;

const GiftPackage = () => {
  const { salonData, salonInfoPending } = useContext(SiteContext);
  const { data: spaPackages } = useFetch(`${SALONAPI}/spapackages`);
  //console.log(spaPackages, "spaPackages");

  // const handlePurchaseClick = () => {
  //   window.location.href = "/purchase-spa-package";
  // };

  useEffect(() => {
    const banner = document.querySelector(".spapackageBanner");
    const grid = document.querySelector(".spaGird-section");

    if (banner && grid) {
      banner.addEventListener("click", () => {
        grid.scrollIntoView({ behavior: "smooth" });
      });
    }

    return () => {
      if (banner) {
        banner.removeEventListener("click", () => {
          grid.scrollIntoView({ behavior: "smooth" });
        });
      }
    };
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <link rel="canonical" href="https://www.squareonesalon.com/gift-card" />
        <title>
          Buy Gift Cards for Hair & Spa Services | Square One | Ohio
        </title>
        <meta
          name="description"
          content="Get gift cards for haircuts, color, extensions, spa treatments, and more at Square One Salon in Ohio. A great gift for anyone who loves beauty and wellness!"
        />
      </Helmet>

      <div className="inner-banner-main">
        {!salonInfoPending ? (
          <>
            {salonData &&
              parse(
                salonData &&
                  salonData.website_settings.gift_card_banner &&
                  salonData.website_settings.gift_card_banner
              )}
          </>
        ) : (
          <>
            <div className="videoWrapperli">
              <div
                className="shimmer"
                style={{ minHeight: "324px", display: "block" }}
              ></div>
            </div>
          </>
        )}
      </div>

      <div className="spapackageBanner">
        <div className="spapackageContent">
          <h4>
            Explore our Spa Packages – the Perfect Gift for Relaxation and
            Rejuvenation!
          </h4>
          <p>
            Not sure what to get? Try one of our recommended spa packages to
            start your wellness journey.{" "}
            <span>
              Available at Dayton, Brown St, Centerville, and New Albany.
            </span>
          </p>
        </div>
      </div>

      <section className="iframe-theme">
        <div className="container">
          <div className="iframe-max-theme max-1144">
            <div className="iframe-container">
              <iframe
                className="responsive-iframe"
                title="Gift Cards"
                src="https://book.salonbiz.com/widget/#/booking/purchase-gift-card?comToken=c244t201402131049p6t52Uxb7k6z75S6eN3a6"
                width="100%"
              ></iframe>
            </div>
          </div>
        </div>
      </section>

      <section className="spaGird-section">
        <div className="container">
          <div className="spaGird">
            <h2>Spa Packages</h2>
            <p>
              Not sure what to get? Try one of our recommended spa packages to
              start your wellness journey.{" "}
              <span>
                Available at Dayton, Brown St, Centerville, and New Albany.
              </span>
            </p>
            <div className="row">
              {spaPackages &&
                spaPackages.map((spaPackage, index) => {
                  return (
                    <div
                      className="col-lg-4 col-md-6 col-sm-6 col-12"
                      key={index}
                    >
                      <div className="promotion-gird">
                        <div className="promotion-card">
                          <span className="spaPrice">
                            {spaPackage.sub_menu_price}
                          </span>
                          <div className="promotion-img">
                            <img
                              src={
                                spaPackage.sub_menu_file
                                  ? spaPackage.sub_menu_file
                                  : `${BASEURL}/assets/images/blog-img6.png`
                              }
                              width="162"
                              height="162"
                              alt={spaPackage.sub_menu_title}
                              className="img-fluid"
                            />
                          </div>
                          <div className="promotionCont">
                            <h3>{spaPackage.sub_menu_title}</h3>
                            <div className="promotion-min">
                              <p>
                                {parse(
                                  spaPackage?.sub_menu_description
                                    ?.replace(/<[^>]*>/g, "")
                                    .replace(/\s+/g, " ")
                                    .trim()
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="promotion-download">
                          {spaPackage.sub_menu_externallink ? (
                            <Link
                              className="btn btn-link"
                              target="_blank"
                              to={spaPackage.sub_menu_externallink}
                            >
                              Purchase
                            </Link>
                          ) : (
                            <Link className="btn btn-link">Purchase</Link>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default GiftPackage;
