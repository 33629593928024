import parse, { domToReact } from 'html-react-parser';

export function replaceAnchorTags(htmlString) {
  const handleLinkClick = (event) => {
    event.preventDefault(); // Prevent React Router's default navigation behavior
    window.location.href = event.currentTarget.href; // Trigger a full page reload
  };

  return parse(htmlString, {
    replace: ({ name, attribs, children }) => {
      if (name === 'a' && attribs?.href) {
        return (
          <a href={attribs.href} onClick={handleLinkClick}>
            {domToReact(children)}
          </a>
        );
      }
    },
  });
}
