import React, { useState, useEffect , useRef} from 'react';
import useFetch from '../../fetch/useFetch';
import { Link } from 'react-router-dom';

const SALONAPI = process.env.REACT_APP_SQUAREONE_API_EXTERNAL; 

const Popup = () => {

    const [isPopupVisible, setPopupVisible] = useState(false);
    const popupRef = useRef(null);
    const {data:popup, loading : popupLoading} = useFetch(`${SALONAPI}/onLoadPopup`);

    let activePopup = popup && popup?.slice(0 , 1);

    let specialImage = activePopup && activePopup[0]?.specials_image_new;
    let specialURL = activePopup && activePopup[0]?.specials_url;
    
    
    useEffect(() => {
        if(popup){
            setPopupVisible(true)
        }
    },[popup])

    const handleClose = () => {
        setPopupVisible(false) 
    }
    

    const handleClickOutside = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
            setPopupVisible(false);
        }
      };
    
      useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);

  

    return (
        <React.Fragment>
            {isPopupVisible &&  popup?.length > 0 &&(
                <div id="StaffModal" className={`modal-staff ${isPopupVisible ? 'show' : ''}`}>
                    <div className="modal-staff-dialog" ref={popupRef}>
                        <div className="modal-staff-content modal-staff-content-load">
                            <button type="button" className="close" onClick={handleClose}>
                                <span>
                                    <img
                                        src={`assets/images/close.svg`}
                                        width="16.35"
                                        height="16.35"
                                        alt="close icon"
                                    />
                                </span>
                            </button>
                            <div className="modal-staff-body">
                              {
                                !popupLoading ? ( <div className='special-img'>
                                   <Link to = {specialURL}><img src={specialImage} alt='special' width='100%' /></Link> 
                                 </div>) : (<div className='shimmer'>
                                    <div className='shimmer-inner'></div>
                                 </div>)
                              }
                            
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default Popup;
