import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import "../blog/blog.css";

const BlogList = React.lazy(() => import('../../components/BlogList'));
const LatestBlog = React.lazy(() => import('../../components/LatestBlog'));
const NewsLetter = React.lazy(() => import('../../components/NewsLetter'));
const Banner = React.lazy(() => import('../../components/Banner'));

const BASEURL = process.env.REACT_APP_SQUAREONE_BASE_URL;
const SALONAPI = process.env.REACT_APP_SQUAREONE_API_EXTERNAL;


export default function Blog() {

  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  }

  const categories = ['Service Spotlight', 'At-Home Care', 'How-To’s'];
  
  const [count, setCount] = useState(10);
  const [blog, setBlogs] = useState([]);
  const [filterBlog, setFilterBlog] = useState([]);
  const [isPending, setIsPending] = useState(false);
  const [serverError, setServerError] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('all');

  


  useEffect(() => {
    const fetchData = async () => {
      setIsPending(true);
      try {
        const response = await fetch(`${SALONAPI}/wsblog/${count}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setBlogs(data);
        setFilterBlog(data);
      } catch (error) {
        setServerError(error.message);
      } finally {
        setIsPending(false);
      }
    };
    fetchData();
  }, [count]);

  useEffect(() => {
    if (selectedCategory === 'all') {
      setFilterBlog(blog);
    } else {
      setFilterBlog(blog.filter(blog => blog.blog_category_name.split(' ').join('-') === selectedCategory));
    }
  }, [selectedCategory, blog]);


  
  const filteredSroll = (category) => {
    setSelectedCategory(category.split(' ').join('-'));
    
     
  };


  const loadMore = () => {
    setCount(prevCount => prevCount + 9);
  }


  if (serverError) {
    return <div className='error-fetch'>Error: {serverError}</div>;
  }

  return (
    <>

       <Helmet>
       <link rel="canonical" href="https://www.squareonesalon.com/blog" />
        <title>2025 Hair Beauty Trends and Updates: Square One Salon & Spa</title>
        <meta name="description" content="Stay updated with the latest hair and beauty tips, updates, and news of Ohio & around the globe. Read the Aveda Hair Square One Salon & Spa blogs today!" />
       </Helmet>

      <div className="blog-sortbywrapper">
        <div className="blog-sortby-gird">
          <div className="blog-sortbyleft">
            <p>Sort by category:</p>
          </div>
          <div className="blog-sortbyright">
            <button className={`blog-toggle ${isOpen ? 'active' : ''}`} onClick={toggleDropdown}>
              <img src={`${BASEURL}/assets/images/mobile-arrow.svg`} width="16.65" height="8.33" alt="arrow" />
            </button>
            <ul className={`blog-menu ${isOpen ? 'open' : ''}`}>
              <li onClick={()=> setIsOpen(false)}>
                <span role="button" onClick={() => filteredSroll('all')} className={selectedCategory === "all" ? "active" : ''} >All</span>
              </li>
              {categories.map(category => (
                <li key={category} onClick={()=> setIsOpen(false)}>
                  <span role="button" onClick={() => filteredSroll(category)} className={selectedCategory === category.split(' ').join('-') ? "active" : ''}>{category}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <React.Suspense fallback={<div className='shimmer' style={{ minHeight: '327px', display: 'block' }}></div>}>
        <Banner BASEURL={BASEURL} isPending={isPending} />
      </React.Suspense>

      <div className="blog-main">
        <div className="blog-main-container">
          <div className="row">
            <div className="col-12 col-sm-12 col-lg-8 col-md-12 blog-item blog-item-large">
              {
                !isPending ? (
                  <React.Suspense fallback={<div className='shimmer' style={{ height: '327px', display: 'block' }}></div>}>
                    <LatestBlog BASEURL={BASEURL} blog={filterBlog} isPending={isPending}  />
                  </React.Suspense>
                ) : (
                  <div className='shimmer blog-item-in' style={{ height: '327px', display: 'block' }}></div>
                )
              }
            </div>

            <div className="col-12 col-sm-6 col-lg-4 col-md-6 get-latest-info d-sm-block">
              {
                !isPending ? (
                  <React.Suspense fallback={<div></div>}>
                    <NewsLetter BASEURL={BASEURL} isPending={isPending} />
                  </React.Suspense>
                ) : (
                  <div className='shimmer blog-item-in ' style={{ height: '327px', display: 'block' }}></div>
                )
              }
            </div>

            <React.Suspense fallback={<div></div>}>
              {
                !isPending ? (
                  <BlogList blog={filterBlog} isPending={isPending} />
                ) : (
                  <div className="shimmerRow" style={{ display: 'flex', gap: '16px', flexWrap: 'wrap' }}>
                    <div className='shimmer blog-item-in' style={{ height: '390px', width: '360px', display: 'block' }}></div>
                    <div className='shimmer blog-item-in' style={{ height: '390px', width: '360px', display: 'block' }}></div>
                    <div className='shimmer blog-item-in' style={{ height: '390px', width: '360px', display: 'block' }}></div>
                    <div className='shimmer blog-item-in' style={{ height: '390px', width: '360px', display: 'block' }}></div>
                    <div className='shimmer blog-item-in' style={{ height: '390px', width: '360px', display: 'block' }}></div>
                    <div className='shimmer blog-item-in' style={{ height: '390px', width: '360px', display: 'block' }}></div>
                  </div>
                )
              }
            </React.Suspense>
          </div>
          {filterBlog && filterBlog.length >= count && (
            <div className="text-start mt-3">
              <button className="btn btn-outline-primary" onClick={loadMore}>
              View More
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
