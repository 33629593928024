import React, { useState, useEffect, useRef } from 'react';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';

const ServiceDescription = ({ description, salonData, BASEURL }) => {

  console.log(description, 'jjjj')

  const [showModal, setShowModal] = useState(false);
  const popupRef = useRef(null);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (showModal) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [showModal]);

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setShowModal(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const insertAnchor = (text) => {
    const anchorText = 'texture chart';
    if (text.includes(anchorText)) {
      const parts = text.split(anchorText);
      return (
        <>
          {parts[0]}
          <Link onClick={openModal} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
            {anchorText}
          </Link>
          {parts[1]}
        </>
      );
    }
    return text;
  };

  const TextWithFormatting = ({ text }) => {
    const parts = text.split('Not sure of your hair texture?');
    
    return (
      <div id="service-description" >
         {/* <div dangerouslySetInnerHTML={{ __html: description }}  /> */}

        <p dangerouslySetInnerHTML={{ __html: description }} ></p>
       
       {/* <p>{parts[0].trim()} dummy</p>
        */}
        {parts[1] && (
          <p>
            Not sure of your hair texture?{' '}
            {insertAnchor(parts[1].trim())}
          </p>
        )}

      </div>
    );
  };

  return (
    <React.Fragment>
      <div id="service-description">
        <TextWithFormatting text={description} />
      </div>

      {showModal && (
        <div id="CutStyleModal" className="modal-staff cutstyle-modal show">
          <div className="modal-staff-dialog" ref={popupRef}>
            <div className="modal-staff-content">
              <button type="button" className="close" onClick={closeModal}>
                <span>
                  <img
                    src={`${BASEURL}/assets/images/close.svg`}
                    width="16.35"
                    height="16.35"
                    alt="close icon"
                  />
                </span>
              </button>
              <div className="modal-staff-body">
                <div className="check-your-inbox-grid">
                  {salonData && parse(salonData.website_settings.new_textured_hair_analysis && salonData.website_settings.new_textured_hair_analysis)}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default ServiceDescription;
