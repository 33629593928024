import React,{useContext} from 'react';
import "../lavelsystem/lavelsystem.css";
import SiteContext from '../../helpers/CreateContext';
import parse from 'html-react-parser';
import { Helmet } from "react-helmet-async";


export default function SalonPolicies() {
const { salonData , salonInfoPending } = useContext(SiteContext); 


  return (
    <>

<Helmet>
<link rel="canonical" href="https://www.squareonesalon.com/salon-policies" />
        <title>Salon Policies | Square One Salon & Spa</title>
        <meta name="description" content="Review our salon policies at Square One Salon & Spa. Learn about our guidelines and procedures." />
       </Helmet>

<div className="inner-banner-main">
        {
           !salonInfoPending ? (<>{salonData && parse(salonData.website_settings.new_salon_policies_banner && salonData.website_settings.new_salon_policies_banner) }</>) : (<><div className="videoWrapperli">
            <div className="shimmer" style={{ minHeight: '324px', display: 'block' }}></div>
          </div></>)
        }
        
      </div>


      <div className="container">
        {
          !salonInfoPending ? (<> {salonData && parse(salonData.website_settings.new_salon_policies_content && salonData.website_settings.new_salon_policies_content) } </>) : (<><div className="videoWrapperli">
           <>
           {[...Array(3)].map((_, index) => (
              <div className="levels_wrapper" style={{marginBottom:'20px'}}>
              <div className="shimmer"  style={{ minHeight: '25px', width:"100%", display: 'block',marginBottom:'16px' }}></div>
              <div className="shimmer"  style={{ minHeight: '25px', width:"95%", display: 'block',marginBottom:'16px' }}></div>
              <div className="shimmer"  style={{ minHeight: '25px', width:"90%", display: 'block', marginBottom:'16px' }}></div>
              </div>   
            ))}<br/><br/>
          </>
          </div></>)
        }

      </div>

  
    </>
  )
}
