import React from 'react'
import { Link } from 'react-router-dom'

const FixedFooter = ({locationDetailsDayton, salonInfo}) => {


  
  const handleLinkClick = (event) => {
    event.preventDefault(); // Prevent React Router's navigation
    window.location.href = event.currentTarget.href; // Trigger full reload
  };
  let addressLocation =salonInfo && salonInfo.salon_address && salonInfo.salon_address.toLowerCase()

  

  const formatAddress = (salonInfo, addressLocation) => {
    return `${addressLocation || ''}, ${salonInfo.salon_city.toLowerCase() || ''}, ${salonInfo.salon_state || ''} ${salonInfo.salon_zip.toLowerCase() || ''}`;
  };

  const BASEURL = process.env.REACT_APP_SQUAREONE_BASE_URL;
  return (
    <div className="location-fixed">
        <div className="location-fx-left">
          <h3>{locationDetailsDayton.locationName || ''}</h3>
      
          <p className='text-cap'>
   
             {
              salonInfo && (
                ["218", "219", "220", "235", "221", "222"].includes(salonInfo.salon_id) ?
                  formatAddress(salonInfo && salonInfo, salonInfo && addressLocation)
                  : ''
              )
            }
            </p>
        </div>
        <div className="location-fx-right">
          {/* <Link to  = { locationDetailsDayton && locationDetailsDayton?.onlineBooking} target='_blank' className="btn btn-white">Book Now</Link> */}
          <Link onClick={handleLinkClick} to  = {`${BASEURL}/online-booking/${locationDetailsDayton.urlformat && locationDetailsDayton.urlformat}`} className="btn btn-white">Book Now</Link>
        </div>
      </div>
  )
}

export default FixedFooter
