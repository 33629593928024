import React, { useContext } from "react";
import { Link } from "react-router-dom";
import "../contact/contact.css";
import SiteContext from '../../helpers/CreateContext';
import parse from 'html-react-parser';
import ContactFormMain from "../../components/ContactFormMain";
import { Helmet } from "react-helmet-async";


export default function Contact() {
  const { salonData } = useContext(SiteContext); 
  const BASEURL = process.env.REACT_APP_SQUAREONE_BASE_URL;
 

  return (
    <>
    
       <Helmet>
       <link rel="canonical" href="https://www.squareonesalon.com/contactus" />
        <title>Contact Us | Square One Salon & Spa Aveda Salon</title>
        <meta name="description" content="Get in touch with us at all six of our Square One Salon and Spa locations in Ohio. We're the best Aveda salon in Dayton, Columbus, Centerville, Brown St, Oak Street and New Albany, OH." />
       </Helmet>

      <div className="inner-banner-main">
        {salonData && parse(salonData?.website_settings?.new_contactus_main_banner && salonData?.website_settings?.new_contactus_main_banner) }
      </div>

      <div className="container">
        <div className="contactWrapper">
          <h3>If you need us, we are here for you</h3>
          <p>
            Please use the form below to submit any customer service inquiries.
            To contact a specific salon, please visit our find a location page {' '} 
            <Link to={`${BASEURL}/location`}>here.</Link>
          </p>
           <ContactFormMain/>
        </div>
      </div>

     
    </>
  );
}
