import React, {useContext} from "react";
import "../career/career.css";
import { Link } from "react-router-dom";
import SiteContext from "../../helpers/CreateContext";
import parse from 'html-react-parser';
import { Helmet } from "react-helmet-async";

const BASEURL = process.env.REACT_APP_SQUAREONE_BASE_URL;
export default function Career() {
  const { salonData } = useContext(SiteContext); 
  const handleLinkClick = (event) => {
    event.preventDefault(); // Prevent React Router's navigation
    window.location.href = event.currentTarget.href; // Trigger full reload
  };
  return (
    <>
   
       <Helmet>
       <link rel="canonical" href="https://www.squareonesalon.com/career" />

        <title>Salon & Spa Careers at Square One Aveda Salon</title>
        <meta name="description" content="Square One Salon and Spa is an Aveda salon in Ohio Join our team at Square One Salon & Spa. Explore career opportunities and apply today!" />
       </Helmet>
      <div className="inner-banner-main">
      {salonData && parse(salonData.website_settings && salonData.website_settings.new_site_career_banner && salonData.website_settings.new_site_career_banner) }
      </div>

      <div className="container">
        <div className="careerWrapper">
          <div className="row">
            <div className="col-md-8 col-12">
              <div className="careerVideo">
                <div className="videoWrapper">
                  <video className="video-inner" width="100%" height="400" muted controls autoPlay loop preload="true" playsInline>
                    <source src={`${BASEURL}/assets/images/career-video.mp4`} type="video/mp4"/>
                  </video>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="career_job">
                <div className="career_job_content">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40.164"
                      height="29.21"
                      viewBox="0 0 40.164 29.21"
                    >
                      <path
                        id="group_FILL1_wght400_GRAD0_opsz24"
                        d="M40-770.79V-775.9a5.347,5.347,0,0,1,.8-2.853,5.324,5.324,0,0,1,2.122-1.985,27.1,27.1,0,0,1,5.751-2.122,25.118,25.118,0,0,1,5.933-.707,25.118,25.118,0,0,1,5.933.707,27.1,27.1,0,0,1,5.751,2.122,5.324,5.324,0,0,1,2.122,1.985,5.347,5.347,0,0,1,.8,2.853v5.112Zm32.861,0v-5.477a7.345,7.345,0,0,0-1.118-3.857,9.861,9.861,0,0,0-3.172-3.172,23.031,23.031,0,0,1,4.382.936,22.846,22.846,0,0,1,3.834,1.62,8.088,8.088,0,0,1,2.51,2.031,3.9,3.9,0,0,1,.867,2.442v5.477ZM54.605-785.395a7.032,7.032,0,0,1-5.157-2.145A7.032,7.032,0,0,1,47.3-792.7a7.032,7.032,0,0,1,2.145-5.157A7.032,7.032,0,0,1,54.605-800a7.032,7.032,0,0,1,5.157,2.145,7.032,7.032,0,0,1,2.145,5.157,7.032,7.032,0,0,1-2.145,5.157A7.032,7.032,0,0,1,54.605-785.395Zm18.256-7.3a7.032,7.032,0,0,1-2.145,5.157,7.032,7.032,0,0,1-5.157,2.145,9.12,9.12,0,0,1-1.278-.114A11.151,11.151,0,0,1,63-785.76,10.855,10.855,0,0,0,64.9-789a10.523,10.523,0,0,0,.662-3.7,10.524,10.524,0,0,0-.662-3.7A10.857,10.857,0,0,0,63-799.635a5.551,5.551,0,0,1,1.278-.3A11.984,11.984,0,0,1,65.559-800a7.032,7.032,0,0,1,5.157,2.145A7.032,7.032,0,0,1,72.861-792.7Z"
                        transform="translate(-40 800)"
                        fill="#97ad6e"
                      />
                    </svg>
                  </span>
                  <h3 className="font-weight-normal">Job Opportunities</h3>
                  <p>
                    Browse current openings available at our six salon
                    locations.
                  </p>
                  <Link onClick={handleLinkClick} to="https://recruiting.paylocity.com/recruiting/jobs/All/1b88052a-fdf2-4822-a895-e1729db679f2/Square-One-Salon-and-Spa" target="_blank" className="btn btn-primary">
                    Openings
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="career-productsection">
          <div className="row">
            {salonData && parse(salonData.website_settings && salonData.website_settings.new_career_products_content && salonData.website_settings.new_career_products_content)}
            
            {/* <div className="col-md-4 col-sm-6 col-12 px-md-1">
              <div className="careerImageBox">
                <img
                  src={`${BASEURL}/assets/images/carrerImg-1.png`}
                  width="368"
                  height="515"
                  alt="career img1"
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-12 px-md-1">
              <div className="careerImageBox">
                <img
                  src={`${BASEURL}/assets/images/carrerImg-2.png`}
                  width="368"
                  height="515"
                  alt="career img1"
                />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
