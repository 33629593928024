import React, {useContext} from 'react'
import SiteContext from '../../helpers/CreateContext';
import parse from 'html-react-parser';
import { useParams } from "react-router-dom";
// import { Helmet } from "react-helmet-async";

const Package = () => {
  const { linkId } = useParams();

    const { salonData, salonInfoPending } = useContext(SiteContext); 

    return (
      <React.Fragment>
            
            {/* <Helmet>
            <link rel="canonical" href="https://www.squareonesalon.com/gift-card" />
          <title></title>
          <meta name="description" content="Get gift cards for haircuts, color, extensions, spa treatments, and more at Square One Salon in Ohio. A great gift for anyone who loves beauty and wellness!" />
         </Helmet> */}
  
          <div className="inner-banner-main">
  
        {
          !salonInfoPending ? (<>{salonData && parse(salonData && salonData.website_settings.package_new_banner && salonData.website_settings.package_new_banner) }</>) : (<><div className="videoWrapperli">
            <div className="shimmer" style={{ minHeight: '324px', display: 'block' }}></div>
          </div></>)
        }
  
   </div>
  
         <section className='iframe-theme'>
           <div className='container'>
           <div className="iframe-max-theme">
              <div className="iframe-container">
                  <iframe className="responsive-iframe" title='Gift Cards' src={`https://book.salonbiz.com/widget/#/booking/purchase-gift-card?comToken=c244t201402131049p6t52Uxb7k6z75S6eN3a6&linkId=${linkId}&useGiftCardLinkData=1`} width="100%"></iframe>
              </div>
          </div>
  
           </div>
         </section>
  
      </React.Fragment>
    )
  }

export default Package