import React, { useContext } from 'react';
import "../services/services.css";
import { Link } from "react-router-dom";
import SiteContext from '../../helpers/CreateContext';
import parse from 'html-react-parser';
import { Helmet } from "react-helmet-async";



const BASEURL = process.env.REACT_APP_SQUAREONE_BASE_URL;

const Shimmer = () => (
  <div className="shimmer" style={{ minHeight: '350px', display: 'block', borderRadius: '5px', border: '1px solid #97AD6E' }}></div>
);

const extractText = (node) => {
  if (typeof node === 'string') return node;
  if (Array.isArray(node)) return node.map(child => extractText(child)).join('');
  if (node.props && node.props.children) return extractText(node.props.children);
  return '';
};

const limitText = (text, limit) => text.length > limit ? text.substring(0, limit) + '...' : text;

const ServiceCard = ({ service }) => {

  // const parsedContent = parse(service.post_description);
  // const firstParagraph = parsedContent.find(node => node.type === 'p');
  // const paragraphText = extractText(firstParagraph.props.children);
  // const limitedText = limitText(paragraphText, 250);

  const parsedContent = parse(service.post_description);
const firstParagraph = Array.isArray(parsedContent)
  ? parsedContent.find(node => node.type === 'p')
  : parsedContent.type === 'p'
  ? parsedContent
  : null;

const paragraphText = firstParagraph ? extractText(firstParagraph.props.children) : "";
const limitedText = limitText(paragraphText, 250);
  const handleLinkClick = (event) => {
    event.preventDefault(); // Prevent React Router's navigation
    window.location.href = event.currentTarget.href; // Trigger full reload
  };

  return (
    <div className="col-12 col-sm-6 col-md-4 services-col">
       
        <Helmet>
        <link rel="canonical" href="https://www.squareonesalon.com/services" />
                   <title>Top Haircuts, Color & Wellness Services in Ohio | Square One</title>
                   <meta name="description" content="Square One Salon provides the best haircuts, color, facials, extensions, nails, and wellness services for men & women in Dayton & Columbus" />
                  </Helmet>

      <div className="services-inner-card">
        <div className="services-inner-info">
          <figure>
            <img src={service.post_image ? service.post_image : `${BASEURL}/assets/images/logo.svg`} width="162" height="162" alt={service.post_title} />
          </figure>
          <h4>{service.post_title}</h4>
          <div>{limitedText}</div>
        </div>
        <div className="discover-btn">
          {
           service?.author.length >  0 ? <Link onClick={handleLinkClick} className='btn btn-link' to={`${BASEURL}/services/${service.blog_category_name.toLowerCase()}/${service.post_title.toLowerCase().replace(/[^a-z0-9]+/g, '-')}`}>View Menu</Link> : <p className='btn btn-link'>Menu coming soon! Stay tuned.</p>
          }
          
        </div>
      </div>
    </div>
  );
};

export default function Services() {

  const { salonData, salonInfoPending,  salonServices , servicePending } = useContext(SiteContext);

  const salonList = salonServices && salonServices.filter(service => service.blog_category_name === "Salon");
  const wellnessList = salonServices && salonServices.filter(service => service.blog_category_name === "Wellness");

  console.log(salonList, 'salonList')
  console.log(wellnessList, 'wellnessList')

  return (
    <>
      <div className="inner-banner-main">
        {
          !salonInfoPending ? (<>{salonData && parse(salonData.website_settings.new_services_main_banner && salonData.website_settings.new_services_main_banner)}</>) : 
          (<><div className="videoWrapperli">
            <div className="shimmer" style={{ minHeight: '324px', display: 'block' }}></div>
          </div></>)
        }
        
      </div>

      <div className="services-main gray-bg">
        {
          !servicePending ? (<div className="container">
            <div className="services-grid-cont">
              <h3>Salon Services</h3>
              <div className="row gx-md-4">
                {salonList && salonList.length > 0 ? (
                  salonList.map((service, index) => <ServiceCard key={index} service={service} />)
                ) : (
                  <p>No salon services available at the moment.</p>
                )}
              </div>
            </div>
  
            <div className="services-grid-cont">
              <span id='wellness-services' className='d-block scroll-position-wellness'></span>
              {salonData && parse(salonData.website_settings.new_wellness_services_content && salonData.website_settings.new_wellness_services_content)}
              <div className="row gx-md-4">
                {wellnessList && wellnessList.length > 0 ? (
                  wellnessList.map((service, index) => <ServiceCard key={index} service={service} />)
                ) : (
                  <p>No wellness services available at the moment.</p>
                )}
              </div>
            </div>
          </div>) : (<div className="promotion-bg">
          <div className="container">
            <div className="promotion-wrapper">
              <div className="row">
                {[...Array(6)].map((_, index) => (
                  <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-4" key={index}>
                    <Shimmer />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>)
        }
        
      </div>
    </>
  );
}
