import React,{useContext} from 'react'
import TopHeader from '../../components/TopHeader'
import {  useLocation } from "react-router-dom";
import locations from '../locations/locations.json'; 
import FixedFooter from '../../components/FixedFooter';
import useFetch from '../../fetch/useFetch';
import { Helmet } from "react-helmet-async";
import parse from 'html-react-parser';
import SiteContext from '../../helpers/CreateContext';
const LocationOnlineBooking = () => {

    const BASEURL = process.env.REACT_APP_SQUAREONE_BASE_URL;
    const SALONAPI = process.env.REACT_APP_SQUAREONE_API_EXTERNAL;

    const location = useLocation();
    // const path = location.pathname.split('/').pop();

    const pathSegments = location.pathname.split('/').filter(Boolean);
    const path = pathSegments[pathSegments.length - 1]; // Get the last non-empty segment
  
    
    let locationDetailsDayton = locations && locations.filter(location => location.urlformat === path);

    const {data : salonInfo }= useFetch(`${SALONAPI}/salon_info/${locationDetailsDayton[0].salonId}`);
    const  {salonData} = useContext(SiteContext);
    const webInfo = salonData && salonData?.website_settings;
 

  return (
    <React.Fragment>
       
         <Helmet>
         <link rel="canonical" href={locationDetailsDayton[0]?.curl_ob} />
               <title> {locationDetailsDayton[0]?.metaTitle_ob || 'Aveda Hair & Spa Salon Ohio | Beauty & Wellness - Square One'} </title>
               <meta name="description" content={locationDetailsDayton[0]?.metaDes_ob || 'Experience Best Aveda hair & spa services at Square One Salon in Ohio. Offering expert beauty & wellness treatments in Dayton & Columbus Ohio. Book now!'} />
              </Helmet>

       <TopHeader  locationDetailsDayton = {locationDetailsDayton[0]}  BASEURL = {BASEURL}/>

       <section className='iframe-theme'>
            <div className='container'>
                <div className="iframe-max-theme">
                     
                <div className="home-offer-content">
                    <div className='max-800'>
                   {webInfo && parse(webInfo?.services_special_offer || '')}
                    </div>
                </div>

                    <div className="iframe-container">
                        <iframe className="responsive-iframe" title='Book an Appointment' src={locationDetailsDayton && locationDetailsDayton[0]?.onlineBooking} width="100%"></iframe>
                    </div>

                    <div className='note-offer'>
                     {webInfo && parse(webInfo?.services_offer_note || '')}
                    </div>
                </div>
            </div>
        </section>

        {salonInfo &&  <FixedFooter  locationDetailsDayton = {locationDetailsDayton[0]} salonInfo = {salonInfo}/>}
    </React.Fragment>
  )
}

export default LocationOnlineBooking
