import { useState, useEffect, useCallback } from 'react';

const useFetch = (url) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [cache, setCache] = useState({});

  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null);

    if (cache[url]) {
      setData(cache[url]);
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();
      setData(result);
      setCache((prevCache) => ({ ...prevCache, [url]: result }));
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, [url, cache]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const refresh = () => {
    setCache((prevCache) => {
      const newCache = { ...prevCache };
      delete newCache[url];
      return newCache;
    });
    fetchData();
  };

  return { data, loading, error, refresh };
};

export default useFetch;
 