import React from 'react';
import {Outlet, ScrollRestoration} from 'react-router-dom';
import Header from './header/Header';
import Footer from './footer/Footer';

export default function RootLayout() {
  return (

    <div className="wrapper">
        <ScrollRestoration />
        <Header/>
        <main>
            <Outlet />
        </main>
        <Footer/>
    </div>
    
      

  )
}
