import React, {useState} from 'react'
import { Link, NavLink , useLocation, useNavigate} from 'react-router-dom'

const TopHeader = ({locationDetailsDayton, BASEURL}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () =>{
    setIsOpen(!isOpen);
  }  

  const navigate = useNavigate();


  const location = useLocation();
  let path = location.pathname.replace("-", "")
  const needloc = `/location/${locationDetailsDayton?.locationName && locationDetailsDayton?.locationName.toLowerCase().split(' ').join('')}`


  const handleLinkClick = (event) => {
    event.preventDefault(); // Prevent React Router's navigation
    window.location.href = event.currentTarget.href; // Trigger full reload
  };
  return (
    <div className="blog-sortbywrapper">
        <div className="blog-sortby-gird">
          <div className="blog-sortbyleft">
            {
              path === needloc ? (<p style={{cursor:'pointer'}} onClick={() => navigate(`${BASEURL}/location`)}> Square One - <span>{locationDetailsDayton?.locationName && locationDetailsDayton?.locationName}</span> </p>) : ( <p>
                <Link onClick={handleLinkClick} to = {`${BASEURL}/location/${locationDetailsDayton.urlformat && locationDetailsDayton.urlformat}`}> 
                Square One </Link> - <span>{locationDetailsDayton.locationName && locationDetailsDayton.locationName}</span>
            </p>)
            }
           
          </div>
          <div className="blog-sortbyright">
          <button className={`blog-toggle ${isOpen ? 'active':''}`} onClick={toggleDropdown}>
                 <img src={`${BASEURL}/assets/images/mobile-arrow.svg`} width="16.65" height="8.33" alt="arrow" /> 
                </button>

            <ul className={`blog-menu ${isOpen ? 'open' :''}`}>
              <li>
                <NavLink onClick={handleLinkClick} to = {`${BASEURL}/team/${locationDetailsDayton.urlformat && locationDetailsDayton.urlformat}`}>Team</NavLink>
              </li>
              <li>
                <NavLink onClick={handleLinkClick} to = {`${BASEURL}/promotions/${locationDetailsDayton.urlformat && locationDetailsDayton.urlformat}`}>Promotions</NavLink>
              </li>
              <li>
                <NavLink onClick={handleLinkClick} to = {locationDetailsDayton.shopNowUrl && locationDetailsDayton.shopNowUrl} target='_blank'>Shop</NavLink>
              </li>
              <li>
                <NavLink onClick={handleLinkClick} to = {`${BASEURL}/reviews/${locationDetailsDayton.urlformat && locationDetailsDayton.urlformat}`}>Reviews</NavLink>
              </li>
              <li>
                <NavLink onClick={handleLinkClick} to = {`${BASEURL}/contact/${locationDetailsDayton.urlformat && locationDetailsDayton.urlformat}`}>Contact</NavLink>
              </li>
            </ul>
          </div>
        </div>
    </div>
  )
}

export default TopHeader
