import React, { useState,  useContext, useEffect, Suspense, useMemo } from 'react';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import './home.css';
import useFetch from '../../fetch/useFetch';
import SiteContext from '../../helpers/CreateContext';
import parse,{domToReact} from 'html-react-parser';
import { truncateDescription } from '../../utils/cleanHtml';
import { Link } from 'react-router-dom';

import { convertAndDownload } from '../../utils/convertAndDownload';
import { useInView } from 'react-intersection-observer';
import Popup from './Popup';

import { Helmet } from "react-helmet-async";

// import MetaTags from '../../MetaTags';
// import metaData from '../../seoMetaTags.json';

const Video = React.lazy(() => import('./Video'));
const Locationsdata = React.lazy(() => import('./Locationsdata'));
const HomeOffers = React.lazy(() => import('./HomeOffers'));
const HomePageBlog = React.lazy(() => import('./HomePageBlog'));


const BASEURL = process.env.REACT_APP_SQUAREONE_BASE_URL;
const SALONAPI = process.env.REACT_APP_SQUAREONE_API_EXTERNAL; 

const FadeInSection = ({ children }) => {
  const { ref, inView } = useInView({
    threshold: 0.1, // Trigger when 10% of the component is in view
  });

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (inView) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [inView]);

  return (
    <div ref={ref} className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}>
      {children}
    </div>
  );
};



export default function Home() {

  const { salonData , salonInfoPending } = useContext(SiteContext);

  const { data : blog , loading : blogLoading , serverError : blogError } = useFetch(`${SALONAPI}/homeBlog`);

  const {data :specialData , loading : loadingSpecail } = useFetch(`${SALONAPI}/specialHomePageData`)

  const [mapShowModal, setMapShowModal] = useState(false);
  const [modalData, setModalData] = useState(null)


  const specialItem = specialData && specialData.filter((sitem) => sitem.specials_title === "Home_page_Special" )
  const referFriend = specialData && specialData.filter((sitem) =>  sitem.specials_title === "Home_page_Refer")

 

 const fullObj = [...(specialItem || []), ...(referFriend || [])];


  useEffect(() => {
    if (mapShowModal) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
    
  }, [mapShowModal]);

  

  const openMapModal = (data) => {
   
    setModalData(data);
     setMapShowModal(true);
  };

  const closeMapModal = () => {
    setMapShowModal(false);
  };

  const [fileName] = useState('downloaded_image.jpg'); 

  const handleLinkClick = (event) => {
    event.preventDefault(); // Prevent React Router's navigation
    window.location.href = event.currentTarget.href; // Trigger full reload
  };

  function replaceAnchorTags(htmlString) {
    return parse(htmlString, {
      replace: ({ name, attribs, children }) => {
        if (name === 'a' && attribs.href) {
          return (
            <a className='btn btn-outline-primary' href={attribs.href}>
              {domToReact(children)}
            </a>
          );
        }
      },
    });
  }


 
  const slides = useMemo(() => {
    return blog?.map(({ post_image, post_title, blog_category_name, post_id, post_description, blog_url }) => (
      <SwiperSlide key={post_id}>
        <div className="spotlight-swiperslider">
          <div className="spotlight-inner-info">
            <figure>
              <img src={post_image} width="162" height="162" alt="spotlight 1"/>
            </figure>
            <h3>{blog_category_name || '\u00A0'}</h3>
            <h4>{truncateDescription(post_title, 50)}</h4>
            <BlogDescription post_description={post_description} />
          </div>
          <div className="discover-btn">
            <Link onClick={handleLinkClick} to={`/blogdetails/${blog_url}`} className="btn btn-link" > Read More </Link>
          </div>
        </div>
      </SwiperSlide>
    ));
  }, [blog]);

  // if(salonInfoPending){
  //   return <HomePageShimmer/>
  // }


  return (
    <>

      <Helmet>
      <link rel="canonical" href="https://www.squareonesalon.com/" />
        <title> Aveda Hair & Spa Salon Ohio | Beauty & Wellness - Square One </title>
        <meta name="description" content="Experience Best Aveda hair & spa services at Square One Salon in Ohio. Offering expert beauty & wellness treatments in Dayton & Columbus Ohio. Book now!" />
       </Helmet>
       
    <Popup/>

   
    <Suspense fallback={<div style={{visibility:'none'}}></div>}>
     
        <Video salonInfoPending = {salonInfoPending}/>
      
    </Suspense>

      
    <Suspense fallback={<div style={{visibility:'none'}}></div>}>
      
      <Locationsdata salonData = {salonData} salonInfoPending = {salonInfoPending} />
      
    </Suspense>
      
     <Suspense fallback={<div style={{visibility:'none'}}></div>}>
     <FadeInSection>
         <HomeOffers salonInfoPending = {salonInfoPending}  Swiper = {Swiper} Navigation = {Navigation} Pagination = {Pagination} Link={Link} SwiperSlide = {SwiperSlide} />
         </FadeInSection>
    </Suspense>

     
      <div className="home-offer-main">
      <FadeInSection>
        {
          !loadingSpecail ? ( <div className="container">
       
            {
             fullObj && fullObj.length > 0 ? (
              <>
               {
                 fullObj.map((eachSpecial) => {
                   return(
                     <div className="row align-items-center" key={eachSpecial.specials_id} >
                     <div className="col-12 col-sm-6 col-md-6 col-md-54">
                     <div className="home-offer-info-col">
                        <div>{parse(eachSpecial.specials_description_only)}</div>
                       
                      {
                        eachSpecial?.specials_price &&  <button className="btn btn-outline-primary"  onClick={()=>{openMapModal(eachSpecial)}} >
                        {
                           eachSpecial?.specials_title === "Home_page_Special" 
                             ? (eachSpecial?.specials_price && 'Get ' + eachSpecial?.specials_price) 
                             : `${eachSpecial.specials_price}`
                         }
                         
                         </button>
                      }
                       
                     </div>
                           
                     </div>
                     <div className="col-12 col-sm-6 col-md-6 col-md-46">
                     <div className="home-offer-image-cont">
                      <figure>
                          <img alt={eachSpecial.specials_title} height="396" src={eachSpecial.specials_image_new} width="502" />
                       </figure>
                    </div>
                       </div> 
                   </div> 
                   )
                 })
               }
              </>
             ) : (null)
            }
   
               <div className="row align-items-center">
                   {salonData && salonData?.website_settings?.new_to_squareone && replaceAnchorTags(salonData?.website_settings?.new_to_squareone || '') } 
               </div>
   
           </div>) : (
                 <>
                  <div className='row align-items-center mb-4' style={{display:'flex', minHeight:'400px', maxWidth:'1169px',width:'100%',margin:'0 auto'}}>
                  <div className='col-12 col-sm-6 col-md-6'>
                        <div className='shimmer' style={{width:'100%', display:'block',minHeight:'397px',borderRadius:'10px'}}></div>
                  </div>
                    <div className='col-12 col-sm-6 col-md-6'>
                      <div className='shimmer' style={{width:'100%', display:'block',minHeight:'397px',borderRadius:'10px'}}></div>
                    </div>
               </div>

               <div className='row align-items-center mb-4' style={{display:'flex', minHeight:'400px',  maxWidth:'1169px',width:'100%',margin:'0 auto'}}>
                  <div className='col-12 col-sm-6 col-md-6'>
                        <div className='shimmer' style={{width:'100%', display:'block',minHeight:'397px',borderRadius:'10px'}}></div>
                  </div>
                    <div className='col-12 col-sm-6 col-md-6'>
                      <div className='shimmer' style={{width:'100%', display:'block',minHeight:'397px',borderRadius:'10px'}}></div>
                    </div>
               </div>
                 </>
           )
        }
       
       </FadeInSection>
      </div>
      

      <Suspense fallback={<div style={{visibility:'none'}}></div>}>
      {
        !blogLoading  ? ( <FadeInSection>
          <HomePageBlog blogLoading = {blogLoading} blog = {blog} blogError = {blogError} Swiper = {Swiper} Navigation = {Navigation} Pagination = {Pagination} Link={Link} BASEURL = {BASEURL} slides= {slides}/>
         </FadeInSection>) : ( <div className='row align-items-center mb-4 pt-5 pb-5' style={{display:'flex', minHeight:'400px',  maxWidth:'1169px',width:'100%',margin:'0 auto'}}>
                  <div className='col-12 col-sm-4 col-md-4'>
                        <div className='shimmer' style={{width:'100%', display:'block',minHeight:'397px',borderRadius:'10px'}}></div>
                  </div>
                    <div className='col-12 col-sm-4 col-md-4'>
                      <div className='shimmer' style={{width:'100%', display:'block',minHeight:'397px',borderRadius:'10px'}}></div>
                    </div>
                    <div className='col-12 col-sm-4 col-md-4'>
                      <div className='shimmer' style={{width:'100%', display:'block',minHeight:'397px',borderRadius:'10px'}}></div>
                    </div>
               </div>)
      }
     
     </Suspense>
 
     
    
     {mapShowModal && modalData && (
        <div id="StaffModal" className={`modal-staff bg-none ${mapShowModal ? "show fade-in" : ""}`}>
          <div className='modal-overlay' onClick={closeMapModal}></div>
          <div className="modal-staff-dialog">
            <div className="modal-staff-content">
              <button type="button" className="close" onClick={closeMapModal}>
                <span>
                  <img
                    src={`${BASEURL}/assets/images/close.svg`}
                    width="16.35"
                    height="16.35"
                    alt="close icon"
                  />
                </span>
              </button>
              <div className="modal-staff-body">
                <div className="promotion-modal-gird">
                  <div className="promotion-card">
                    <div className="promotion-img">
                      <img src={modalData.specials_image_new || ''} width="502" height="396" alt="gift_card" style={{height:'100%', maxHeight:'242px', objectFit:'contain'}} />
                    </div>
                   
                      <div className="mh-auto fw-medium">{parse(modalData.specials_description_only || '')}</div>
                    
                  </div>
                  <div className="promotion-download">
                    <button onClick={() => convertAndDownload(modalData.specials_image_new, fileName)} className="btn btn-link" download="download">
                      Download
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

  

    </>
  )
}

const BlogDescription = ({ post_description }) => {
  const strippedContent = post_description.replace(/<[^>]+>/g, '');  
  return (
    <div className="blog-item-para">
      {parse(truncateDescription(strippedContent, 130))}
    </div>
  );
};