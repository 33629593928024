import React from 'react';
import { Link } from 'react-router-dom';
import { truncateDescription } from '../utils/truncateDescription';

const BASEURL = process.env.REACT_APP_SQUAREONE_BASE_URL;

const ArchivedPost = ({ blog }) => {
  
  if (!blog) return null;

  const handleLinkClick = (event) => {
    event.preventDefault(); // Prevent React Router's navigation
    window.location.href = event.currentTarget.href; // Trigger full reload
  };

  return (
    <>
      {blog && blog.map(({ post_id, blog_category_name, post_title, blog_url }) => (
        <div className="archived-posts-card" key={post_id}>
          <div className="archived-posts-cont">
            <h4>{blog_category_name || '\u00A0'}</h4>
            <p>{truncateDescription(post_title, 63) || 'Square One Salon and Spa'}</p>
          </div>
          <Link onClick={handleLinkClick} to={`${BASEURL}/blogdetails/${blog_url}`} className="btn btn-link">
            Read Post
          </Link>
        </div>
      ))}
    </>
  );
};

export default ArchivedPost;
